import React from 'react';
import { IntlProvider } from 'react-intl'
import translations from './translations.json'

export default class IntlProviderConfigured extends React.Component {
    state = {
        loading: true,
        locale: 'en'
    }

    componentDidMount() {
        this.updateParams();
    }

    componentDidUpdate() {
        const currentLang = localStorage.getItem('language');
        if (currentLang == null) {
            this.updateParams()
        }
    }

    updateParams() {
        localStorage.setItem('language', 'en');
        this.setState({ locale: 'en', loading: false });
    }

    render() {
        const locale = this.state?.locale
        const { children } = this.props

        if (this.state.loading) return null

        return (
            <IntlProvider locale={locale} messages={translations[locale]}>
                {children}
            </IntlProvider>
        )
    }
}