import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { textSmMedium, textSmRegular } from "../../../styles/typography";

const getBackgroundColor = (type) => {
  switch (type) {
    case "error":
      return appTheme.colors.red[25];
    case "alert":
      return appTheme.colors.orange[25];
    default:
      return appTheme.colors.green[25];
  }
};

const getBorderColor = (type) => {
  switch (type) {
    case "error":
      return appTheme.colors.red[300];
    case "alert":
      return appTheme.colors.orange[300];
    default:
      return appTheme.colors.green[300];
  }
};

const getTitleColor = (type) => {
  switch (type) {
    case "error":
      return appTheme.colors.red[700];
    case "alert":
      return appTheme.colors.orange[700];
    default:
      return appTheme.colors.green[700];
  }
};

const getLabelColor = (type) => {
  switch (type) {
    case "error":
      return appTheme.colors.red[600];
    case "alert":
      return appTheme.colors.orange[600];
    default:
      return appTheme.colors.green[600];
  }
};

export const AlertContainer = styled.div`
  ${({ theme, type, active }) => css`
    gap: 12px;
    width: 540px;
    z-index: 2000;
    padding: 16px;
    display: flex;
    position: fixed;
    transition: 0.3s ease;
    align-items: flex-start;
    border: 1px solid ${getBorderColor(type)};
    background-color: ${getBackgroundColor(type)};
    pointer-events: ${active ? "all" : "none"};
    top: ${active ? "48px" : "58px"};
    opacity: ${active ? '1' : '0'};
    border-radius: 8px;
    left: 90px;

    @media (max-width: 640px) {
      flex-wrap: wrap;
      width: calc(100% - 40px);
      left: 20px;
      top: 20px;
    }
  `}
`;

export const AlertIcon = styled.img`
  ${({ theme }) => css``}
`;

export const AlertTexts = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`;

export const AlertTitle = styled.h6`
  ${({ theme, type }) => css`
    ${textSmMedium}
    margin-bottom: 4px;
    color: ${getTitleColor(type)};
  `}
`;

export const AlertDesc = styled.p`
  ${({ theme, type }) => css`
    ${textSmRegular}
    color: ${getLabelColor(type)};
    margin: 0;
  `}
`;

export const AlertClose = styled.button`
  ${({ theme, type }) => css`
    & svg {
      stroke: ${getTitleColor(type)};
    }

    @media (max-width: 640px) {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  `}
`;
