import React from "react";
import { DropdownButton, DropdownHightlight } from "./style";
import arrow from "../../../assets/images/icons/arrow-right.svg";

export default function LoadOutDropdownButton({
  openModal,
  setOpenModal,
  value,
  placeholder,
}) {
  return (
    <DropdownButton
      onClick={setOpenModal}
      value={value}
      active={openModal ? "openModal" : undefined}
    >
      <DropdownHightlight active={value ? "value" : undefined}>
        {placeholder}
      </DropdownHightlight>

      <span>{!value ? placeholder : value}</span>
      <img src={arrow} alt="Arrow Icon" />
    </DropdownButton>
  );
}
