import React from "react";
import alertIcon from "../../../assets/images/icons/alert-alert.svg";
import successIcon from "../../../assets/images/icons/success-alert.svg";
import cancelIcon from "../../../assets/images/icons/cancel-alert.svg";
import {
  AlertClose,
  AlertContainer,
  AlertDesc,
  AlertIcon,
  AlertTexts,
  AlertTitle,
} from "./style";

const LoadOutAlert = ({ type, title, desc, active, setActive }) => {
  const typeIcon = {
    success: successIcon,
    alert: alertIcon,
    error: cancelIcon,
  };

  return (
    <AlertContainer active={active ? "active" : undefined} type={type}>
      <AlertIcon src={typeIcon[`${type}`]} alt="Ícone de Alert" />
      <AlertTexts>
        <AlertTitle type={type}>{title}</AlertTitle>
        <AlertDesc type={type}>{desc}</AlertDesc>
      </AlertTexts>
      <AlertClose onClick={() => setActive(!active)} type={type}>
        <svg
          width="21"
          height="21"
          stroke="#027A48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m15.5 5.356-10 10m0-10 10 10"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </AlertClose>
    </AlertContainer>
  );
};

export default LoadOutAlert;
