import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";

export const IconHeader = styled.img`
  ${({ theme }) => css`
    margin-bottom: 24px;
  `}
`;

export const TitleHeader = styled.h1`
  ${({ theme }) => css`
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    margin-bottom: 12px;
  `}
`;

export const ResendEmail = styled.p`
  ${({ theme }) => css`
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${appTheme.colors.gray[500]};
  `}
`;
