import React, { useEffect, useState } from "react";
import { ModalContainer } from "./style";
import FeedBackModal from "../feedBackModal";
import { useAuth } from '../../../context/AuthProvider/useAuth';

export default function Dynamic({
  openModal,
  setOpenModal,
  setLoading,
  setText
}) {
  const auth = useAuth();
  const [contactList, setContactList] = useState([]);
  const [detailsList, setDetailsList] = useState([]);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  useEffect(() => {
    if (!openModal) {
      return;
    }

    setLoading(true);
    
    try {
      ( async () => {
        const { data } = await auth.getAllDynamic();

        const contacts = data.ret.filter( item => item.type === 1);
        setContactList(contacts);
        const details = data.ret.filter( item => item.type === 2);
        setDetailsList(details);
      })();
    } catch ( error ) {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: error?.data.message,
      });
    } finally {
      setLoading(false);
    }
  }, [openModal]);

  function handleClick(tag) {
    setText(tag);
    setOpenModal(false);
  }

  return (
    <>
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <ModalContainer
        style={{ height: "350px", width: "350px", position: "fixed", textAlign: "start", alignItems: "start" }}
        open={openModal}
        setOpen={setOpenModal}
      >
        <div className="small fw-bold text-center mb-2">Dynamic Fields</div>
        <div id="scroll-conversation text-start" data-mdb-perfect-scrollbar="true" style={{ height: "auto", overflow: "scroll", marginLeft: 8 }}>
          <div className="small fw-bold">Contact Details</div>
            { contactList.map( item => {
              return (<div onClick={() => handleClick(item.tag)} style={{ fontSize: 14 }} key={item.id} className="btn w-auto d-inline-block py-0 px-1 m-1 bg-purple text-white">{item.tag}</div>);
            })}
          <div className="small fw-bold mt-2">My Details</div>
            { detailsList.map( item => {
              return (<div onClick={() => handleClick(item.tag)} style={{ fontSize: 14 }} key={item.id} className="btn w-auto d-inline-block py-0 px-1 m-1 bg-purple text-white">{item.tag}</div>);
            })}
        </div>
      </ModalContainer>
    </>
  );
}
