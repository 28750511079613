import { useEffect, useState } from "react";
import iconArrow2 from "../../assets/images/icons/arrow-down.svg";
import ListItem from "./listItem";
import Loading from "../../components/fragments/Loading";
import AddEdit from "./addEdit";
import { FormattedMessage } from "react-intl";
import {
  ArrowOrder,
  CardFormContainer,
  CardFormHeader,
  CardFormHeaderActions,
  CardFormHeaderSearchMobile,
  HideOnMobile,
  RosterHeaderButtons,
  RosterRefreshHeader,
  SelectedActions,
} from "../../styles/common";
import iconRefresh from "../../assets/images/icons/icon-refresh.svg";
import FeedBackModal from "../../components/fragments/feedBackModal";
import LoadOutButton from "../../components/fragments/loadOutButton";
import { UsersTable, QuantityBall } from "./style";
import LoadOutInput from "../../components/fragments/loadOutInput";
import LoadOutCheckbox from "../../components/fragments/loadOutCheckbox";
import TablePagination from "../../components/fragments/tablePagination";
import { DevicesHeaderButtonsMobile } from "./style";
import { checkTokenValidity } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider/useAuth";
import HeaderMobile from "./header-mobile";

export default function List() {
  const history = useHistory();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  //modal edit/add props
  const [addEditRegister, setAddEditRegister] = useState(false);
  const [objectEditRegister, setObjectEditRegister] = useState({})
  const [idEditRegister, setIdEditRegister] = useState(undefined);
  const [modalObject, setModalObject] = useState({
    type: "alert",
    buttonLabel: "Delete",
    open: false,
    title: "",
    desc: "",
  });

  //ordering props
  const [lastField, setLastField] = useState("name");
  const [lastOrder, setLastOrder] = useState(false);

  //table cols data and ordering props
  const cols = [
    { title: 'name' },
    { title: 'message' },
    { title: 'createdat' },
  ];

  //pagination props
  const [selected, setSelected] = useState(1);
  const [perPage] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");

  const [templateList, setTemplateList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [count, setCount] = useState([]);
  const offset = selected * perPage - perPage;

  const isMobile = window.matchMedia(
    "(max-width: 1270px)" //"(pointer: coarse) and (hover: none)"
  ).matches;

  //table props
  const [hasTemplateChecked, setHasTemplateChecked] = useState(false);

  const setAllCheckboxes = (value) => {
    setHasTemplateChecked(value.target.checked);
    const newList = [ ...filter.map((x) => { return { ...x, active: value.target.checked } }) ];
    setFilter(newList);
  };

  const setCheckID = (id, value) => {
    const newList = [ ...filter.map((data) => {
      if (data.id === id) {
        data.active = value;
      }
      return data;
    }) ];
    setFilter(newList);
  }

  useEffect(() => {
    handleCheck();
  }, [filter]);

  function handleCheck() {
    var active = true;
    filter.forEach(item => {
      if (!item.active) {
        active = false;
        return;
      }
    });

    if (filter.length === 0) {
      active = false;
    }

    setHasTemplateChecked(active);
  }

  const fetchData = async () => {
    setLoading(true);

    try {
      const { data } = await auth.getAllTemplatesByLineId();
      const newList = data.ret.map((item) => { item.active = false; return item; });
      setTemplateList(newList);
      setPageCount(Math.ceil(newList.length / perPage));
    } catch ({ response }) {
      console.log(JSON.stringify(response));
      //checkTokenValidity(response.data);
    } finally {
      setLoading(false);
    }
  }
  
  function deleteDataSelected() {
    setModalObject({
      type: "alert",
      buttonLabel: "Delete",
      open: true,
      title: "Confirm delete",
      desc: `Are you sure you want to delete this items?`,
      action: () => {
        setLoading(true);
    
        try {
          filter.forEach(async (item) => {
            if (item.active) {
              await deleteData(item.id);
            }
          });
        } catch ({ response }) {
          console.log(JSON.stringify(response));
          //checkTokenValidity(response.data);
        } finally {
          setLoading(false);
          setModalObject({
            ...modalObject,
            open: false,
          });
        }
      }
    });
  }

  const deleteData = async (templateId) => {
    setLoading(true);

    try {
      const { data } = await auth.deleteTemplateById(templateId);
      if (data.res) {
        //await fetchData();
        const newDrivers = [ ...templateList.filter((item) => item.id !== templateId) ];
        setTemplateList(newDrivers);
        setSelected(1);
        //setUpdate(!update);
      }
    } catch ({ response }) {
      console.log(JSON.stringify(response));
      //checkTokenValidity(response.data);
    } finally {
      setLoading(false);
    }
  }

  const fetchDataById = async (id) => {
    setLoading(true);

    try {
      const { data } = await auth.getTemplateById(id);
      if (data.res) {
        setObjectEditRegister({ ...data.ret[0] });
      }
    } catch ({ response }) {
      console.log(JSON.stringify(response));
      //checkTokenValidity(response.data);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    document.title = "LoadOut - Templates";
    fetchData();
  }, [auth.lineData]);

  useEffect(() => {
    datatable();
  }, [templateList, selected, search, lastField, lastOrder]);

  function datatable() {
    const list = [...templateList];

    //1 - Status
    //const listStatus = getStatusFilter(list);

    //2 - Search
    var listSearch = list;
    if (search.length) {
      listSearch = list.filter((x) =>
          (x.name.toLowerCase()).includes(
            search.toLowerCase()
          )
        );
    }

    //3 - Sort
    listSearch.sort((a, b) => {
      if (a[lastField].toLowerCase() < b[lastField].toLowerCase()) {
        return lastOrder ? 1 : -1;
      } else if (a[lastField].toLowerCase() > b[lastField].toLowerCase()) {
        return lastOrder ? -1 : 1;
      } else {
        return 0;
      }
    });

    //4 - Paging
    const ListSlice = listSearch.slice(selected === 1 ? 0 : offset, selected * perPage);

    setCount(listSearch.length);
    setFilter(ListSlice);
    setPageCount(Math.ceil(listSearch.length / perPage));
  }

  function reorder(field) {
    if (field === lastField) {
      setLastOrder(!lastOrder);
    } else {
      setLastOrder(false);
      setLastField(field);
    }
  }

  function onChangeSearch (e) {
    setSearch(e.target.value);
    setSelected(1);
    setLastField("name");
    setLastOrder(false);
  }

  return (
    <>
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <AddEdit
        openModal={addEditRegister}
        setOpenModal={setAddEditRegister}
        setId={setIdEditRegister}
        object={objectEditRegister}
        id={idEditRegister}
        refreshData={fetchData}
        setLoading={setLoading}
      />
      <CardFormContainer>
        <Loading visible={loading} />

        <CardFormHeader>
          <CardFormHeaderActions style={{ height: "40px"}}>
            <RosterRefreshHeader>
              <FormattedMessage id="templates" />
              <QuantityBall active={true}>
                {count}
              </QuantityBall>
              <img onClick={fetchData} src={iconRefresh} alt="Refresh Icon" />
            </RosterRefreshHeader>
            {filter?.find((x) => x.active) ? (
              <HideOnMobile>
                <SelectedActions>
                  <LoadOutButton
                    onClick={deleteDataSelected}
                    icon="trash"
                    color="border"
                  />
                </SelectedActions>
              </HideOnMobile>
            ) : (
              <RosterHeaderButtons>
                <LoadOutButton
                  label="New Template"
                  icon="add"
                  onClick={() => {
                    setObjectEditRegister({});
                    setIdEditRegister('');
                    setAddEditRegister(true);
                  }}
                />
              </RosterHeaderButtons>
            )}
          </CardFormHeaderActions>
          <CardFormHeaderSearchMobile>
            <LoadOutInput
              value={ search }
              type="find"
              onChange={(e) => onChangeSearch(e) }
              setSearch={ setSearch }
              highlight={<FormattedMessage id="form.search" />}
            />
          </CardFormHeaderSearchMobile>
          <DevicesHeaderButtonsMobile>
            {filter?.find((x) => x.active) ? (
              <LoadOutButton
                label={<FormattedMessage id="delete" />}
                onClick={deleteDataSelected}
                icon="trash"
                color="red"
              />
            ) : (
              <>
                <LoadOutButton
                  label="New Template"
                  icon="add"
                  onClick={() => {
                    setObjectEditRegister({});
                    setIdEditRegister('');
                    setAddEditRegister(true);

                  }}
                />
              </>
            )}
          </DevicesHeaderButtonsMobile>
        </CardFormHeader>
        {isMobile && (
          <HeaderMobile checkAll={hasTemplateChecked} onChangeSelectedAll={setAllCheckboxes} lastField={lastField} lastOrder={lastOrder} reorder={reorder} />
        )}
        <UsersTable>
          <thead>
            <tr>
              {cols.map((field, index) => {
                return (
                  <th>
                    {index === 0 && <LoadOutCheckbox value={hasTemplateChecked} onChange={setAllCheckboxes} />}
                    <span key={index} onClick={() => { reorder(field.title); }}>
                      <FormattedMessage id={`tabela.${[field.title]}`} />
                      {lastField === field.title && (
                        <ArrowOrder
                          src={iconArrow2}
                          down={lastOrder}
                          alt="Arrow Icon"
                        />
                      )}
                    </span>
                  </th>
                )
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filter?.map((d, i) => {
              return (
                <ListItem
                  key={i}
                  listData={d}
                  deleteData={deleteData}
                  updateData={fetchDataById}
                  setCheckID={setCheckID}
                  setAddEditRegister={setAddEditRegister}
                  setIdEditRegister={setIdEditRegister}
                  setObjectEditRegister={setObjectEditRegister}
                />
              );
            })}
          </tbody>
        </UsersTable>

        <TablePagination
          currentPage={selected}
          totalPages={pageCount || 1}
          nextAction={() => setSelected((prev) => prev + 1)}
          previousAction={() => setSelected((prev) => prev - 1)}
        />
      </CardFormContainer>
    </>
  );
}
