import React, { useState } from "react";
import {
  ModalComposition,
  ModalContainer,
  ModalClose,
  ModalIcon,
  ModalTitle,
  ModalDesc,
  AlertButtons,
} from "../feedBackModal/style";
import {
  useIosInstallPrompt,
  useWebInstallPrompt,
} from "../../../services/pwa";
import phoneBall from "../../../assets/images/icons/phone-ball.svg";
import iosButton from "../../../assets/images/icons/iosApp.svg";
import phoneIcon from "../../../assets/images/icons/icon-phone.svg";
import LoadOutButton from "../loadOutButton";
import { PwaButton } from "./style";

export default function PWARequest() {
  const [openModal, setOpenModal] = useState(true);
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return (
    <>
      <ModalComposition open={openModal}>
        <ModalContainer
          mode={iosInstallPrompt ? "ios" : "android"}
          open={openModal}
        >
          <ModalClose onClick={() => setOpenModal(false)}>
            <svg
              width="14"
              height="15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m13 1.355-12 12m0-12 12 12"
                stroke="#101828"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ModalClose>
          <ModalIcon src={phoneBall} alt="Phone Icon" />
          <ModalTitle>Try LoadOut App</ModalTitle>
          {iosInstallPrompt ? (
            <ModalDesc type="pwa" mode="ios">
              Click on <img src={iosButton} alt="iOs Install App" /> and then{" "}
              <strong>add to Home Screen</strong> or{" "}
              <span role="button" onClick={() => setOpenModal(false)}>
                proceed with the browser
              </span>
            </ModalDesc>
          ) : (
            <ModalDesc>
              Install the LoadOut App in your phone for a better experience.
            </ModalDesc>
          )}

          {!iosInstallPrompt && (
            <AlertButtons>
              <LoadOutButton
                label="Install App"
                onClick={handleWebInstallAccepted}
              />
              <LoadOutButton
                color="white"
                label="Proceed with the browser"
                onClick={() => setOpenModal(false)}
              />
            </AlertButtons>
          )}
        </ModalContainer>
      </ModalComposition>
      <PwaButton onClick={() => setOpenModal(true)}>
        <img src={phoneIcon} alt="Icon Phone" />
        <p>
          Install the <span>LoadOut App</span> for a better experience.
        </p>
      </PwaButton>
    </>
  );
}
