import { useState, useEffect, useCallback } from 'react';

const { hidden, visibilityChange } = (() => {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }

    return { hidden, visibilityChange };
})();

function usePageVisibility() {
    const [isVisible, toggleVisibility] = useState(true);

    function handleVisibilityChange() {
        if (document[hidden]) {
            toggleVisibility(false);
        } else {
            toggleVisibility(true);
        }
    }

    function forceVisibilityTrue() {
        toggleVisibility(true);
    }

    function forceVisibilityFalse() {
        toggleVisibility(false);
    }

    useEffect(() => {
        document.addEventListener(visibilityChange, handleVisibilityChange, false);

        document.addEventListener('focus', forceVisibilityTrue, false);
        document.addEventListener('blur', forceVisibilityFalse, false);

        window.addEventListener('focus', forceVisibilityTrue, false);
        window.addEventListener('blur', forceVisibilityFalse, false);

        return () => {
            document.removeEventListener(
                visibilityChange,
                handleVisibilityChange,
                false,
            );

            document.removeEventListener('focus', forceVisibilityTrue, false);
            document.removeEventListener('blur', forceVisibilityFalse, false);

            window.removeEventListener('focus', forceVisibilityTrue, false);
            window.removeEventListener('blur', forceVisibilityFalse, false);
        };
    }, []);

    return isVisible;
}

const PageVisibility = ({ children }) => children(usePageVisibility());

export { usePageVisibility, PageVisibility };
