import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import { textSmMedium, textSmRegular } from "../../../styles/typography";

export const TablePaginationContainer = styled.footer`
  ${({ theme }) => css`
    border-top: 1px solid ${appTheme.colors.gray[100]};
    padding: 11px 24px 16px 24px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;

    @media (max-width: 610px) {
      border: none;
    }

    & > button {
      display: none;
      width: fit-content;

      @media (max-width: 610px) {
        display: block;
      }
    }
  `}
`;

export const TablePaginationLabel = styled.p`
  ${({ theme }) => css`
    ${textSmRegular}
    color: ${appTheme.colors.gray[500]};
    margin: 0;

    & span {
      ${textSmMedium}
    }
  `}
`;

export const TablePaginationButtons = styled.ul`
  ${({ theme }) => css`
    display: flex;
    gap: 12px;

    @media (max-width: 610px) {
      display: none;
    }

    & button {
      width: fit-content;
    }
  `}
`;
