import styled, { css } from 'styled-components/macro';
import appTheme from '../../../styles/themes';
import { Link } from 'react-router-dom';

export const GoBackLink = styled(Link)`${({ theme }) => css`
  color: ${appTheme.colors.gray[500]};
  gap: 12.17px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  align-items: center;
  transition: 0.3s ease;
  
  &:hover {
    color: ${appTheme.colors.gray[600]};

    svg {
        stroke: ${appTheme.colors.gray[600]};
    }
  }

  svg {
    transition: 0.3s ease;
  }
`}`