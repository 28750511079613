import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import {
  textMdRegular,
  textSmRegular,
  textXsSemiBold,
} from "../../../styles/typography";
import { DropdownField } from "../loadOutDropdown/style";

export const DropdownButton = styled(DropdownField)`
  ${({ theme, value, active }) => css`
    height: 54px;
    padding: ${value ? "26px 17px 8px 20px" : "17px 17px 17px 20px"};

    & span {
      ${textSmRegular}
      color: ${value ? appTheme.colors.gray[900] : appTheme.colors.gray[500]};
      align-items: center;
      display: flex;
      gap: 8px;
    }
  `}
`;

export const DropdownHightlight = styled.strong`
  ${({ theme, active, mode }) => css`
    position: absolute;
    left: ${mode ? '49px' : '20px'};
    opacity: ${active ? '1' : '0'};
    top: ${active ? "17px" : "50%"};
    ${active ? textXsSemiBold : textMdRegular}
    pointer-events: ${active ? "all" : "unset"};
    transform: ${active ? "translate(0, -50%)" : "unset"};
    color: ${active ? appTheme.colors.purple[500] : appTheme.colors.gray[500]};
    transition: 0.4s ease;

    & span {
      all: unset;
    }
  `}
`;
