import styled, { css } from "styled-components/macro";
import appTheme from "../../styles/themes";
import {
  displayXsSemiBold,
  textMdMedium,
  textMdRegular,
  textSmMedium,
  textXsBold,
  textXsSemiBold,
} from "../../styles/typography";

export const ImportsBG = styled.div`
  ${({ theme }) => css`
    padding: 55px 0 80px;
    background-color: ${appTheme.colors.gray[40]};

    @media (max-width: 734px) {
      background-color: ${appTheme.colors.white};
    }
  `}
`;

export const ImportsContainer = styled.main`
  ${({ theme, width }) => css`
    background-color: ${appTheme.colors.gray[40]};
    max-width: ${width ? "1013px" : "714px"};
    margin: 0 auto;
    display: block;

    ${width &&
    css`
      @media (max-width: 900px) {
        width: 100%;
        padding: 0 16px;
        background-color: ${appTheme.colors.white};
      }
    `}

    @media (max-width: 734px) {
      width: 100%;
      padding: 0 16px;
      background-color: ${appTheme.colors.white};
    }
  `}
`;

export const ImportsTitle = styled.h1`
  ${({ theme }) => css`
    ${displayXsSemiBold}
    color: ${appTheme.colors.purple[900]};
    margin: 24px 0 20px;
  `}
`;

export const ImportsSubTitle = styled.h2`
  ${({ theme }) => css`
    ${textMdRegular}
    color: ${appTheme.colors.gray[500]};
    margin-bottom: 40px;

    @media (max-width: 734px) {
      margin-bottom: 24px;
    }
  `}
`;

export const ImportsTabsContainer = styled.div`
  ${({ theme }) => css`
    & > div {
      display: none;

      @media (max-width: 734px) {
        display: flex;
      }
    }
  `}
`;

export const StringCodeSection = styled.div`
  ${({ theme }) => css`
    margin-bottom: 96px;

    & button {
      float: right;
      margin-top: 16px;
      width: fit-content;
    }
  `}
`;

export const ImportsTabHeaderContainer = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${appTheme.colors.gray[200]};

    @media (max-width: 734px) {
      display: none;
    }
  `}
`;

export const ImportsTabBodyContainer = styled.ul`
  ${({ theme, active }) => css``}
`;

export const ImportsTabBody = styled.li`
  ${({ theme, active }) => css`
    display: ${active ? "block" : "none"};
    background-color: ${appTheme.colors.white};
    padding: 43px 101px 55px;

    @media (max-width: 734px) {
      padding: 26px 0;
    }
  `}
`;

export const StringCodeContainer = styled.div`
  ${({ theme }) => css`
    ${textXsBold}
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 5.72222px;
    background-color: ${appTheme.colors.gray[50]};
    border: 0.715278px solid ${appTheme.colors.gray[200]};
    box-shadow: 0px 0.715278px 1.43056px rgba(16, 24, 40, 0.05);
  `}
`;

export const StringCodeSideBar = styled.ul`
  ${({ theme }) => css`
    padding: 17.17px;
    border: none;
    background-color: ${appTheme.colors.gray[100]};

    & li {
      ${textXsSemiBold}
      color: ${appTheme.colors.gray[400]};
      font-size: 11px;
    }
  `}
`;

export const StringCodeField = styled.textarea`
  ${({ theme }) => css`
    border: none;
    outline: none;
    width: 100%;
    resize: none;
    min-height: 370px;
    padding: 17.17px;
    background-color: ${appTheme.colors.gray[50]};
  `}
`;

export const SaveCountLabel = styled.p`
  ${({ theme }) => css`
    gap: 3px;
    margin: 0;
    display: flex;
    justify-content: center;
  `}
`;

export const ConfirmHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    margin: 24px 0 48px;
    justify-content: space-between;
    align-items: flex-end;
  `}
`;

export const ConfirmHeaderTexts = styled.div`
  ${({ theme }) => css`
    & h1 {
      ${displayXsSemiBold}
      color: ${appTheme.colors.purple[900]};
      margin-bottom: 8px;
    }

    & > span {
      ${textMdMedium}
      color: ${appTheme.colors.gray[500]};
      max-width: 505px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      display: inline-block;

      @media (max-width: 758px) {
        max-width: 340px;
      }
    }
  `}
`;

export const ConfirmHeaderActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;

    & button {
      height: fit-content;
    }

    @media (max-width: 420px) {
      display: none;
    }
  `}
`;

export const RosterTD = styled.td`
  ${({ theme }) => css`
    position: relative;

    & span {
      &:first-of-type {
        margin-right: 3px;
      }
    }

    & > div {
      top: 50%;
      right: 0;
      position: absolute;
      transform: translate(0, -50%);
    }
  `}
`;

export const ConfirmHeaderActionsResponsive = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (max-width: 420px) {
      display: flex;
      gap: 12px;
    }
  `}
`;

export const TableResponsive = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 100%;

    //overflow-x: scroll;
    //overflow-y: hidden;

    @media (max-width: 900px) {
      width: calc(32px + 100%);
      max-width: calc(16px + 100%);
    }
  `}
`;

export const TableConfirm = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-collapse: separate;

    thead {
      background-color: ${appTheme.colors.gray[25]};

      td {
        ${textXsBold}
        padding: 12px 24px;
        color: ${appTheme.colors.gray[500]};
        white-space: nowrap;

        &:first-of-type {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }

    tbody {
      background-color: ${appTheme.colors.white};

      td {
        ${textSmMedium}
        padding: 12px 24px;
        color: ${appTheme.colors.gray[500]};
        white-space: nowrap;

        &:first-of-type {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        &:not(:first-of-type) {
          // padding: 21px 24px 26px;
        }
      }
    }
  `}
`;
