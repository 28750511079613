import React, { useEffect, useRef, useState } from "react";
import appTheme from "../../../styles/themes";
import { useOnClickOutside } from "../../../utils/utils";
import { ThreeModal, ThreeModalActionsContainer } from "./style";

export default function ThreeModalActions({
  openModal,
  setOpenModal,
  editAction,
  deleteAction,
  type,
}) {
  const ref = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  useOnClickOutside(ref, () => setModalOpen(false));

  useEffect(() => {
    setModalOpen(openModal);
  }, [openModal]);

  return (
    <ThreeModalActionsContainer>
      <svg
        width="21"
        height="21"
        stroke={appTheme.colors.gray[400]}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setOpenModal(!openModal)}
      >
        <path
          d="M10.75 11.668a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667ZM10.75 5.835a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667ZM10.75 17.501a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666Z"
          strokeWidth="1.667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <ThreeModal ref={ref} type={type} active={modalOpen}>
        {type === "counseling" ? (
          <>
            <li onClick={editAction}>
              <svg
                width="17"
                height="17"
                fill="none"
                stroke={appTheme.colors.gray[500]}
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)">
                  <path
                    d="M12.083 2.835A1.886 1.886 0 0 1 14.75 5.5l-9 9-3.667 1 1-3.666 9-9Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.75 .834)"
                      d="M0 0h16v16H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>Sign Counseling</span>
            </li>
            <li onClick={deleteAction}>
              <svg
                width="17"
                height="17"
                stroke="#667085"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  clipPath="url(#a)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M.703 8.668S3.37 3.335 8.036 3.335c4.667 0 7.334 5.333 7.334 5.333s-2.667 5.333-7.334 5.333C3.37 14.001.703 8.668.703 8.668Z" />
                  <path d="M8.036 10.668a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.035 .668)"
                      d="M0 0h16v16H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>View details</span>
            </li>
          </>
        ) : (
          <>
            <li onClick={editAction}>
              <svg
                width="17"
                height="17"
                fill="none"
                stroke={appTheme.colors.gray[500]}
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)">
                  <path
                    d="M12.083 2.835A1.886 1.886 0 0 1 14.75 5.5l-9 9-3.667 1 1-3.666 9-9Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.75 .834)"
                      d="M0 0h16v16H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>Edit</span>
            </li>
            <li onClick={deleteAction}>
              <svg
                width="17"
                height="17"
                fill="none"
                stroke={appTheme.colors.gray[500]}
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)">
                  <path
                    d="M2.945 4.752H4.28m0 0h10.666m-10.666 0v9.333a1.333 1.333 0 0 0 1.333 1.333h6.667a1.334 1.334 0 0 0 1.333-1.333V4.752H4.279Zm2 0V3.418a1.333 1.333 0 0 1 1.333-1.333h2.667a1.333 1.333 0 0 1 1.333 1.333v1.334"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.945 .752)"
                      d="M0 0h16v16H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>Delete</span>
            </li>
          </>
        )}
      </ThreeModal>
    </ThreeModalActionsContainer>
  );
}
