import { useState } from "react";
import { FormattedMessage } from "react-intl";
import LoadOutCheckbox from "../../components/fragments/loadOutCheckbox";
import ThreeModalActions from "../../components/fragments/threeModalActions";
import iconUser from "../../assets/images/icons/empty-avatar.svg";
import {
  DisplayBlock,
  TableLineResponsive,
  TableLineResponsiveHeader,
} from "../../styles/common";
import {
  TableStatus,
  AddressContainer,
  AddressLine,
  CodeContainer, DevicesLineResponsiveContainer
} from "./style";
import { getRoleName } from "../../utils/utils";

export default function ListItem({
  listData,
  updateData,
  deleteData,
  modalObject,
  setModalObject,
  setAddEditRegister,
  setIdEditRegister,
  setCheckID,
}) {
  const [lineActionsDropdown, setLineActionsDropdown] = useState(false);

  function handleOnChange() {
    setCheckID(listData.id, !listData.active);
  }

  return (
    <>
      <TableLineResponsive>
        <TableLineResponsiveHeader>
          <AddressLine>
            <LoadOutCheckbox
              value={listData.active}
              onChange={handleOnChange}
            />
            <AddressContainer>
              <img src={iconUser} alt="Icon User" />
              <DisplayBlock>
                <h5>{listData.name}</h5>
                <TableStatus type={listData.status === 1 ? "Active" : "Inactive"}>
                  <figure />
                  <span>{listData.status === 0 ? "Inactive" : "Active"}</span>
                </TableStatus>
              </DisplayBlock>
            </AddressContainer>
          </AddressLine>
          <ThreeModalActions
            openModal={lineActionsDropdown}
            setOpenModal={setLineActionsDropdown}
            editAction={async () => {
              setAddEditRegister(true);
              setIdEditRegister(listData.id);
              setLineActionsDropdown(false);
              await updateData(listData.id)
            }}
            deleteAction={() => {
              setLineActionsDropdown(false);
              setModalObject({
                type: "alert",
                buttonLabel: "Delete",
                open: true,
                title: "Confirm delete",
                desc: "Are you sure you want to delete this item?",
                action: () => {
                  deleteData(listData.id);
                  setModalObject({
                    ...modalObject,
                    open: false,
                  });
                }
              });
            }}
          />
        </TableLineResponsiveHeader>
        <DevicesLineResponsiveContainer>
          <CodeContainer>
            <h6>
              <FormattedMessage id="tabela.phone" />
            </h6>
            <p>{listData.phone}</p>
          </CodeContainer>
          <CodeContainer>
            <h6>
              <FormattedMessage id="tabela.role" />
            </h6>
            <p>{getRoleName(listData.role)}</p>
          </CodeContainer>
        </DevicesLineResponsiveContainer>
        <DevicesLineResponsiveContainer>
          <CodeContainer>
            <h6>
              <FormattedMessage id="tabela.email" />
            </h6>
            <p>{listData.email}</p>
          </CodeContainer>
        </DevicesLineResponsiveContainer>
      </TableLineResponsive>
      <tr>
        <td>
          <LoadOutCheckbox
            value={listData.active}
            onChange={() => {
              listData.active = !listData.active;
              setCheckID(listData.id, listData);
            }}
          />
          <span>{listData.name}</span>
        </td>
        <td>
          <span>{listData.phone}</span>
        </td>
        <td>
          <span>{listData.email}</span>
        </td>
        <td>
          <span>{getRoleName(listData.role)}</span>
        </td>
        <td>
          <TableStatus type={listData.status === 1 ? "Active" : ""}>
            <figure />
            <span>{listData.status === 0 ? "Inactive" : "Active"}</span>
          </TableStatus>
        </td>
        <td>
          <ThreeModalActions
            openModal={lineActionsDropdown}
            setOpenModal={setLineActionsDropdown}
            editAction={async () => {
              setAddEditRegister(true);
              setIdEditRegister(listData.id);
              setLineActionsDropdown(false);
              await updateData(listData.id)
            }}
            deleteAction={() => {
              setLineActionsDropdown(false);
              setModalObject({
                type: "alert",
                buttonLabel: "Delete",
                open: true,
                title: "Confirm delete",
                desc: "Are you sure you want to delete this item?",
                action: async () => await deleteData(listData.id),
              });
            }}
          />
        </td>
      </tr>
    </>
  );
}
