import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { DropdownField } from "../loadOutDropdown/style";

export const HeaderContainer = styled.nav`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    padding: 12px 29px 13px 26px;
    justify-content: space-between;
    background-color: ${appTheme.colors.white};
    z-index: 200;
    position: fixed;
    width: calc(100vw - 105px);
    top: 0;

    @media (max-width: 900px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      & > span {
        display: none;
      }
    }
  `}
`;

export const ViewAllContainer = styled.div`
  ${({ theme }) => css`
    position: relative;

    @media (max-width: 750px) {
      display: none;
    }

    & > div:last-of-type {
      width: 189px;
      right: 0;

      & li {
        gap: 9px;
      }

      & ul {
        overflow: initial;
      }

      & span {
        color: ${appTheme.colors.gray[900]};
      }
    }
  `}
`;

export const ViewAllContainerMobile = styled(ViewAllContainer)`
  ${({ theme }) => css`
    @media (max-width: 750px) {
      display: block;
    }
  `}
`;
export const ViewAllButton = styled(DropdownField)`
  ${({ theme, active }) => css`
    background-color: ${appTheme.colors.white};
    border: 1px solid
      ${active ? appTheme.colors.purple[300] : appTheme.colors.gray[200]};
    padding: 10px 20px;
    width: 250px;
    gap: 8px;
    text-align: center;
    @media (max-width: 750px) {
      padding: 9.14px 12.8px;
    }

    &:hover {
      border: 1px solid ${appTheme.colors.purple[300]};
    }

    & span {
      color: ${appTheme.colors.gray[500]};
      flex-shrink: 0;
      width: 100%;
    }
  `}
`;

export const LogoCube = styled.img`
  ${({ theme }) => css`
    display: none;
    width: 37px;

    @media (max-width: 900px) {
      display: block;
    }
  `}
`;

export const MobileButtons = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (max-width: 900px) {
      display: flex;
      gap: 24px;
    }
  `}
`;

export const HamButton = styled.button`
`;

export const UserActions = styled.div`
  ${({ theme }) => css`
    display: block;

    @media (max-width: 900px) {
      display: none;
    }
  `}
`;
