import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { textMdMedium, textSmMedium } from "../../../styles/typography";
import { ThreeModal } from "../threeModalActions/style";

export const UserNavContainer = styled.div`
  ${({ theme }) => css``}
`;

export const UserSettings = styled.ul`
  ${({ theme }) => css`
    display: flex;
    gap: 23px;
  `}
`;

export const LanguageSettings = styled.ul`
  ${({ theme }) => css`
    border-top: 1px solid ${appTheme.colors.gray[200]};
    padding-top: 10px;
    margin-top: 29px;

    & li:nth-of-type(2) {
      margin-top: 24px;

      @media (max-width: 900px) {
        margin-top: 7px;
      }
    }
  `}
`;

export const LanguageButton = styled.button`
  ${({ theme, active }) => css`
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
    padding: 9px;

    &:hover {
      background-color: ${appTheme.colors.purple[50]};
    }

    @media (max-width: 900px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 17.25px 19.25px;
    }

    & > img {
      display: none;
      transition: 0.3s ease;
      transform: rotate(${active ? "-90deg" : "90deg"});

      @media (max-width: 900px) {
        display: block;
      }
    }

    & .loadTooltip {
      top: 50%;
      left: 45px;
      transform: translate(0, -50%);
    }

    &:hover {
      background-color: ${appTheme.colors.purple[50]};

      & > span {
        color: ${appTheme.colors.purple[500]};
      }

      & .loadTooltip {
        opacity: 1;
        pointer-events: all;
      }
    }
  `}
`;

export const LanguagesDropdown = styled(ThreeModal)`
  ${({ theme, type }) => css`
    top: initial !important;
    left: 0;
    bottom: 40px;
    width: 212px;
    padding: 8px;

    @media (max-width: 900px) {
      bottom: initial;
      width: 100%;
    }

    & li {
      ${textSmMedium}

      &:hover {
        background-color: ${appTheme.colors.purple[25]} !important;

        & span {
          color: ${appTheme.colors.purple[500]};
        }
      }

      & button {
        margin: initial;
        padding: 0;

        &:hover {
          background-color: initial;
        }

        @media (max-width: 900px) {
          padding: 0;
        }
      }

      &:last-of-type {
        margin: 0;
      }
    }
  `}
`;

export const ImageCircle = styled.div`
  ${({ theme }) => css`
    width: 18px;
    height: 18px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: flex;

    & img {
      flex-shrink: 0;
    }
  `}
`;

export const ImageLabel = styled.span`
  ${({ theme }) => css`
    gap: 17px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;

    & > span {
      ${textMdMedium}
      display: none;
      color: ${appTheme.colors.gray[600]};

      @media (max-width: 900px) {
        display: block;
      }
    }
  `}
`;

export const ImageLabelModal = styled(ImageLabel)`
  ${({ theme }) => css`
    & > span {
      display: block;
  `}
`;

export const UserProfile = styled.div`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 14px;

    @media (max-width: 900px) {
      justify-content: space-between;
      padding: 0 20px 0 6px;
      margin-bottom: 19px;
      width: 100%;
    }
  `}
`;

export const ArrowIcon = styled.img`
  ${({ theme, active }) => css`
    transition: 0.3s ease;
    transform: ${active ? "rotate(0)" : "rotate(180deg)"};
    width: 16px;

    display: block;

    @media (max-width: 900px) {
      display: none;
    }
  `}
`;

export const BallsIcon = styled.img`
  ${({ theme, active }) => css`
    display: none;

    @media (max-width: 900px) {
      display: block;
    }
  `}
`;

export const UserProfilePicture = styled.img`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;
  `}
`;

export const MobileHidden = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      display: none;
    }
  `}
`;

export const LogOutContainer = styled.li`
  ${({ theme }) => css`
    display: none;

    @media (max-width: 900px) {
      display: block;
      padding-top: 33px;
      border-top: 1px solid ${appTheme.colors.gray[200]};
      margin-top: 3px;

      & a {
        color: ${appTheme.colors.gray[900]};

        &:hover {
          color: ${appTheme.colors.purple[500]};
        }

        & svg {
          stroke: ${appTheme.colors.gray[900]};
        }
      }
    }
  `}
`;

export const UserProfileActions = styled.ul`
  ${({ theme, active }) => css`
    opacity: ${active ? '1' : '0'};
    pointer-events: ${active ? "all" : "none"};
    position: absolute;
    width: 212px;
    padding: 8px;
    z-index: 20;
    right: 0;
    transition: 0.3s ease;
    top: ${active ? "62px" : "55px"};
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    background-color: ${appTheme.colors.white};
    border-radius: 8px;

    @media (max-width: 900px) {
      top: ${active ? "125px" : "55px"};
    }

    & li:last-of-type {
      position: relative;
      margin-top: 13px;

      &:before {
        position: absolute;
        content: "";
        height: 1px;
        width: 100%;
        top: -8px;
        background-color: ${appTheme.colors.gray[100]};
      }
    }
  `}
`;

export const SettingsDropdown = styled.ul`
  ${({ theme, active }) => css`
    display: ${active ? "block" : "none"};
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 289px;
    background-color: ${appTheme.colors.white};

    & li {
      ${textMdMedium}
      padding: 12px 8px;
      white-space: nowrap;
      background-color: ${appTheme.colors.white};
      transition: 0.3s ease;

      & a,
      span {
        color: ${appTheme.colors.gray[600]};
      }
      &:hover {
        background-color: ${appTheme.colors.purple[50]};
        cursor: pointer;

        & a,
        span {
          color: ${appTheme.colors.purple[500]};
        }
      }
    }

    @media (max-width: 900px) {
      position: relative;
      bottom: 0;
      width: 100%;
      max-height: 384px;
      overflow-y: scroll;
    }
  `}
`;
