import styled, { css } from "styled-components";
import { DriverItemModal } from "../../../styles/common";
import appTheme from "../../../styles/themes";
import { textSmMedium } from "../../../styles/typography";

export const ThreeModalActionsContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    cursor: pointer;

    & > svg {
      transition: 0.3s ease;

      &:hover {
        stroke: ${appTheme.colors.gray[900]};
      }
    }
  `}
`;

export const ThreeModal = styled(DriverItemModal)`
  ${({ theme, type }) => css`
    right: 0;
    width: 212px;
    padding: 8px;

    & li {
      ${textSmMedium}
      gap: 12px;
      display: flex;
      cursor: pointer;
      transition: 0.3s ease;
      color: ${appTheme.colors.gray[500]};
      align-items: center;
      border-radius: 8px;
      padding: 12px 16px;

      & svg {
        transition: 0.3s ease;
      }

      &:hover {
        color: ${appTheme.colors.purple[500]};
        background-color: ${appTheme.colors.purple[25]};

        & svg {
          stroke: ${appTheme.colors.purple[500]};
        }
      }

      &:last-of-type {
        margin-top: 4px;

        ${type !== "counseling" &&
    css`
          &:hover {
            color: ${appTheme.colors.red[500]};
            background-color: ${appTheme.colors.red[50]};

            & svg {
              stroke: ${appTheme.colors.red[500]};
            }
          }
        `}
      }
    }
  `}
`;
