import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import {
  textLgSemiBold,
  textSmMedium,
  textXsMedium,
  textXsRegular,
} from "../../../styles/typography";
import { FileUploader } from "react-drag-drop-files";

export const TabTitleSection = styled.h4`
  ${({ theme }) => css`
    ${textLgSemiBold}
    display: flex;
    align-items: center;
    color: ${appTheme.colors.gray[900]};
    margin: 0 0 8px;

    @media (max-width: 734px) {
      margin: 0;
    }
  `}
`;

export const TabSubTitleSection = styled.p`
  ${({ theme }) => css`
    ${textXsRegular}
    color: ${appTheme.colors.gray[400]};
    margin-bottom: 12px;
    display: flex;
  `}
`;

export const SubTitleFile = styled.span`
  ${({ theme }) => css`
    ${textXsMedium}
    color: ${appTheme.colors.purple[900]};
    margin-left: 4px;
    max-width: 178px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: inline-block;
  `}
`;

export const UploadFileFigure = styled.img`
  ${({ theme }) => css`
    margin-bottom: 16px;
  `}
`;

export const UploadFileLabel = styled.label`
  ${({ theme }) => css`
    width: 512px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    padding: 32.9px 24px;
    border: 2px dashed ${appTheme.colors.gray[200]};
    background-color: ${appTheme.colors.white};
    border-radius: 8px;
    transition: 0.3s;
    margin-bottom: 42px;

    &:hover {
      background-color: ${appTheme.colors.purple[25]};
    }

    & button {
      width: fit-content;
    }

    @media (max-width: 734px) {
      width: 100%;
      margin-bottom: 62px;
    }
  `}
`;

export const UploadFileDesc = styled.p`
  ${({ theme }) => css`
    ${textSmMedium}
    text-align: center;
    margin-bottom: 4px;
    color: ${appTheme.colors.purple[500]};

    & strong {
      font-weight: 400;
      color: ${appTheme.colors.gray[500]};
    }
  `}
`;

export const UploadFileSize = styled.p`
  ${({ theme }) => css`
    ${textXsMedium}
    color: ${appTheme.colors.gray[400]};
    margin-bottom: 16px;
  `}
`;

export const TabHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;

    @media (max-width: 734px) {
      flex-direction: column;
      align-items: initial;

      & button {
        margin-bottom: 11.5px;
      }
    }
  `}
`;

export const TabTitles = styled.div`
  ${({ theme }) => css`
    flex-shrink: 0;
  `}
`;
