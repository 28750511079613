import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import appTheme from "../../../styles/themes";
import { textLgMedium, textMdMedium } from "../../../styles/typography";

export const LeftMenuNav = styled.nav`
  ${({ theme, active }) => css`
    position: fixed;
    z-index: 250;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    transition: 0.3s ease;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 23.5px 33.75px 22.5px;
    background-color: ${appTheme.colors.white};
    border-right: 1px solid ${appTheme.colors.gray[200]};

    @media (max-width: 900px) {
      pointer-events: ${active ? "all" : "none"};
      opacity: ${active ? '1' : '0'};
      left: ${active ? '0' : "-20px"};
      align-items: initial;
      overflow-y: scroll;
      padding: 16px 23px;
      width: 50%;
    }

    @media (max-width: 734px) {
      width: 100%;
    }
  `}
`;

export const LeftMenuHeader = styled.header`
  ${({ theme }) => css`
    display: none;
    justify-content: space-between;
    margin-bottom: 36px;

    & h3 {
      ${textLgMedium}
      color: ${appTheme.colors.gray[900]}
    }

    @media (max-width: 900px) {
      display: flex;
    }
  `}
`;

export const HamButton = styled.img`
  ${({ theme }) => css`
    cursor: pointer;
  `}
`;

export const LogoCube = styled.img`
  ${({ theme, active }) => css`
    margin: 0 auto 24px;

    @media (max-width: 900px) {
      display: none;
    }
  `}
`;

export const LeftMenuButtons = styled.div`
  ${({ theme, active }) => css``}
`;

export const LeftMenuButton = styled(Link)`
  ${({ theme, active }) => css`
    position: relative;
    ${textMdMedium}
    background-color: ${active
      ? appTheme.colors.purple[50]
      : appTheme.colors.white};
    color: ${active ? appTheme.colors.purple[500] : appTheme.colors.gray[600]};
    align-items: center;
    padding: 11.25px;
    display: flex;
    gap: 19.25px;
    width: auto;
    transition: 0.3s;
    margin-bottom: 8px;
    border-radius: 4px;

    & svg {
      transition: 0.3s;
      flex-shrink: 0;
      stroke: ${active
      ? appTheme.colors.purple[500]
      : appTheme.colors.gray[600]};
      transition: 0.3s ease;
    }

    & > span {
      display: none;
    }

    &:hover {
      color: ${appTheme.colors.purple[500]};
      background-color: ${appTheme.colors.purple[50]};

      & svg {
        stroke: ${appTheme.colors.purple[500]};
      }

      & .loadTooltip {
        opacity: 1;
        pointer-events: all;
      }
    }

    & img {
      transition: 0.3s ease;
      transform: rotate(${active ? "-90deg" : "90deg"});
      display: none;
    }

    & p {
      margin: 0;
      padding: 0;
    }

    @media (max-width: 900px) {
      margin-bottom: 4px;
      padding: 17.25px 19.25px;

      & span {
        display: block;
      }

      & img {
        display: block;
      }

      &:has(> img) {
        justify-content: space-between;
        gap: 0;

        & p {
          display: flex;
          gap: 19.25px;
          align-items: center;
        }
      }
    }
  `}
`;

export const PainelButtons = styled.div`
  ${({ theme, active }) => css``}
`;

export const UserActionsMobile = styled.div`
  ${({ theme, active }) => css`
    display: none;

    @media (max-width: 900px) {
      display: block;
    }
  `}
`;
