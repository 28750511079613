import { StyledLink } from "./style";

const LoadOutLink = ({ to, label, onClick }) => {
  return (
    <span>
      <StyledLink onClick={onClick} to={to}>
        {label}
      </StyledLink>
    </span>
  );
};

export default LoadOutLink;
