import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import DragUploadFile from "../../../components/fragments/dragUploadFile";
import FeedBackModal from "../../../components/fragments/feedBackModal";
import Loading from "../../../components/fragments/Loading";

export default function GateImport() {
  const history = useHistory();
  const auth = useAuth();
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");

  const [loading, setLoading] = useState();
  const [modalObject, setModalObject] = useState({
    type: "error",
    buttonLabel: "Ok",
    open: false,
    title: "Oops...",
    desc: "",
  });

  function onFileChange(file) {
    setFile(file);
    setFileName(file.name);
  }

  async function importFile() {
    if (file === "") {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: "No file uploaded",
      });

      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("filetocontact", file);

    try {
      const { data } = await auth.postContactImport(formData);
      if (data.res) {
        history.push("/import/contact/confirm", {
          data: data,
          fileName: fileName,
        });
      }
    } catch ({ response }) {
      //checkTokenValidity(response.data)
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response?.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Loading visible={loading} />
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />

      <DragUploadFile
        title="Contacts"
        fileUploaded={fileName}
        id="upload-contact"
        importCsv={importFile}
        onFileChange={onFileChange}
        toolTipTitle="Contacts"
        toolTipDesc="Use template to import"
        html="contact-template.csv"
      />
    </>
  );
}
