import styled, { css } from "styled-components";
import appTheme from "./themes";
import {
  displayXsSemiBold,
  textLgMedium,
  textLgSemiBold,
  textMdMedium,
  textMdSemiBold,
  textSmMedium,
  textSmRegular,
  textSmSemiBold,
  textXsMedium,
  textXsRegular,
  textXsSemiBold,
} from "./typography";
import { DropdownField } from "../components/fragments/loadOutDropdown/style";

export const RosterTable = styled.table`
  ${({ theme }) => css`
    width: 100%;

    & thead {
      background-color: ${appTheme.colors.gray[25]};

      @media (max-width: 1270px) {
        display: none;
        
      }

      & th {
        
        ${textXsSemiBold}
        cursor: pointer;
        color: ${appTheme.colors.gray[500]};
        padding: 14px 0 14px 24px;

        & > span {
          display: flex;
          gap: 4px;
        }

        &:first-of-type {
          min-width: 206px;
          display: flex;

          & span {
            margin-right: 4px;
            margin-left: 12px;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          min-width: 30px;
        }

        &:nth-of-type(4) {
          min-width: 33px;
        }
        &:nth-of-type(5) {
          min-width: 73px;
        }
        &:nth-of-type(6) {
          min-width: 63px;
        }
      }
    }

    & tbody {
      
      & tr {
        
        &:not(:first-of-type) {
          border-top: 1px solid ${appTheme.colors.gray[100]};
        }

        @media (max-width: 1270px) {
          display: none;
        }
      }
      & td {
        
        height: 72px;
        padding: 18.33px 10px 17.61px 0;

        &:first-of-type {
          align-items: flex-start;
          padding: 30px 0 26px 24px;
          display: flex;
          gap: 12px;
        }
      }
    }
  `}
`;

export const CardFormContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${appTheme.colors.white};
    border: 1px solid ${appTheme.colors.gray[200]};
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    margin-bottom: 24px;
    

    @media (max-width: 1270px) {
      box-shadow: initial;
      background-color: initial;
      border: none;
    }
  `}
`;

export const CardFormContainer2 = styled(CardFormContainer)`
  ${({ theme }) => css`
    @media (max-width: 1270px) {
      border: 1px solid ${appTheme.colors.gray[200]};
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  `}
`;

export const MaxWidth = styled.div`
  ${({ maxWidth }) => css`
    max-width: ${maxWidth ? maxWidth : "initial"};
  `}
`;

export const CardFormHeader = styled.div`
  ${({ theme }) => css`
    padding: 20px 24px 26px 16px;

    @media (max-width: 1270px) {
      padding: 0;
      margin-bottom: 24px;
    }
  `}
`;

export const CardFormHeader2 = styled(CardFormHeader)`
  ${({ theme }) => css`
    @media (max-width: 1270px) {
      padding: 27px 24px 26px 24px;
      margin-bottom: 0;
      border-bottom: 1px solid ${appTheme.colors.gray[100]};
    }
  `}
`;

export const MainTitle = styled.h1`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    & span {
      ${displayXsSemiBold}
      color: ${appTheme.colors.purple[900]};
    }

    @media (max-width: 610px) {
      margin-bottom: 24px;
    }
  `}
`;

export const FormRosterContainer = styled.main`
  ${({ theme }) => css`
    padding: 1px 54px 0 52px;
    background-color: ${appTheme.colors.gray[40]};
    min-height: 100vh;
    
    @media (max-width: 1270px) {
      background-color: ${appTheme.colors.white};
    }

    @media (max-width: 610px) {
      padding: 32px 16px;
    }
  `}
`;

export const CardFormHeaderActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 13.5px;
    justify-content: space-between;

    @media (max-width: 610px) {
      margin-bottom: 24px;
    }
  `}
`;

export const RosterRefreshHeader = styled.h3`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    margin: 0;
    gap: 8px;

    & span {
      ${textLgMedium}
      color: ${appTheme.colors.gray[900]};
    }

    & img {
      cursor: pointer;
    }
  `}
`;

export const RosterHeaderButtons = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 12px;

    & > button {
      width: fit-content;
    }

    @media (max-width: 750px) {
      display: none;
    }
  `}
`;

export const RosterHeaderButtonsMobile = styled(RosterHeaderButtons)`
  ${({ theme }) => css`
    display: none;

    @media (max-width: 750px) {
      display: flex;
      width: 100%;

      & button {
        width: 100%;
        justify-content: center;
      }
    }
  `}
`;

export const CardFormHeaderSearch = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 18px;

    @media (max-width: 750px) {
      flex-direction: column;
      gap: 26px;
    }
  `}
`;

export const DisplayBlock = styled.div`
  ${({ theme }) => css`
    display: block;
  `}
`;

export const CardFormHeaderSearchMobile = styled(CardFormHeaderSearch)`
  ${({ theme }) => css`
    @media (max-width: 750px) {
      flex-direction: row;
    }
  `}
`;

export const ItemTime = styled.div`
  ${({ theme, active }) => css`
    position: relative;

    & > button {
      ${textSmMedium}
      color: ${appTheme.colors.gray[500]};
      /* border-bottom: 1px solid ${appTheme.colors.gray[500]};
      line-height: 15px; */
    }
  `}
`;

export const ModalDropdownImg = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`;

export const ModalDropdownImgButton = styled.button`
  ${({ theme, active }) => css`
    ${textSmMedium}
    width: 100%;
    padding: 6px 6px 6px 8px;
    justify-content: space-between;
    border: ${active
      ? `1px solid ${appTheme.colors.purple[300]}`
      : "1px solid transparent"};
    color: ${appTheme.colors.gray[500]};
    transition: 0.3s ease;
    align-items: center;
    position: relative;
    border-radius: 6px;
    display: flex;

    & img {
      transition: 0.3s ease;
      transform: rotate(${active ? 0 : "180deg"});
    }

    &:hover {
      border: 1px solid
        ${active ? appTheme.colors.purple[300] : appTheme.colors.gray[300]};
    }
  `}
`;

export const MessageActionModal = styled.div`
  ${({ theme, active }) => css`
    opacity: ${active ? 1 : 0};
    top: ${active ? "-20px" : "35px"};
    left: ${active ? "30px" : "0px"};
    pointer-events: ${active ? "all" : "none"};
    transition: 0.3s ease;
    position: absolute;
    width: 100%;
    justify-content: end
    border-radius: 8px;
    background-color: ${appTheme.colors.white};
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    overflow: hidden;
    z-index: 50000;

    & div[type="find"] {
      border-radius: 0;
    }
  `}
`;

export const DriverItemModal = styled.div`
  ${({ theme, active }) => css`
    opacity: ${active ? 1 : 0};
    top: ${active ? "50px" : "35px"};
    pointer-events: ${active ? "all" : "none"};
    transition: 0.3s ease;
    position: absolute;
    border-radius: 8px;
    background-color: ${appTheme.colors.white};
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    overflow: hidden;
    z-index: 50;

    & div[type="find"] {
      border-radius: 0;
    }
  `}
`;

export const DriverItemList = styled.ul`
  ${({ theme, active }) => css`
    padding-bottom: 18px;
    overflow-y: scroll;
    max-height: 258px;

    & li {
      ${textSmMedium}
      display: flex;
      cursor: pointer;
      position: relative;
      color: ${appTheme.colors.gray[500]};
      padding: 10px 55px 10px 14px;
      transition: 0.3s ease;
      gap: 8px;

      @media (max-width: 430px) {
        padding: 10px 0 10px 14px;
      }

      &:hover {
        background-color: ${appTheme.colors.purple[25]};
      }
    }
  `}
`;

export const MessageActionItemList = styled.ul`
  ${({ theme, active }) => css`
    padding-bottom: 18px;
    overflow-y: scroll;
    max-height: 258px;
    height: 40px;

    & li {
      ${textSmMedium}
      display: flex;
      cursor: pointer;
      position: relative;
      color: ${appTheme.colors.gray[500]};
      padding: 10px 15px 10px 15px;
      transition: 0.3s ease;
      gap: 8px;

      @media (max-width: 430px) {
        padding: 10px 0 10px 14px;
      }

      &:hover {
        background-color: ${appTheme.colors.purple[25]};
      }
    }
  `}
`;

export const MessageActionItemItem = styled.li`
  ${({ theme, active }) => css`
    ${textSmMedium}
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    color: ${appTheme.colors.gray[500]};
    background-color: ${active
      ? appTheme.colors.purple[25]
      : appTheme.colors.white};
    padding: 10px 15px 10px 15px;
    transition: 0.3s ease;
    gap: 8px;

    &:hover {
      background-color: ${appTheme.colors.purple[25]};
    }

    & span {
      gap: 8px;
      display: flex;
      align-items: center;
    }

    & > img {
      position: absolute;
      right: 14px;
      display: ${active ? "block" : "none"};
    }
  `}
`;

export const DriverItemItem = styled.li`
  ${({ theme, active }) => css`
    ${textSmMedium}
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    color: ${appTheme.colors.gray[500]};
    background-color: ${active
      ? appTheme.colors.purple[25]
      : appTheme.colors.white};
    padding: 10px 10px 10px 14px;
    transition: 0.3s ease;
    gap: 8px;
    
    &:hover {
      background-color: ${appTheme.colors.purple[25]};
    }

    & span {
      gap: 8px;
      display: flex;
      text-align: center;
      width: 100%;
      white-space: nowrap;
    }

    & > img {
      position: absolute;
      right: 14px;
      display: ${active ? "block" : "none"};
    }
  `}
`;

export const DriverDetailsButton = styled.button`
  ${({ }) => css`
    padding: 20px;
    color: ${appTheme.colors.purple[600]};
    background-color: ${appTheme.colors.white};
    border: 1px solid rgba(143, 166, 190, 0.31);
    transition: 0.3s ease;
    text-align: center;
    border-radius: 6px;
    ${textSmMedium}

    &:hover {
      background-color: ${appTheme.colors.purple[600]};
      color: ${appTheme.colors.white};
    }
  `}
`;

export const ArrowOrder = styled.img`
  ${({ theme, down }) => css`
    transition: 0.3s ease;
    transform: rotate(${down ? '0deg' : "-180deg"});
  `}
`;

export const DriverAssociate = styled.h5`
  ${({ }) => css`
    ${textMdMedium}
    color: ${appTheme.colors.gray[400]};
    margin-bottom: 40px;

    @media (max-width: 550px) {
      margin: unset;
    }

    & a {
      ${textMdSemiBold};
      color: ${appTheme.colors.purple[900]} !important;
      text-decoration: none !important;
      margin: 0 !important;
    }
  `}
`;

export const DriverDetailsForm = styled.form`
  ${({ }) => css`
    border-radius: 8px;
    background-color: ${appTheme.colors.white};
    padding: 24px 0 43px;
  `}
`;

export const DetailsFormFooter = styled.footer`
  ${({ }) => css`
    gap: 12px;
    display: flex;
    margin-top: 43px;
    padding: 16px 24px;
    justify-content: flex-end;
    border-top: 1px solid ${appTheme.colors.gray[100]};

    & button {
      width: fit-content;
    }
  `}
`;

export const InputTransparentContainer = styled.div`
  ${({ theme, width }) => css`
    max-width: 138px;
    padding: 8px 7px 8px 15px;
    width: fit-content;
    transition: 0.3s ease;
    border-radius: 6.425px;
    border: 1px solid transparent;

    & span,
    & input {
      ${textSmMedium}
      color: ${appTheme.colors.gray[500]};
    }

    & input {
      width: ${width}px;
      border: none;
      text-underline-offset: 3px;
      text-decoration: underline;
      background-color: transparent;

      &:focus-visible {
        outline: none;
      }
    }

    &:hover {
      border: 1px solid ${appTheme.colors.gray[200]};
    }

    &:focus-within {
      border: 1px solid ${appTheme.colors.purple[300]};
    }

    &:has(span) {
      border: unset;

      &:hover,
      &:focus-within {
        border: unset;
      }
    }
  `}
`;

export const CompanyCityState = styled.h6`
  ${({ theme }) => css`
    ${textXsRegular}
    color: ${appTheme.colors.gray[700]};
    margin: 66.33px 0 24px;
    display: none;

    @media (max-width: 900px) {
      display: block;
    }
  `}
`;

export const RegisterForm = styled.div`
  ${({ theme, width }) => css`
    gap: 24px;
    width: ${width ? width : '640px'};
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 658px) {
      width: 100% !important;
    }

    @media (max-width: 610px) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const RegisterFormOneColumn = styled(RegisterForm)`
  ${({ theme, width }) => css`
    margin: auto;
    width: ${width ? width : "640px"};
    grid-template-columns: 1fr;
  `}
`;
export const FieldContainer = styled.div`
  ${({ theme }) => css`
    position: relative;

    & > div:last-of-type {
      top: 60px;
    }
  `}
`;

export const HideOnMobile = styled.div`
  ${({ theme }) => css`
    flex-shrink: 0;
    @media (max-width: 750px) {
      display: none;
    }
  `}
`;

export const ShowOnMobileFull = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (max-width: 750px) {
      display: flex;
      width: 100%;

      & button {
        justify-content: center;
      }
    }
  `}
`;

export const ShowOnMobile = styled.div`
  ${({ theme }) => css`
    display: none;

    @media (max-width: 750px) {
      display: flex;
    }
  `}
`;

export const DropdownRegisterButton = styled(DropdownField)`
  ${({ theme, date }) => css`
    height: 54px;
    padding: ${date ? "26px 20px 8px" : "17px 17px 17px 20px"};

    & span {
      ${textSmRegular}
      color: ${appTheme.colors.gray[500]};
      align-items: center;
      display: flex;
      gap: 8px;

      & img {
        transition: .4s ease;
        ${date &&
    css`
          margin-top: -20px;
        `}
      }
    }
  `}
`;

export const TableLineResponsive = styled.div`
  ${({ theme }) => css`
    display: none;
    
    @media (max-width: 1270px) {
      display: block;
      margin-bottom: 24px;
      background-color: ${appTheme.colors.white};
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
    }
  `}
`;

export const TableLineResponsive2 = styled(TableLineResponsive)`
  ${({ theme }) => css`
  
    @media (max-width: 1270px) {
      margin: 0 26px 16px;
    }
  `}
`;

export const TableLineResponsiveHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
 
    align-items: center;
    justify-content: space-between;
    background-color: ${appTheme.colors.gray[50]};
    border-radius: 8px 8px 0 0;
    padding: 14.5px 32.33px 14.5px 21px;
    width: 100%;
  `}
`;

export const TableLineResponsiveContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`;

export const ImportsTabHeader = styled.li`
  ${({ theme, active }) => css`
    ${textMdMedium}
    color: ${active ? appTheme.colors.purple[500] : appTheme.colors.gray[500]};
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 0 82px 16px;
    list-style-type: none;
    border-bottom: 2px solid
      ${active ? appTheme.colors.purple[500] : "transparent"};
    transition: 0.3s ease;

    &:hover {
      border-bottom: 2px solid ${appTheme.colors.purple[500]};
      color: ${appTheme.colors.purple[500]};
    }
  `}
`;

export const GridTwoColumns = styled.div`
  ${({ theme, active }) => css`
    ${textMdMedium}
    gap: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const ProfilePicture = styled.div`
  ${({ theme, width }) => css`
    ${textMdMedium}
    height: ${width}px;
    width: ${width}px;
    border-radius: 100%;
    overflow: hidden;
  `}
`;

export const DriverItemModalButton = styled.button`
  ${({ theme, active }) => css`
    width: 100%;
    position: relative;
    border-radius: 6.425px;
    padding: 8.39px 11.24px;
    background-color: ${appTheme.colors.white};
    border: ${active
      ? `1px solid ${appTheme.colors.purple[300]}`
      : "1px solid transparent"};
    transition: 0.3s ease;
    align-items: center;
    display: flex;
    gap: 6.06px;

    &:hover {
      border: ${active
      ? `1px solid ${appTheme.colors.purple[300]}`
      : `1px solid ${appTheme.colors.gray[200]}`};
    }

    & span {
      ${textSmMedium}
      color: ${appTheme.colors.gray[500]};
    }

    & > img {
      top: 50%;
      right: 16.24px;
      position: absolute;
      transform: translate(0, -50%) rotate(90deg);
    }
  `}
`;

export const SentTableStatus = styled.div`
  ${({ theme }) => css`
    width: fit-content;
    padding: 2px 8px 2px 7px;
    background-color: ${appTheme.colors.green[50]};
    border-radius: 16px;

    & span {
      color: ${appTheme.colors.green[700]};
      ${textXsMedium}
    }
  `}
`;

export const StatusWarning = styled(SentTableStatus)`
  ${({ theme }) => css`
    background-color: ${appTheme.colors.orange[50]};

    & span {
      color: ${appTheme.colors.orange[700]};
    }
  `}
`;

export const StatusNeutral = styled(SentTableStatus)`
  ${({ theme }) => css`
    background-color: ${appTheme.colors.gray[100]};

    & span {
      color: ${appTheme.colors.gray[700]};
    }
  `}
`;

export const StatusAlert = styled(SentTableStatus)`
  ${({ theme }) => css`
    background-color: ${appTheme.colors.red[50]};

    & span {
      color: ${appTheme.colors.red[700]};
    }
  `}
`;

export const ResponsiveHeaderTexts = styled.div`
  ${({ theme }) => css`
    & h5 {
      ${textSmMedium}
      color: ${appTheme.colors.gray[500]};
      margin-bottom: 4px;
    }
    & p {
      margin-bottom: 8px;
      ${textMdSemiBold}
      color: ${appTheme.colors.gray[900]};
    }
  `}
`;

export const BreadcrumbContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: 24px;
  `}
`;

export const SectionContainer = styled.div`
  ${({ theme }) => css`
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    background-color: ${appTheme.colors.white};

    @media (max-width: 1270px) {
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
        0px 4px 6px -2px rgba(16, 24, 40, 0.05);

      &:nth-of-type(2) {
        border-radius: 0 0 8px 8px;
      }
    }
  `}
`;

export const SectionTitle = styled.h5`
  ${({ theme }) => css`
    ${textLgSemiBold}
    color: ${appTheme.colors.purple[900]};
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      width: fit-content;
    }

    @media (max-width: 750px) {
      flex-direction: column;
      align-items: initial;
      gap: 24px;

      & button {
        width: 100%;
      }
    }
  `}
`;

export const ButtonsRightContainer = styled.div`
  ${({ theme }) => css`
    gap: 8px;
    width: 100%;
    display: flex;
    margin-bottom: 32px;
    justify-content: flex-end;

    & button {
      width: fit-content;
    }
  `}
`;

export const ButtonsRightWidth = styled(ButtonsRightContainer)`
  ${({ theme }) => css`
    @media (max-width: 750px) {
      & button {
        width: 100%;
      }
    }
  `}
`;

export const ButtonsRightFooter = styled(ButtonsRightContainer)`
  ${({ theme }) => css`
    gap: 12px;
    margin-top: 40px;
  `}
`;

export const SelectedActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 12px;

    & button {
      white-space: nowrap;

      &:last-of-type {
        width: fit-content;
        flex-shrink: 0;

        svg {
          stroke: ${appTheme.colors.red[600]};
        }
      }
    }
  `}
`;

export const GatesLineResponsiveContainer = styled(
  TableLineResponsiveContainer
)`
  ${({ theme }) => css`
    display: flex;
    padding: 31px 28px 30px;
    gap: 32px;

    & h6 {
      ${textSmSemiBold}
      color: ${appTheme.colors.gray[900]};
      margin-bottom: 10px;

      & span {
        ${textSmSemiBold}
        color: ${appTheme.colors.gray[900]};
      }
    }

    & p {
      ${textSmMedium}
      margin:0;
    }
  `}
`;
