import React, { useEffect, useState } from "react";
import uploadFigure from "../../../assets/images/upload-figure.svg";
import LoadOutButton from "../loadOutButton";
import Tooltip from "../tooltip";
import WeekDropdown from "../weekDropdown";
import appTheme from "../../../styles/themes";
import {
  SubTitleFile,
  TabHeader,
  TabSubTitleSection,
  TabTitles,
  TabTitleSection,
  UploadFileDesc,
  UploadFileFigure,
  UploadFileLabel,
  UploadFileSize,
} from "./style";

export default function DragUploadFile({
  title,
  fileName,
  onFileChange,
  id,
  toolTipTitle,
  toolTipDesc,
  yearValue,
  setYearValue,
  weekDropdownValue,
  setWeekDropdownValue,
  weekDropdown,
  importCsv,
}) {
  const [scorecards, setScorecards] = useState([]);

  function importFile() {
    /*if (file === "") {
      alert("No file uploaded");
    }
    setLoading(true);
    const data = new FormData();
    data.append("filetomentor", file);
    api
      .post("upload/mentor", data, {
        headers: {
          "x-access-token": GetToken(),
          "Content-Type": "multipart/form-data;",
        },
      })
      .then((data) => {
        props.setMentorMethod(data.data);
        props.setFileNameMentorImport(fileName);
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Success",
          desc: "Import success!",
        });
        history.push("/imports/mentor/confirm");
      })
      .catch((err) => {
        setLoading(false);
        setModalObject({
          type: "error",
          buttonLabel: "Ok",
          open: true,
          title: "Oops...",
          desc: err.response?.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });*/
  }

  function handleClick(event) {
      const { target = {} } = event || {};
      target.value = "";
  }

  return (
    <>
      <TabHeader>
        
      </TabHeader>
      <UploadFileLabel style={{ width: "100%", height: "calc(100% - 30px)" }}
        className="d-flex justify-content-center"
        htmlFor={id}
        onDrop={(e) => {
          e.preventDefault();
          //console.log(e.dataTransfer.files);
          onFileChange(e.dataTransfer.files[0]);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <UploadFileFigure
          className="img-fluid w-auto"
          src={fileName ?? uploadFigure}
          alt="Image to upload"
        />

        <UploadFileDesc style={{ display: fileName ? "none" : ""}}>
          Drag an image file here <span>or click to open.</span>
        </UploadFileDesc>

        <LoadOutButton
          color={appTheme.colors.purple[500]}
          disabled={!fileName}
          visible={ fileName ? true : false}
          label=""
          size="xs"
          icon="trash"
          onClick={() => { onFileChange(undefined); }}
        />

        <input
          type="file"
          name={id}
          className="upload-photo"
          id={id}
          accept=".png, .jpg, .jpeg, .gif"
          onChange={(e) => onFileChange(e.target.files[0])}
          onClick={(e) => handleClick(e)}
        />
      </UploadFileLabel>
    </>
  );
}
