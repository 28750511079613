import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";

export const CheckboxGroup = styled.div`
  ${({ theme }) => css`
    align-items: center;
    position: relative;
    display: flex;
  `}
`;

export const CheckboxField = styled.input`
  ${({ theme }) => css`
    width: 16px;
    height: 16px;
    cursor: pointer;
    appearance: none;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid ${appTheme.colors.gray[300]};
    -webkit-appearance: none;
    transition: 0.3s ease;

    &[type="checkbox"]:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      background-color: ${appTheme.colors.purple[500]};
      border: 0px solid ${appTheme.colors.purple[500]};
    }
  `}
`;

export const CheckboxLabel = styled.label`
  ${({ theme }) => css`
    margin-left: 8px;
    color: ${appTheme.colors.gray[700]};
    font-weight: 500;
    font-size: 14px;
  `}
`;
