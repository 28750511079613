import moment from "moment";
import { useEffect } from "react";
import Swal from "sweetalert2";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  return buildDate;
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref"s element or descendent elements
        if (ref.current && !ref.current.contains(event.target)) {
          handler(event);
        }
      };
      document.addEventListener("click", listener);
      //document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("click", listener);
        //document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It"s worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It"s not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export const useOnPasteImage = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref"s element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      //document.addEventListener("DOMContentLoaded", function () {
        document.addEventListener("paste", listener);
      //});
      return () => {
        document.removeEventListener("paste", listener);
        //document.removeEventListener("DOMContentLoaded", listener);
      };
    }, [ref, handler]
    );
}

export function checkRememberMe() {
  if (localStorage.getItem("rememberMe") !== "null") {
    return {
      emailRemembered: localStorage.getItem("rememberMe"),
      isRemembered: true
    };
  }
  return { emailRemembered: "", isRemembered: false };
}

export function reOrder(tableToBeOrdered, field, typeOfOrder, tableSetterFunction, typeOfOrderFunction) {
  let data = [...tableToBeOrdered.listedData];
  data.sort((a, b) => {
    const asc = typeOfOrder === "asc";
    if (a[field] < b[field]) {
      return asc ? -1 : 1;
    } else if (a[field] > b[field]) {
      return asc ? 1 : -1;
    } else {
      return 0;
    }
  });
  tableSetterFunction({ ...tableToBeOrdered, listedData: data });
  typeOfOrderFunction(typeOfOrder);
}

export function fireAlert(message = "Unexpected Error") {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message
  });
  return
}

export function fireSuccess(message = "Your action was successfully completed.") {
  Swal.fire({
    icon: "success",
    title: "Completed",
    text: message
  });
  return
}

export const roleEnum = {
  "MASTER": 0,
  "OWNER": 1,
  "ADMIN": 2,
  "OPERATOR": 3
}

export const allRoleNames = {
  0: "Master",
  1: "Owner",
  2: "Admin",
  3: "Operator"
}
export const getAllRoleName = (role) => {
  return allRoleNames[role] || " "
}

export const roleNames = {
  2: "Admin",
  3: "Operator"
}
export const getRoleName = (role) => {
  return roleNames[role] || " "
}

export const statusEnum = {
  "ACTIVE": 1,
  "INACTIVE": 0
}

export const statusNames = {
  1: "Active",
  0: "Inactive"
}
export const getStatusName = (status) => {
  return statusNames[status] || " "
}

export const checkTokenValidity = (error) => {
  if (!error.auth) {
    localStorage.removeItem("token");
    localStorage.removeItem("tenant");
    localStorage.removeItem("realtime");
    localStorage.removeItem("lineList");
    localStorage.removeItem("line");
    window.location.replace("/?session=expired");
    return;
  }
  console.log("auth");
};

export function formatDate(d) {

  if (d === "Waiting ...") {
    return d;
  }

  const tmpDate = d.split(" ").join("T").concat(".000Z");
  const date = new Date(tmpDate);

  /*var options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  };*/

  const ts = new Intl.DateTimeFormat('en-US', { year: "numeric", month: "numeric", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true });

  //return date.toLocaleDateString("en-US", options);
  return ts.format(date);
}

export async function hardReload() {
  // await navigator.serviceWorker.getRegistrations().then((registrations) => {
  //   registrations.forEach((registration) => {
  //     registration.unregister();
  //   });
  // });
  // await caches.keys().then((keyList) => {
  //   return Promise.all(
  //     keyList.map((key) => {
  //       return caches.delete(key);
  //     })
  //   );
  // });
  // setTimeout(() => {
  //   window.location.reload();
  // }, 100);
}