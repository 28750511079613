import { Bar } from "react-chartjs-2";

const data = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  datasets: [
    {
      label: "Income",
      backgroundColor: "rgba(197, 145, 237,0.2)",
      borderColor: "rgba(197, 145, 237,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(197, 145, 237,0.4)",
      hoverBorderColor: "rgba(197, 145, 237,1)",
      data: [
        6547, 4891, 2357, 9008, 6785, 16454, 12768, 8764, 6567, 5436, 8997,
        6547,
      ],
    },
  ],
};

export const MonthlyRevenueChart = () => {
  return (
    <div>
      <h5>Monthly Income</h5>
      <Bar data={data} />
    </div>
  );
};
