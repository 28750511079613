import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import { textSmRegular } from "../../../styles/typography";
import { GroupField, InputField } from "../loadOutInput/style";

export const EyeButton = styled.img`
  ${({ theme }) => css`
    transform: translate(0, -50%);
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 50%;
  `}
`;

export const PhoneGroupField = styled(GroupField)`
  ${({ theme }) => css`
    padding: 10px 20px 10px 20px !important;
    position: relative;
    display: flex;
    gap: 12px;
    & div {
      left: 0;
      top: 65px;

      & ul {
        overflow-y: initial;
      }
    }
  `}
`;

export const CountryButton = styled.button`
  ${({ theme, active }) => css`
    display: flex;
    gap: 4px;

    & span {
      ${textSmRegular}
      color: ${appTheme.colors.gray[900]};
    }

    & img:nth-of-type(2) {
      transition: 0.3s ease;
      transform: rotate(${active ? "-90deg" : "90deg"});
    }
  `}
`;

export const InputPhoneField = styled(InputField)`
  ${({ theme }) => css`
    padding: 0;
    color: ${appTheme.colors.gray[500]};
  `}
`;
