import { CheckboxGroup, CheckboxField, CheckboxLabel } from "./style";

const LoadOutCheckbox = ({ label, required, disabled, value, onChange }) => {
  return (
    <CheckboxGroup className="groupField">
      <CheckboxField
        id={label ? `${label}_lo` : ""}
        type="checkbox"
        onChange={onChange}
        checked={value}
        disabled={disabled}
        required={required}
      />
      {label && <CheckboxLabel htmlFor={`${label}_lo`}>{label}</CheckboxLabel>}
    </CheckboxGroup>
  );
};

export default LoadOutCheckbox;
