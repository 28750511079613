import { useState } from "react";
import { InputField, SuportLabel } from "../loadOutInput/style";
import { GroupField, DropdownPhone, DropdownSelected } from "./style";
import iconArrow from "../../../assets/images/icons/arrow-right.svg";

const InputPhoneMask = ({
  value,
  groupStyle,
  placeholder,
  width,
  suport,
  required,
  disabled,
  invalid,
  onChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <>
      <GroupField
        style={groupStyle}
        width={width}
        active={value ? "value" : undefined}
        className="groupField"
      >
        <DropdownPhone>
          <DropdownSelected>
            <p>US</p>
            <img src={iconArrow} alt="Seta" />
          </DropdownSelected>
        </DropdownPhone>
        <InputField
          onChange={onChange}
          value={value || ""}
          placeholder={placeholder}
          disabled={disabled}
        />
      </GroupField>
      {suport && <SuportLabel>{suport}</SuportLabel>}
    </>
  );
};

export default InputPhoneMask;
