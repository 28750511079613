import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { textXsMedium, textXsRegular } from "../../../styles/typography";

export const TooltipContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-left: 8px;
  `}
`;

export const TooltipButton = styled.img`
  ${({ theme }) => css`
    cursor: pointer;
  `}
`;

export const TooltipCard = styled.div`
  ${({ theme, active }) => css`
    left: 50%;
    padding: 12px;
    text-align: left;
    position: absolute;
    pointer-events: ${active ? "all" : "none"};
    background-color: ${appTheme.colors.gray[900]};
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    bottom: ${active ? "32px" : "27px"};
    transform: translate(-46%, 0);
    opacity: ${active ? '1' : '0'};
    transition: 0.3s ease;
    border-radius: 8px;
    width: 320px;
    z-index: 200;

    &::after {
      background-color: ${appTheme.colors.gray[900]};
      transform: translate(-50%, 0) rotate(45deg);
      position: absolute;
      bottom: -6px;
      height: 12px;
      width: 12px;
      content: "";
      right: 50%;
    }
  `}
`;

export const TooltipCardTitle = styled.h6`
  ${({ theme }) => css`
    ${textXsMedium}
    color: ${appTheme.colors.white};
    margin-bottom: 4px;
  `}
`;

export const TooltipCardDesc = styled.p`
  ${({ theme }) => css`
    ${textXsRegular}
    color: ${appTheme.colors.white};
  `}
`;
