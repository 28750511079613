import styled, { css } from "styled-components/macro";
import appTheme from "../../styles/themes";
import {
  displayXsSemiBold,
  textMdMedium,
  textMdRegular,
  textXsBold,
  textXsRegular,
  textXsSemiBold,
} from "../../styles/typography";

export const ImportsBG = styled.div`
  ${({ theme }) => css`
    padding: 55px 0 80px;
    background-color: ${appTheme.colors.gray[40]};

    @media (max-width: 734px) {
      background-color: ${appTheme.colors.white};
    }
  `}
`;

export const ImportsContainer = styled.main`
  ${({ theme }) => css`
    background-color: ${appTheme.colors.gray[40]};
    max-width: 714px;
    margin: 0 auto;
    display: block;

    @media (max-width: 734px) {
      width: 100%;
      padding: 0 16px;
      background-color: ${appTheme.colors.white};
    }
  `}
`;

export const ImportsTitle = styled.h1`
  ${({ theme }) => css`
    ${displayXsSemiBold}
    color: ${appTheme.colors.purple[900]};
    margin: 24px 0 20px;
  `}
`;

export const ImportsSubTitle = styled.h2`
  ${({ theme }) => css`
    ${textMdRegular}
    color: ${appTheme.colors.gray[500]};
    margin-bottom: 40px;

    @media (max-width: 734px) {
      margin-bottom: 24px;
    }
  `}
`;

export const ImportsTabsContainer = styled.div`
  ${({ theme }) => css`
    & > div {
      display: none;

      @media (max-width: 734px) {
        display: flex;
      }
    }
  `}
`;

export const StringCodeSection = styled.div`
  ${({ theme }) => css`
    margin-bottom: 96px;

    & button {
      float: right;
      margin-top: 16px;
      width: fit-content;
    }
  `}
`;

export const ImportsTabHeaderContainer = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${appTheme.colors.gray[200]};

    @media (max-width: 734px) {
      display: none;
    }
  `}
`;

export const ImportsTabBodyContainer = styled.ul`
  ${({ theme, active }) => css``}
`;

export const ImportsTabBody = styled.li`
  ${({ theme, active }) => css`
    display: ${active ? "block" : "none"};
    background-color: ${appTheme.colors.white};
    padding: 43px 101px 55px;

    @media (max-width: 734px) {
      padding: 26px 0;
    }
  `}
`;

export const StringCodeContainer = styled.div`
  ${({ theme }) => css`
    ${textXsBold}
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 5.72222px;
    background-color: ${appTheme.colors.gray[50]};
    border: 0.715278px solid ${appTheme.colors.gray[200]};
    box-shadow: 0px 0.715278px 1.43056px rgba(16, 24, 40, 0.05);
  `}
`;

export const StringCodeSideBar = styled.ul`
  ${({ theme }) => css`
    padding: 17.17px;
    border: none;
    background-color: ${appTheme.colors.gray[100]};

    & li {
      ${textXsSemiBold}
      color: ${appTheme.colors.gray[400]};
      font-size: 11px;
    }
  `}
`;

export const StringCodeField = styled.textarea`
  ${({ theme }) => css`
    border: none;
    outline: none;
    width: 100%;
    resize: none;
    height: 370px;
    padding: 17.17px;
    background-color: ${appTheme.colors.gray[50]};
  `}
`;
