import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
//import usePusher from "./usePusher";
import appTheme from "../../styles/themes";
import {
  CompanyCityState,
  FormRosterContainer,
  MainTitle,
} from "../../styles/common";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { getRoleName } from "../../utils/utils";
import { QuarterlySalesChart } from "./chartRevenue";
import { MonthlyRevenueChart } from "./chartIncome";
import FeedBackModal from "../../components/fragments/feedBackModal";

export default function Dashboard() {
  const auth = useAuth();
  const history = useHistory();
  const [counts, setCounts] = useState([]);
  const [company, setCompany] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [modalObj, setModalObj] = useState({
    type: "alert",
    buttonLabel: "Ok",
    open: false,
    title: "Success!",
    desc: "This note has been deleted.",
  });
  const [modalAction, setModalAction] = useState(() => null);
  //usePusher();
  const dataChart = {
    datasets: [
      {
        label: "# of Votes",
        data: [
          counts?.["5"],
          counts?.["4"],
          counts?.["3"],
          counts?.["2"],
          counts?.["1"],
        ],
        backgroundColor: [
          `${appTheme.colors.orange[400]}`,
          `${appTheme.colors.rose[500]}`,
          `${appTheme.colors.green[500]}`,
          `${appTheme.colors.indigo[600]}`,
          `${appTheme.colors.gray[600]}`,
        ],
        offset: 1.6,
        cutout: 65,
      },
    ],
  };

  function printNameScore(score) {
    let result = "";
    switch (score) {
      case 1:
        result = <FormattedMessage id="poor" />;
        break;
      case 2:
        result = <FormattedMessage id="fair" />;
        break;
      case 3:
        result = <FormattedMessage id="great" />;
        break;
      case 4:
        result = <FormattedMessage id="fantastic" />;
        break;
      case 5:
        result = <FormattedMessage id="fantastic_plus" />;
        break;
      default:
        result = "";
    }
    return result;
  }

  /*useEffect(() => {
    // const value = data.data[0];
    // setCompany(value.company);
    // setState(value.state);
    // setCity(value.city);
  });*/

  const styles = {
    dash_container: {
      width: "95%",
    },
    charts_container: {
      margin: 20,
      width: "100%",
      height: "300px",
      justifyContent: "between",
    },
  };

  useEffect(() => {
    if (history.location.search.includes("changed_line")) {
      setModalObj({
        type: "success",
        buttonLabel: "Ok",
        open: true,
        title: "Line Changed",
        desc: "Your line has been updated",
      });
    }
  }, [history]);

  return (
    <FormRosterContainer style={{ paddingTop: window.innerWidth > 768 ? 50 : 0 }}>
      <FeedBackModal
        modalObj={modalObj}
        setModalObj={setModalObj}
        action={modalAction}
      />
      <CompanyCityState>
        {auth.tenantContext.company} : {auth.tenantContext.city} :{" "}
        {auth.tenantContext.state}
      </CompanyCityState>
      <div className="row mt-4">
        <div className="col-lg-4 col-sm-6 mb-4">
          <div className="card h-100">
            <div className="card-body shadow rounded-3">
              <h5 className="card-title ms-3 mt-1" style={{ color: "#8422CF" }}>
                <span>Lines</span>
              </h5>
              <p className="card-text fw-bolder px-3">2</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mb-4">
          <div className="card h-100">
            <div className="card-body shadow rounded-3">
              <h5 className="card-title ms-3 mt-1" style={{ color: "#8422CF" }}>
                <span>Income</span>
              </h5>
              <p className="card-text fw-bolder px-3">$5816.65 USD</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mb-4">
          <div className="card h-100">
            <div className="card-body shadow rounded-3">
              <h5 className="card-title ms-3 mt-1" style={{ color: "#8422CF" }}>
                <span>Role</span>
              </h5>
              <p className="card-text fw-bolder px-3">
                {getRoleName(auth.userContext?.role)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.dash_container}>
        <div style={styles.charts_container} className="row">
          <div className="col-12 col-md-6">
            <MonthlyRevenueChart />
          </div>
          <div className="col-12 col-md-6">
            <QuarterlySalesChart />
          </div>
        </div>
      </div>
    </FormRosterContainer>
  );
}
