import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import iconTic from "../../assets/images/icons/tic.svg";
import { GridContainer } from '../children/company-settings/style'
import LoadOutInput from "../../components/fragments/loadOutInput";
import ModalPattern from "../../components/fragments/modalPattern";
import LoadOutDropdownButton from "../../components/fragments/loadOutDropdownButton";
import {
  DriverItemItem,
  DriverItemList,
  DriverItemModal,
  FieldContainer,
  RegisterFormOneColumn,
} from "../../styles/common";
import FeedBackModal from "../../components/fragments/feedBackModal";
import { useRef } from "react";
import { checkTokenValidity, getRoleName, roleNames, roleEnum, statusNames, getStatusName, statusEnum, useOnClickOutside } from "../../utils/utils";
import { useAuth } from '../../context/AuthProvider/useAuth'

export default function AddEdit({
  openModal,
  setOpenModal,
  refreshData,
  id,
  setId,
  object
}) {
  const auth = useAuth()
  const [userObject, setUserObject] = useState(object)
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [roleDropdown, setRoleDropdown] = useState(false);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  const statusRef = useRef();
  useOnClickOutside(statusRef, () =>
    setStatusDropdown(false)
  );
  const roleRef = useRef();
  useOnClickOutside(roleRef, () =>
    setRoleDropdown(false)
  );

  const resetForm = () => {
    setId("");
    setUserObject({})
    setOpenModal(false);
  };

  async function onSubmit() {
    try {
      if (id) {
        const { data } = await auth.putUserUpdate(id, userObject)
        if (data.res) {
          setModalObject({
            type: "success",
            buttonLabel: "Ok",
            open: true,
            title: "Success",
            desc: "User has been updated",
            action: () => resetForm(),
          });
          refreshData()

        }
        return
      }
      const { data } = await auth.postUserCreate({ ...userObject });
      if (data.res) {
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Success",
          desc: "User has been added",
          action: () => resetForm(),
        });
        refreshData()

      }

    } catch ({ response }) {
      checkTokenValidity(response.data)
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response?.data.message,
      });
    }
  }

  useEffect(() => {
    if (id && object.id) {
      setUserObject(object)
      return
    }
    setUserObject({})
  }, [object, id]);

  return (
    <>
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <ModalPattern
        open={openModal}
        setOpen={setOpenModal}
        title={id ? 'Edit User' : 'Create User'}
        saveAction={onSubmit}
        cancelAction={resetForm}
      >
        <RegisterFormOneColumn width="664px">
          <GridContainer>
            <LoadOutInput
              highlight={<FormattedMessage id="form.phone" />}
              onChange={({ target }) => setUserObject({ ...userObject, phone: target.value })}
              value={userObject?.phone}
            />
            <LoadOutInput
              highlight={<FormattedMessage id="form.email" />}
              onChange={({ target }) => setUserObject({ ...userObject, email: target.value })}
              value={userObject?.email}
            />
          </GridContainer>
          <GridContainer>
            <LoadOutInput
              highlight={<FormattedMessage id="form.fname" />}
              onChange={({ target }) => setUserObject({ ...userObject, fname: target.value })}
              value={userObject?.fname}
            />
            <LoadOutInput
              highlight={<FormattedMessage id="form.lname" />}
              onChange={({ target }) => setUserObject({ ...userObject, lname: target.value })}
              value={userObject?.lname}
            />
          </GridContainer>
          <LoadOutInput
            highlight={<FormattedMessage id="form.username" />}
            onChange={({ target }) => setUserObject({ ...userObject, username: target.value })}
            value={userObject?.username}
          />
          <FieldContainer ref={roleRef}>
            <LoadOutDropdownButton
              openModal={roleDropdown}
              setOpenModal={() => setRoleDropdown(!roleDropdown) }
              placeholder={<FormattedMessage id="form.role" />}
              value={
                getRoleName(userObject.role)
              }
            />
            <DriverItemModal active={roleDropdown}>
              <DriverItemList>
                {Object.keys(roleNames).map((key, index) => {
                  return (<DriverItemItem
                    key={index}
                    active={userObject.role === Number(key)}
                    onClick={() => {
                      setUserObject({ ...userObject, role: Number(key) });
                      setRoleDropdown(false);
                    }}
                  >
                    {getRoleName(Number(key))}
                    <img src={iconTic} alt="Icon Tic" />
                  </DriverItemItem>)
                })}
              </DriverItemList>
            </DriverItemModal>
          </FieldContainer>
          <FieldContainer ref={statusRef}>
            <LoadOutDropdownButton
              openModal={statusDropdown}
              setOpenModal={() => setStatusDropdown(!statusDropdown) }
              placeholder={<FormattedMessage id="form.status" />}
              value={
                getStatusName(userObject.status)
              }
            />
            <DriverItemModal active={statusDropdown}>
              <DriverItemList>
                {Object.keys(statusNames).map((key, index) => {
                  return (<DriverItemItem
                    key={index}
                    active={userObject.status === Number(key)}
                    onClick={() => {
                      setUserObject({ ...userObject, status: Number(key) });
                      setStatusDropdown(false);
                    }}
                  >
                    {getStatusName(Number(key))}
                    <img src={iconTic} alt="Icon Tic" />
                  </DriverItemItem>)
                })}
              </DriverItemList>
            </DriverItemModal>
          </FieldContainer>
        </RegisterFormOneColumn>
      </ModalPattern>
    </>
  );
}
