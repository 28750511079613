const appTheme = {
  colors: {
    white: "#fff",
    black: "#000",
    transparent: "#00000000",
    purple: {
      25: "#FDFAFF",
      50: "#F9F6FF",
      100: "#F8F0FF",
      200: "#EACFFF",
      300: "#CE8EFF",
      400: "#C06DFF",
      500: "#8422CF",
      600: "#651D9C",
      700: "#4B0D7A",
      800: "#330258",
      900: "#170028",
    },
    purpleLight: "#C291E7",
    red: {
      25: "#FFFBFA",
      50: "#FEF3F2",
      200: "#FECDCA",
      300: "#FDA29B",
      500: "#F04438",
      600: "#D92D20",
      700: "#B42318",
    },
    gray: {
      25: "#FCFCFD",
      40: "#FBFBFB",
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#E4E7EC",
      300: "#D0D5DD",
      350: "#8FA6BE",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#344054",
      800: "#1D2939",
      900: "#101828",
    },
    blue: {
      50: "#EFF8FF",
      500: "#2E90FA",
      700: "#175CD3",
    },
    indigo: {
      600: "#444CE7",
    },
    green: {
      25: "#F6FEF9",
      50: "#ECFDF3",
      300: "#6CE9A6",
      500: "#39DED5",
      600: "#039855",
      700: "#027A48",
    },
    success: {
      500: "#12B76A",
    },
    rose: {
      500: "#F63D68",
      600: "#E31B54",
    },
    orange: {
      25: "#FFFCF5",
      50: "#FFFAEB",
      300: "#FEC84B",
      400: "#FD853A",
      500: "#F79009",
      600: "#DC6803",
      700: "#B54708",
    },
  },
};

export default appTheme;
