import {
  ModalContainer,
  ModalDesc,
  ModalTitle,
  ModalIcon,
  AlertButtons,
  ModalComposition,
  ModalClose,
} from "./style";
import { useEffect, useRef, useState } from "react";
import headerSuccessIcon from "../../../assets/images/icons/header-tic.svg";
import headerWarningIcon from "../../../assets/images/icons/header-warning.svg";
import headerAlertIcon from "../../../assets/images/icons/header-alert.svg";
import LoadOutButton from "../loadOutButton";
import { useOnClickOutside } from "../../../utils/utils";

const FeedBackModal = ({ modalObj, setModalObj }) => {
  const [openModal, setOpenModal] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpenModal(false));

  useEffect(() => {
    setOpenModal(modalObj.open);
  }, [modalObj]);

  const closeModal = () => {
    setModalObj({
      ...modalObj,
      open: false,
    });
    setOpenModal(false);
  };

  const getModalIcon = () => {
    switch (modalObj.type) {
      case "success":
        return headerSuccessIcon;
      case "warning":
        return headerWarningIcon;
      default:
        return headerAlertIcon;
    }
  };

  return (
    <ModalComposition open={openModal}>
      <ModalContainer ref={ref} open={openModal}>
        <ModalClose onClick={closeModal}>
          <svg
            width="14"
            height="15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m13 1.355-12 12m0-12 12 12"
              stroke="#101828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ModalClose>
        <ModalIcon src={getModalIcon()} alt="Modal Icon" />
        <ModalTitle>{modalObj.title}</ModalTitle>
        {modalObj.desc && <ModalDesc>{modalObj.desc}</ModalDesc>}
        {modalObj.type === "success" ? (
          <LoadOutButton
            label={modalObj.buttonLabel}
            onClick={() => {
              modalObj.action && modalObj.action();
              closeModal();
            }}
          />
        ) : (
          <AlertButtons>
            {modalObj.type !== "error" && (
              <LoadOutButton
                color="white"
                label="Cancel"
                onClick={closeModal}
              />
            )}
            <LoadOutButton
              color={modalObj.type === "warning" ? "" : "red"}
              label={modalObj.buttonLabel}
              onClick={modalObj.action ? modalObj.action : closeModal}
            />
          </AlertButtons>
        )}
      </ModalContainer>
    </ModalComposition>
  );
};

export default FeedBackModal;
