import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";

const getButtonColor = (color, hover) => {
  switch (color) {
    case "white":
    case "border":
      return hover ? appTheme.colors.gray[50] : appTheme.colors.white;
    case "red":
      return hover ? appTheme.colors.red[700] : appTheme.colors.red[600];
    case "purpleLight":
    case "purpleLightBorder":
      return hover ? appTheme.colors.purple[100] : appTheme.colors.purple[50];
    case "transparent":
      return hover ? appTheme.colors.purple[25] : appTheme.colors.transparent;
    default:
      return hover ? appTheme.colors.purple[700] : appTheme.colors.purple[500];
  }
};

const getDisableColor = (color) => {
  switch (color) {
    case "white":
    case "border":
      return appTheme.colors.white;
    case "red":
      return appTheme.colors.red[200];
    case "purpleLight":
      return appTheme.colors.purple[25];
    case "purpleLightBorder":
      return appTheme.colors.white;
    case "transparent":
      return appTheme.colors.transparent;
    default:
      return appTheme.colors.purple[200];
  }
};

const getLabelColor = (color, hover) => {
  switch (color) {
    case "border":
      return hover ? appTheme.colors.gray[800] : appTheme.colors.gray[700];
    case "white":
      return appTheme.colors.purple[900];
    case "purpleLight":
    case "purpleLightBorder":
      return appTheme.colors.purple[500];
    case "transparent":
      return hover ? appTheme.colors.purple[500] : appTheme.colors.gray[500];
    default:
      return appTheme.colors.white;
  }
};

const getLabelColorDisable = (color, disabled) => {
  switch (color) {
    case "border":
    case "white":
      return disabled ? appTheme.colors.gray[300] : appTheme.colors.gray[800];
    case "purpleLightBorder":
      return disabled ? appTheme.colors.gray[300] : appTheme.colors.purple[500];
    case "purpleLight":
      return disabled
        ? appTheme.colors.purple[300]
        : appTheme.colors.purple[500];
    case "transparent":
      return disabled ? appTheme.colors.gray[300] : appTheme.colors.gray[500];
    default:
      return appTheme.colors.white;
  }
};

const getFillColorDisabled = (color, disabled) => {
  switch (color) {
    case "purpleLightBorder":
      return disabled ? appTheme.colors.gray[300] : appTheme.colors.purple[500];
    case "purpleLight":
      return disabled
        ? appTheme.colors.purple[300]
        : appTheme.colors.purple[500];
    case "transparent":
      return disabled ? appTheme.colors.gray[300] : appTheme.colors.gray[500];
    case "border":
    case "white":
      return disabled ? appTheme.colors.gray[300] : appTheme.colors.gray[700];
    default:
      return appTheme.colors.white;
  }
};

const getFillColor = (color, hover) => {
  switch (color) {
    case "purpleLight":
    case "purpleLightBorder":
      return appTheme.colors.purple[500];
    case "transparent":
      return hover ? appTheme.colors.purple[500] : appTheme.colors.gray[500];
    case "border":
    case "white":
      return appTheme.colors.gray[700];
    default:
      return appTheme.colors.white;
  }
};

const getPaddingSize = (size) => {
  switch (size) {
    case "x1":
      return "18px 28px";
    case "lg":
      return "14px 20xp";
    case "md":
      return "12px 18px";
    case "xs":
      return "8px 14px";
    default:
      return "10px 16px";
  }
};

const getFontSize = (size) => {
  switch (size) {
    case "x1":
      return "18px";
    case "lg":
    case "md":
      return "16px";
    default:
      return "14px";
  }
};

const getLineHeight = (size) => {
  switch (size) {
    case "x1":
      return "28px";
    case "lg":
    case "md":
      return "24px";
    default:
      return "20px";
  }
};

const getBorderStyle = (color, disabled) => {
  switch (color) {
    case "white":
      return disabled
        ? `1px solid ${appTheme.colors.gray[200]}`
        : `1px solid ${appTheme.colors.gray[300]}`;
    case "border":
      return disabled
        ? `1px solid ${appTheme.colors.gray[200]}`
        : `1px solid ${appTheme.colors.gray[300]}`;
    case "purpleLightBorder":
      return disabled
        ? `1px solid ${appTheme.colors.gray[200]}`
        : `0 solid ${appTheme.colors.purple[50]}`;
    default:
      return "none";
  }
};

export const ButtonField = styled.button`
  ${({ theme, disabled, color, icon, size }) => css`
    background-color: ${disabled
      ? getDisableColor(color)
      : getButtonColor(color, false)};
    color: ${disabled
      ? getLabelColorDisable(color, disabled)
      : getLabelColor(color, false)};
    cursor: ${disabled ? "no-drop" : "pointer"};
    border: ${getBorderStyle(color, disabled)};
    border-radius: 8px;
    padding: ${getPaddingSize(size)};
    font-size: ${getFontSize(size)};
    line-height: ${getLineHeight(size)};
    font-weight: 500;
    width: 100%;
    ${icon && `display: flex; align-items: center; justify-content: center; gap: 8px;`}

    & svg {
      stroke: ${getFillColorDisabled(color, disabled)};
      flex-shrink: 0;
      transition: 0.3s ease;
    }

    &:hover {
      background-color: ${disabled
      ? getDisableColor(color)
      : getButtonColor(color, true)};
      color: ${disabled
      ? getLabelColorDisable(color, disabled)
      : getLabelColor(color, true)};

      & svg {
        stroke: ${disabled
      ? getFillColorDisabled(color, disabled)
      : getFillColor(color, true)};
      }
    }
  `}
`;
