import React from "react";
//import iconProfile from '../../assets/images/icons/empty-avatar.svg'
//<img src={iconProfile} alt="Refresh Icon" className="btn btn-icon m-0 p-0" />

function maskNumber(number) {
    if (!number) { return; }
    var name = "";
    switch (true) {
      case number.indexOf("+1") === 0:
        name = "+1 (" + number.substring(2, 5) + ") " + number.substring(5, 8) + "-" + number.substring(8);
        break;
      case number.indexOf("+55") === 0:
        name = "+55 (" + number.substring(3, 5) + ") " + number.substring(5, 10) + "-" + number.substring(10);
        break;
      default:
        name = number;
    }

    return name;
}

export default function ContactItemList({ details, onClick }) {
    return (
        <>
            <div className="card p-0">
                <div className="card-body p-3 bg-light rounded-3">
                    <div className="row align-items-center">
                        {details.isgroup ?
                        <>
                            <div className="col-1">
                                <div className="row">
                                    <svg id="Layer_1" viewBox="0 0 24 24" fill="#B7B9BC" height="36" width="36">
                                        <g id="Layer_1_1_">
                                            <circle cx="12" cy="12" r="12" fill="#EDEFF2"></circle>
                                            <path className="st1" d="M14.3 17.8c-.5 0-.9-.4-.9-.9v-1.1c0-.7-.6-1.3-1.3-1.3H7.7c-.7 0-1.3.6-1.3 1.3v1.1c0 .5-.4.9-.9.9s-.9-.4-.9-.9v-1.1c0-1.7 1.4-3.1 3.1-3.1h4.4c1.7 0 3.1 1.4 3.1 3.1v1.1c0 .5-.4.9-.9.9zM9.9 12.3c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1c1.7 0 3.1 1.4 3.1 3.1 0 1.8-1.4 3.1-3.1 3.1zm0-4.3c-.7 0-1.3.5-1.3 1.3s.6 1.3 1.3 1.3c.7 0 1.3-.6 1.3-1.3 0-.8-.6-1.3-1.3-1.3zM18.7 17.8c-.5 0-.9-.4-.9-.9v-1.1c0-.6-.4-1.1-1-1.2a.8.8 0 0 1-.6-1.1c.1-.5.6-.8 1.1-.6 1.4.3 2.3 1.6 2.3 3V17c-.1.4-.5.8-.9.8zM14.8 12.3c-.4 0-.8-.3-.9-.7-.1-.5.2-1 .6-1.1.5-.1.8-.5.9-.9.2-.7-.2-1.4-.9-1.6-.5-.1-.8-.6-.7-1 .1-.5.6-.8 1-.7h.1c1.6.4 2.6 2.1 2.2 3.7-.3 1.1-1.1 1.9-2.2 2.2l-.1.1z">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-11">
                                <div className="row small" style={{ fontSize: 13 }}>
                                    <div className="col-6 d-flex fw-bold">{details?.name}</div>
                                </div>
                                <div className="row small" style={{ fontSize: 13 }}>
                                    <span className="col-11 d-flex">Members: {JSON.parse(details?.contactids).length}</span>
                                    <span className="col-1 d-flex flex-row-reverse"><a onClick={() => onClick( details?.id, details?.name, details?.phone, details?.isgroup )} className="btn stretched-link" /></span>
                                </div>
                            </div>
                        </>
                        : !details?.new ?
                        <>
                            <div className="col-1">
                                <div className="row">
                                    <svg id="Layer_1" viewBox="0 0 24 24" fill="#B7B9BC" height="36" width="36">
                                        <g id="Layer_1_1_">
                                            <circle cx="12" cy="12" r="12" fill="#EDEFF2"></circle>
                                            <path className="st1" d="M17.4 19c-.5 0-.9-.4-.9-.9v-1.4c0-1-.8-1.8-1.8-1.8H9.3c-1 0-1.8.8-1.8 1.8v1.4c0 .5-.4.9-.9.9s-.9-.4-.9-.9v-1.4c0-2 1.6-3.6 3.6-3.6h5.4c2 0 3.6 1.6 3.6 3.6v1.4c0 .4-.4.8-.9.9zM12 12.2c-2 0-3.6-1.6-3.6-3.6S10 5 12 5s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6zm0-5.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8S13 6.8 12 6.8z">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-11">
                                <div className="row small" style={{ fontSize: 13 }}>
                                    <div className="col-6 d-flex fw-bold">{details?.name}</div>
                                </div>
                                <div className="row small" style={{ fontSize: 13 }}>
                                    <span className="col-11 d-flex">Phone: {maskNumber(details?.phone)}</span>
                                    <span className="col-1 d-flex flex-row-reverse"><a onClick={() => onClick( details?.id, details?.name, details?.phone, details?.isgroup )} className="btn stretched-link" /></span>
                                </div>
                                <div className={ details?.company === null ? "d-none" : "row small"} style={{ fontSize: 13 }}>
                                    <span className="col-12 d-flex">{details?.company === null ? "Company: " : "Company: " + details?.company}</span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="col-1">
                                <div className="row">
                                    <svg id="Layer_1" viewBox="0 0 30 30" fill="#EDEFF2" height="36" width="36">
                                        <g id="Layer_1_1_">
                                            <circle cx="14" cy="14" r="14" fill="#EDEFF2"></circle>
                                            <path transform="translate(4,4)"  d="M9.66675 3.33417H3.83341C3.39139 3.33417 2.96746 3.50977 2.6549 3.82233C2.34234 4.13489 2.16675 4.55881 2.16675 5.00084V16.6675C2.16675 17.1095 2.34234 17.5335 2.6549 17.846C2.96746 18.1586 3.39139 18.3342 3.83341 18.3342H15.5001C15.9421 18.3342 16.366 18.1586 16.6786 17.846C16.9912 17.5335 17.1667 17.1095 17.1667 16.6675V10.8342M15.9167 2.08417C16.2483 1.75265 16.6979 1.56641 17.1667 1.56641C17.6356 1.56641 18.0852 1.75265 18.4167 2.08417C18.7483 2.41569 18.9345 2.86533 18.9345 3.33417C18.9345 3.80301 18.7483 4.25265 18.4167 4.58417L10.5001 12.5008L7.16675 13.3342L8.00008 10.0008L15.9167 2.08417Z" stroke="#B7B9BC" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-11">
                                <div className="row small" style={{ fontSize: 13 }}>
                                    <div className="col-6 d-flex fw-bold">{details?.name}</div>
                                </div>
                                <div className="row small" style={{ fontSize: 13 }}>
                                    <span className="col-11 d-flex">Phone: {maskNumber(details?.phone)}</span>
                                    <span className="col-1 d-flex flex-row-reverse"><a onClick={() => onClick( details?.id, details?.name, details?.phone, details?.isgroup )} className="btn stretched-link" /></span>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
}
