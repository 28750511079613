import { useEffect, useState } from "react";
import LoadOutCalendarPicker from "../loadOutCalendarPicker";
import "react-calendar/dist/Calendar.css";
import LoadOutButton from "../loadOutButton";
import LoadOutDropdown from "../loadOutDropdown";
import calendarIcon from "../../../assets/images/icons/calendar.svg";
import arrow from "../../../assets/images/icons/arrow.svg";
import arrowRight from "../../../assets/images/icons/arrow-right.svg";
import {
  WeekDropdownButton,
  WeekDropdownCard,
  WeekDropdownContainer,
  WeekDropdownFooter,
  WeekDropdownHeader,
  YearButton,
  YearsContainer,
} from "./style";
import { useRef } from "react";
import { useOnClickOutside } from "../../../utils/utils";
import { DropdownRegisterButton } from "../../../styles/common";

const WeekDropdown = ({
  placeholder,
  dropdownArray,
  dropdownValue,
  setDropdownValue,
  yearValue,
  rightMobile,
  setYearValue,
  submit,
  type,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [dateLabel, setDateLabel] = useState("");
  const [yearValueAux, setYearValueAux] = useState(
    type === "yearDropdown" ? new Date().getFullYear() : new Date()
  );
  const [dropdownValueAux, setDropdownValueAux] = useState();
  const yearRef = useRef();
  const yearDropdownRef = useRef();

  useOnClickOutside(yearRef, () => setOpenModal(false));
  useOnClickOutside(yearDropdownRef, () => setOpenModal(false));

  const getYearValues = () => {
    const currentDate = new Date().getFullYear();
    let years = [];

    for (let i = currentDate - 8; i <= currentDate; i++) {
      years.push(i);
    }

    return years;
  };

  const setCurrentValue = () => {
    setDropdownValue && setDropdownValue(dropdownValueAux);
    setYearValue(yearValueAux);
  };

  const reset = () => {
    setDropdownValueAux(dropdownValue);
    setYearValueAux(yearValue);
  };

  useEffect(() => {
    reset();
  }, [openModal]);

  useEffect(() => {
    setDropdownValueAux(dropdownValue);
  }, [dropdownValue]);

  useEffect(() => {
    setYearValueAux(yearValue);
  }, [yearValue]);

  return (
    <WeekDropdownContainer>
      {type !== "formYear" ? (
        <WeekDropdownButton
          active={openModal}
          onClick={() => setOpenModal(!openModal)}
        >
          <img src={calendarIcon} alt="Ícone de Calendário" />
          {placeholder ? placeholder : dateLabel}
          <img src={arrow} alt="Seta" />
        </WeekDropdownButton>
      ) : (
        <DropdownRegisterButton
          onClick={() => setOpenModal(!openModal)}
          active={openModal}
        >
          <span>
            <img src={calendarIcon} alt="Icon Calendar" />
            {yearValue ? yearValue.getFullYear() : placeholder}
          </span>
          <img src={arrowRight} alt="Arrow Icon" />
        </DropdownRegisterButton>
      )}
      {type === "calendar" && (
        <LoadOutCalendarPicker
          rightMobile={rightMobile}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setDateLabel={setDateLabel}
          dateValue={yearValue}
          setDateValue={setYearValue}
        />
      )}
      {(type === "year" || type === "formYear") && (
        <WeekDropdownCard ref={yearRef} active={openModal}>
          <WeekDropdownHeader>
            <img
              onClick={() =>
                setYearValueAux((prev) => new Date(String(prev.getFullYear())))
              }
              src={arrowRight}
              alt="Seta"
            />
            <span>{yearValueAux.getFullYear()}</span>
            {yearValueAux.getFullYear() < new Date().getFullYear() ? (
              <img
                onClick={() =>
                  setYearValueAux(
                    (prev) => new Date(String(prev.getFullYear() + 2))
                  )
                }
                src={arrowRight}
                alt="Seta"
              />
            ) : (
              <div></div>
            )}
          </WeekDropdownHeader>
          <YearsContainer>
            {getYearValues().map((item) => (
              <YearButton
                onClick={() => setYearValueAux(new Date(String(item + 1)))}
              >
                {item}
              </YearButton>
            ))}
          </YearsContainer>
          <WeekDropdownFooter>
            <LoadOutButton
              label="Cancel"
              color="purpleLight"
              onClick={() => setOpenModal(false)}
            />
            <LoadOutButton
              label="Apply"
              color="purple"
              onClick={() => {
                setCurrentValue();
                setOpenModal(false);
              }}
            />
          </WeekDropdownFooter>
        </WeekDropdownCard>
      )}
      {type === "yearDropdown" && (
        <WeekDropdownCard ref={yearDropdownRef} active={openModal}>
          <WeekDropdownHeader>
            <img
              onClick={() => setYearValueAux((prev) => prev - 1)}
              src={arrowRight}
              alt="Seta"
            />
            <span>{yearValueAux}</span>
            {yearValueAux < new Date().getFullYear() ? (
              <img
                onClick={() => setYearValueAux((prev) => prev + 1)}
                src={arrowRight}
                alt="Seta"
              />
            ) : (
              <div></div>
            )}
          </WeekDropdownHeader>
          <LoadOutDropdown
            data={dropdownArray}
            value={dropdownValueAux}
            setValue={setDropdownValueAux}
            placeholder="Select the week"
          />
          <WeekDropdownFooter>
            <LoadOutButton
              label="Cancel"
              color="purpleLight"
              onClick={() => setOpenModal(false)}
            />
            <LoadOutButton
              label="Apply"
              color="purple"
              onClick={() => {
                setCurrentValue();
                setOpenModal(false);
              }}
            />
          </WeekDropdownFooter>
        </WeekDropdownCard>
      )}
    </WeekDropdownContainer>
  );
};

export default WeekDropdown;
