import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";

export const ModalContainer = styled.div`
  ${({ mode, open }) => css`
    padding: 14px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: ${appTheme.colors.gray[100]};
    transition: 0.3s ease;
    background-color: ${appTheme.colors.white};

    & .bg-purple {
      background-color: ${appTheme.colors.purple[400]};

      &:hover {
        background-color: ${appTheme.colors.purple[500]};
      }
    }

    
  `}
`;