const regular = `font-weight: 400;`;
const medium = `font-weight: 500;`;
const semiBold = `font-weight: 600;`;
const bold = `font-weight: 700;`;


const x2 = `font-size: 72px; line-height: 90px;`;
const x1 = `font-size: 60px; line-height: 72px;`;
const lg = `font-size: 48px; line-height: 60px;`;
const md = `font-size: 36px; line-height: 44px;`;
const sm = `font-size: 30px; line-height: 38px;`;
const xs = `font-size: 24px; line-height: 32px;`;
const textX1 = `font-size: 20px; line-height: 30px;`;
const textLg = `font-size: 18px; line-height: 28px;`;
const textMd = `font-size: 16px; line-height: 24px;`;
const textSm = `font-size: 14px; line-height: 20px;`;
const textXs = `font-size: 12px; line-height: 18px;`;

export const displayX2Regular = `${x2}${regular}`;
export const displayX2Medium = `${x2}${medium}`;
export const displayX2SemiBold = `${x2}${semiBold}`;
export const displayX2Bold = `${x2}${bold}`;
export const displayX1Regular = `${x1}${regular}`;
export const displayX1Medium = `${x1}${medium}`;
export const displayX1SemiBold = `${x1}${semiBold}`;
export const displayX1Bold = `${x1}${bold}`;
export const displayLgRegular = `${lg}${regular}`;
export const displayLgMedium = `${lg}${medium}`;
export const displayLgSemiBold = `${lg}${semiBold}`;
export const displayLgBold = `${lg}${bold}`;
export const displayMdRegular = `${md}${regular}`;
export const displayMdMedium = `${md}${medium}`;
export const displayMdSemiBold = `${md}${semiBold}`;
export const displayMdBold = `${md}${bold}`;
export const displaySmRegular = `${sm}${regular}`;
export const displaySmMedium = `${sm}${medium}`;
export const displaySmSemiBold = `${sm}${semiBold}`;
export const displaySmBold = `${sm}${bold}`;
export const displayXsRegular = `${xs}${regular}`;
export const displayXsMedium = `${xs}${medium}`;
export const displayXsSemiBold = `${xs}${semiBold}`;
export const displayXsBold = `${xs}${bold}`;

export const textX1Regular = `${textX1}${regular}`;
export const textX1Medium = `${textX1}${medium}`;
export const textX1SemiBold = `${textX1}${semiBold}`;
export const textX1Bold = `${textX1}${bold}`;
export const textLgRegular = `${textLg}${regular}`;
export const textLgMedium = `${textLg}${medium}`;
export const textLgSemiBold = `${textLg}${semiBold}`;
export const textLgBold = `${textLg}${bold}`;
export const textMdRegular = `${textMd}${regular}`;
export const textMdMedium = `${textMd}${medium}`;
export const textMdSemiBold = `${textMd}${semiBold}`;
export const textMdBold = `${textMd}${bold}`;
export const textSmRegular = `${textSm}${regular}`;
export const textSmMedium = `${textSm}${medium}`;
export const textSmSemiBold = `${textSm}${semiBold}`;
export const textSmBold = `${textSm}${bold}`;
export const textXsRegular = `${textXs}${regular}`;
export const textXsMedium = `${textXs}${medium}`;
export const textXsSemiBold = `${textXs}${semiBold}`;
export const textXsBold = `${textXs}${bold}`;