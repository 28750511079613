import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import {
  textMdMedium,
  textSmMedium,
  textSmSemiBold,
} from "../../../styles/typography";

export const WeekDropdownContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    flex-shrink: 0;
  `}
`;

export const WeekDropdownButton = styled.button`
  ${({ theme, active }) => css`
    ${textSmSemiBold}
    padding: 10px 16px;
    color: ${appTheme.colors.gray[500]};
    background-color: ${appTheme.colors.white};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid
      ${active ? appTheme.colors.purple[300] : appTheme.colors.gray[200]};
    transition: 0.3s ease;
    align-items: center;
    border-radius: 8px;
    display: flex;
    gap: 8px;

    &:hover {
      border-color: ${appTheme.colors.purple[300]};
    }

    & img:last-of-type {
      transition: 0.3s ease;
      transform: rotate(${active ? 0 : "180deg"});
    }

    @media (max-width: 610px) {
      justify-content: center;
      width: 100%;
    }
  `}
`;

export const WeekDropdownCard = styled.div`
  ${({ theme, active, rightMobile }) => css`
    pointer-events: ${active ? "all" : "none"};
    top: ${active ? "50px" : "40px"};
    opacity: ${active ? '1' : '0'};
    position: absolute;
    z-index: 50;
    right: 0;
    width: 328px;
    padding: 28px 24px 16px;
    background: ${appTheme.colors.white};
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    transition: 0.3s ease;
    border-radius: 8px;

    @media (max-width: 610px) {
      left: ${rightMobile ? "30%" : "50%"};
      transform: translate(-50%, 0);
    }
  `}
`;

export const WeekDropdownHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & img {
      cursor: pointer;
    }

    & img:first-of-type {
      transform: rotate(180deg);
    }

    & span {
      ${textMdMedium}
      color: ${appTheme.colors.purple[900]};
    }
  `}
`;

export const WeekDropdownFooter = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    padding-top: 16px;
    margin-top: 20px;
    gap: 12px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      height: 1px;
      width: 328px;
      transform: translate(-50%, 0);
      background-color: ${appTheme.colors.gray[200]};
    }
  `}
`;

export const YearsContainer = styled.ul`
  ${({ theme }) => css`
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    max-width: 247px;
    justify-content: space-between;
    gap: 12px;
  `}
`;

export const YearButton = styled.button`
  ${({ theme }) => css`
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: 0.3s ease;

    & span {
      ${textSmMedium}
      color: ${appTheme.colors.purple[900]};
    }

    &:hover {
      border: 1px solid ${appTheme.colors.gray[300]};
    }
  `}
`;
