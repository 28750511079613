import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import { textX1SemiBold, textXsMedium } from "../../../styles/typography";

export const ModalRightModal = styled.div`
  ${({ theme, active }) => css`
    background: rgba(38, 38, 38, 0.95);
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    transition: 0.3s ease;
    opacity: ${active ? '1' : '0'};
    pointer-events: ${active ? "all" : "none"};
  `}
`;

export const ModalRightContainer = styled.div`
  ${({ theme, active, size }) => css`
    position: absolute;
    height: 100%;
    overflow-y: scroll;
    width: ${size === "lg" ? "551px" : "348px"};
    padding: ${size === "lg" ? '0' : "32px 26px 16px 25px"};
    right: ${active ? '0' : "-15px"};
    background-color: ${appTheme.colors.white};
    opacity: ${active ? '1' : '0'};
    transition: 0.3s ease;

    @media (max-width: 734px) {
      width: 100%;
    }
  `}
`;

export const ModalRightHeader = styled.header`
  ${({ theme, size }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${size === "lg" &&
    css`
      padding: 24px;
      border-bottom: 1px solid ${appTheme.colors.gray[200]};
    `}
    margin-bottom: 32px;

    & img {
      cursor: pointer;
      ${size === "lg" &&
    css`
        width: 24px;
        opacity: 0.4;
      `}
    }
  `}
`;

export const ModalRightChildren = styled.div`
  ${({ theme }) => css``}
`;

export const HeaderTexts = styled.div`
  ${({ theme }) => css`
    & h4 {
      ${textX1SemiBold}
      color: ${appTheme.colors.purple[900]};
      margin: 0;
    }

    & p {
      ${textXsMedium}
      color: ${appTheme.colors.gray[400]};
      margin: 8px 0 0;
    }
  `}
`;
