import React from "react";
import { FormattedMessage } from "react-intl";
import uploadFigure from "../../../assets/images/upload-figure.svg";
import LoadOutButton from "../loadOutButton";
import Tooltip from "../tooltip";
import WeekDropdown from "../weekDropdown";
import {
  SubTitleFile,
  TabHeader,
  TabSubTitleSection,
  TabTitles,
  TabTitleSection,
  UploadFileDesc,
  UploadFileFigure,
  UploadFileLabel,
  UploadFileSize,
} from "./style";

export default function DragUploadFile({
  title,
  fileUploaded,
  onFileChange,
  id,
  fileType,
  toolTipTitle,
  toolTipDesc,
  yearValue,
  setYearValue,
  weekDropdownValue,
  setWeekDropdownValue,
  weekDropdown,
  importCsv,
  html
}) {
  const getDisabledButton = () => {
    if (!fileUploaded || (weekDropdown && !weekDropdownValue)) {
      return true;
    }
    return false;
  };
  
  return (
    <>
      <TabHeader>
        <TabTitles>
          <TabTitleSection>
            {title}
            <Tooltip title={toolTipTitle} desc={toolTipDesc} />
          </TabTitleSection>
          { html ? 
            <TabSubTitleSection>
              Template:{" "}
              <SubTitleFile>
                <a href={"downloads/"+html}>{html}</a>
              </SubTitleFile>
            </TabSubTitleSection>
          : "" }
          <TabSubTitleSection>
            <FormattedMessage id="file_name" />:{" "}
            <SubTitleFile>{fileUploaded}</SubTitleFile>
          </TabSubTitleSection>
        </TabTitles>
        {weekDropdown && (
          <WeekDropdown
            placeholder={
              <span>
                {yearValue} - <FormattedMessage id="week" /> {weekDropdownValue}
              </span>
            }
            type="yearDropdownFree"
            dropdownArray={[]}
            dropdownValue={weekDropdownValue}
            setYearValue={setYearValue}
            yearValue={yearValue}
            setDropdownValue={setWeekDropdownValue}
          />
        )}
      </TabHeader>
      <UploadFileLabel
        htmlFor={id}
        onDrop={(e) => {
          onFileChange(e.dataTransfer.files[0]);
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <UploadFileFigure
          src={uploadFigure}
          alt="Figura representativa de upload"
        />
        <UploadFileDesc>
          <FormattedMessage id="drag_a" /> {fileType ? fileType : "CSV"}{" "}
          <FormattedMessage id="file_here" />{" "}
          <strong>
            <FormattedMessage id="or_upload" />
          </strong>
        </UploadFileDesc>
        <UploadFileSize>
          <FormattedMessage id="max_file_size" />: 25MB
        </UploadFileSize>
        <LoadOutButton
          color="border"
          label={
            <>
              <FormattedMessage id="import_a" /> {fileType ? fileType : "CSV"}
            </>
          }
          size="xs"
          onClick={importCsv}
          disabled={getDisabledButton()}
        />
        <input
          type="file"
          name={id}
          className="upload-photo"
          id={id}
          accept={
            fileType === "PDF"
              ? ".pdf"
              : ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          onChange={(e) => onFileChange(e.target.files[0])}
        />
      </UploadFileLabel>
    </>
  );
}
