import appTheme from "../../../styles/themes";
import arrowIcon from "../../../assets/images/icons/arrow-right.svg";
import {
  textMdMedium,
  textSmMedium,
  textSmRegular,
} from "../../../styles/typography";
import { WeekDropdownCard } from "../weekDropdown/style";
import styled, { css } from "styled-components";

const getPosition = (position, active) => {
  switch (position) {
    case "bottom":
      return css`
        top: unset !important;
        bottom: ${active ? "60px" : "40px"};
        transform: unset;
        left: 0;
      `;
    default:
      return "";
  }
};

export const DateDropdownCard = styled(WeekDropdownCard)`
  ${({ theme, position, active }) => css`
    padding: 0;
    overflow: hidden;
    ${getPosition(position, active)}

    & > button {
      position: absolute;
      width: calc(100% - 48px);
      left: 50%;
      top: 72px;
      transform: translate(-50%, 0);
    }

    & .react-calendar {
      padding: 30px 24px 0;
      border: none;

      &__tile {
        &:enabled:focus {
          background-color: ${appTheme.colors.purple[600]};
          border-radius: 100%;

          & abbr {
            color: ${appTheme.colors.white} !important;
          }
        }
      }

      &__tile--active {
        background-color: ${appTheme.colors.purple[600]} !important;
        border-radius: 100%;

        & abbr {
          color: ${appTheme.colors.white} !important;
        }

        &:hover {
          background-color: ${appTheme.colors.purple[50]} !important;

          & abbr {
            color: ${appTheme.colors.purple[700]} !important;
          }
        }
      }

      &__tile--now {
        background-color: ${appTheme.colors.gray[100]};
        border-radius: 100%;

        & abbr {
          color: ${appTheme.colors.purple[900]};
        }

        &:hover {
          & abbr {
            color: ${appTheme.colors.purple[700]} !important;
          }
        }
      }

      &__navigation {
        justify-content: space-between;
        margin-bottom: 84px;
        height: initial;
        display: flex;

        &__prev2-button,
        &__next2-button {
          display: none;
        }

        &__label {
          pointer-events: none;

          & span {
            ${textMdMedium}
            color: ${appTheme.colors.purple[900]};
            text-transform: capitalize;
          }
        }

        &__prev-button,
        &__next-button {
          min-width: initial;
          position: relative;

          &:hover {
            background-color: ${appTheme.colors.white} !important;
          }

          &::after {
            content: url(${arrowIcon});
            background-color: ${appTheme.colors.white};
            transform: translate(-50%, -50%) rotate(180deg);
            position: absolute;
            height: 20px;
            width: 20px;
            left: 50%;
            top: 50%;
          }
        }

        &__next-button {
          &::after {
            transform: translate(-50%, -50%) rotate(0);
          }
        }
      }

      &__month-view {
        &__weekdays {
          display: flex;
          margin-bottom: 24px;
          justify-content: space-between;

          &__weekday {
            flex: initial !important;
            overflow: initial !important;
            width: 40px;
            padding: 0;

            & abbr {
              ${textSmMedium}
              text-transform: capitalize;
              color: ${appTheme.colors.purple[900]};
              text-decoration: none;
            }
          }
        }

        &__days {
          display: flex;
          justify-content: space-between;

          &__day {
            padding: 0;
            flex: initial !important;
            transition: 0.3s ease;
            width: 40px;
            height: 40px;

            & abbr {
              ${textSmRegular}
              color: ${appTheme.colors.purple[900]};
            }

            &--neighboringMonth {
              & abbr {
                color: ${appTheme.colors.gray[500]};
              }
            }

            &:hover {
              border-radius: 100%;
              background-color: ${appTheme.colors.purple[50]};

              & abbr {
                color: ${appTheme.colors.purple[700]};
              }
            }
          }
        }
      }
    }
  `}
`;

export const DateDropdownFooter = styled.footer`
  ${({ theme }) => css`
    gap: 12px;
    display: flex;
    margin-top: 20px;
    padding: 16px 24px;
    border-top: 1px solid ${appTheme.colors.gray[200]};
  `}
`;
