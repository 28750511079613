import styled, { css } from "styled-components/macro";
import appTheme from "../../styles/themes";

export const FormSubActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
    width: 100%;
  `}
`;

export const LogoSubTitle = styled.p`
  ${({ theme }) => css`
    text-align: center;
    margin-bottom: 32px;
    color: ${appTheme.colors.gray[500]};
  `}
`;

export const LogoImg = styled.img`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto 28.5px;
  `}
`;
