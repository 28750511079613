import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import {
  textLgMedium,
  textSmRegular,
  textSmSemiBold,
} from "../../../styles/typography";

export const ModalComposition = styled.div`
  ${({ theme, open }) => css`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(38, 38, 38, 0.95);
    pointer-events: ${open ? "all" : "none"};
    opacity: ${open ? "1" : "0"};
    transition: 0.3s ease;
    z-index: 99999;

    @media (max-width: 750px) {
      padding: 0 16px;
    }
  `}
`;

export const ModalContainer = styled.div`
  ${({ mode, open }) => css`
    position: absolute;
    padding: 24px;
    width: 350px;
    left: 50%;
    text-align: center;
    border-radius: 12px;
    transition: 0.3s ease;
    background-color: ${appTheme.colors.white};
    bottom: ${mode === "ios" ? "30px" : "unset"};
    top: ${mode === "ios" ? "unset" : "50%"};
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    transform: translate(-50%, ${mode === "ios" ? 0 : "-50%"});

    ${mode === "ios" &&
    css`
      &::after {
        width: 44px;
        content: "";
        height: 44px;
        bottom: -22px;
        left: 50%;
        position: absolute;
        background-color: ${appTheme.colors.white};
        transform: rotate(45deg) translate(-50%, 0);
      }
    `}
  `}
`;

export const ModalClose = styled.button`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `}
`;

export const ModalIcon = styled.img`
  ${({ theme }) => css`
    margin: 0 auto 20px;
  `}
`;

export const ModalTitle = styled.h2`
  ${({ theme }) => css`
    ${textLgMedium}
    margin-bottom: 8px;
  `}
`;

export const ModalDesc = styled.p`
  ${({ mode, type }) => css`
    ${textSmRegular}
    margin-bottom: 32px;
    color: ${appTheme.colors.gray[500]};
    ${mode === "ios" &&
    css`
      line-height: 34px;
    `}

    & img {
      margin-top: -5px;
      display: inline-block;
    }

    & strong,
    & span {
      ${textSmSemiBold}
    }

    ${type === "pwa" &&
    css`
      & span {
        color: ${appTheme.colors.purple[500]};
        text-decoration: underline;
      }
    `}
  `}
`;

export const AlertButtons = styled.div`
  ${({ theme }) => css`
    gap: 12px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  `}
`;
