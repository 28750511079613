import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import LoadOutInput from "../../components/fragments/loadOutInput";
import ModalPattern from "../../components/fragments/modalPattern";
import { RegisterFormOneColumn } from "../../styles/common";
import FeedBackModal from "../../components/fragments/feedBackModal";
import { useRef } from "react";
import { checkTokenValidity, useOnClickOutside } from "../../utils/utils";
import { useAuth } from '../../context/AuthProvider/useAuth'
import TablePagination from "../../components/fragments/tablePagination";
import LoadOutButton from "../../components/fragments/loadOutButton";
import Loading from "../../components/fragments/Loading";
import {
  GroupsTable,
  QuantityBall
} from "./style";

export default function AddEdit({
  openModal,
  setOpenModal,
  refreshData,
  id,
  setId,
  object
}) {
  const auth = useAuth();
  const [groupObject, setGroupObject] = useState(object);
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  //pagination props
  const [contacts, setContacts] = useState([]);
  const [contactIds, setContactIds] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState([]);
  const [selected, setSelected] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const offset = selected * perPage - perPage;

  //ordering props
  const [lastField, setLastField] = useState("name");
  const [lastOrder, setLastOrder] = useState(false);

  const statusRef = useRef();
  useOnClickOutside(statusRef, () => setStatusDropdown(false));

  const resetForm = () => {
    setId(0);
    setGroupObject({});
    setOpenModal(false);
    setContactIds([]);
    setContacts([]);
    setSelected(1);
  };

  const fetchContactIdsData = async () => {
    setLoading(true);

    try {
      const { data } = await auth.getAllContactsByLineId();
      const newList = data.ret.filter((x) => JSON.parse(object.contactids || "[]").includes(x.id));
      setContacts(data.ret);
      setContactIds(newList);
      setPageCount(Math.ceil(newList.length / perPage));
    } catch (error) {
      //checkTokenValidity(response.data)
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function onSubmit() {
    try {
      if (id) {
        const { data } = await auth.putGroupUpdateById(id, { ...groupObject, contactids: contactIds.map((item) => { return item.id }) })
        if (data.res) {
          setModalObject({
            type: "success",
            buttonLabel: "Ok",
            open: true,
            title: "Success",
            desc: "This group has been updated.",
            action: () => resetForm(),
          });
          refreshData();
        }
        return;
      }
      const { data } = await auth.postGroupCreate({ ...groupObject, contactids: contactIds.map((item) => { return item.id }) });
      if (data.res) {
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Success",
          desc: "This group has been added.",
          action: () => resetForm(),
        });
        refreshData();
      }
    } catch ({ response }) {
      checkTokenValidity(response.data)
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response?.data.message,
      });
    }
  }

  useEffect(() => {
    if (id && object.id) {
      setGroupObject(object);
      fetchContactIdsData();
      //setContactIds(contacts.filter(({id}) => JSON.parse(object.contactids).includes(id)));
      return
    }
    return () => {
      setGroupObject({});
      setContactIds([]);
      setContacts([]);
      setSelected(1);
    }
  }, [id, object]);

  useEffect(() => {
    if (openModal) {
      fetchContactIdsData();
    } else {
      resetForm();
    }
  }, [openModal]);

  useEffect(() => {
    datatable();
  }, [search, selected, lastField, lastOrder, contacts, contactIds]);
  
  function datatable() {
    const list = [ ...search.length ? contacts : contactIds ];

    //1 - Status
    //const listStatus = getStatusFilter(list);

    //2 - Search
    var listSearch = list;
    if (search.length) {
      listSearch = list.filter((x) =>
        String(x.name).toLowerCase().includes(search.toLowerCase()) ||
        String(x.phone).toLowerCase().includes(search.toLowerCase())
      );
    }

    //3 - Sort
    listSearch.sort((a, b) => {
      if (String(a[lastField]).toLowerCase() < String(b[lastField]).toLowerCase()) {
        return lastOrder ? 1 : -1;
      } else if (String(a[lastField]).toLowerCase() > String(b[lastField]).toLowerCase()) {
        return lastOrder ? -1 : 1;
      } else {
        return 0;
      }
    });

    //4 - Paging
    const ListSlice = listSearch.slice(selected === 1 ? 0 : offset, selected * perPage);

    const newCount = listSearch.length;
    //console.log("count:", newCount);
    setCount(newCount);
    setFilter(ListSlice);
    setPageCount(Math.ceil(newCount / perPage));
  }

  function reorder(field) {
    if (field === lastField) {
      setLastOrder(!lastOrder);
    } else {
      setLastOrder(false);
      setLastField(field);
    }
  }

  function onChangeSearch(e) {
    setSearch(e.target.value);
    setSelected(1);
    setLastField("name");
    setLastOrder(false);
  }

  return (
    <>
      <Loading visible={loading} />
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <ModalPattern
        open={openModal}
        setOpen={setOpenModal}
        title={id ? 'Edit Group' : 'Create Group'}
        saveAction={onSubmit}
        cancelAction={resetForm}
      >
        <RegisterFormOneColumn width={'650px'}>
          <LoadOutInput
            highlight={<FormattedMessage id="form.name" />}
            onChange={({ target }) => {
              setGroupObject({ ...groupObject, name: target.value })
            }}
            value={groupObject?.name}
          />
          <LoadOutInput
            value={search}
            type="find"
            setSearch={setSearch}
            onChange={(e) => onChangeSearch(e) }
            highlight={<FormattedMessage id="form.search" />}
          />
          <>
            <GroupsTable>
              <thead>
                <tr>
                  {['name', 'phone'].map((field, index) => {
                    return (
                      <th key={index}>
                        <span>
                          <FormattedMessage id={`tabela.${field}`} />
                        </span>
                      </th>
                    )
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filter?.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span>{d.name}</span>
                      </td>
                      <td>
                        <span>{d.phone}</span>
                      </td>
                      <td>
                        {contactIds.find(c => c.id === d.id) ?
                          <LoadOutButton
                            className='fw-bolder'
                            style={{ cursor: 'pointer' }}
                            onClick={() => { 
                              setContactIds(contactIds.filter(c => c.id !== d.id));
                            }}
                            color='red'
                            size="xs"
                            icon="trash"
                          />
                          :
                          <LoadOutButton
                            className='fw-bolder'
                            style={{ cursor: 'pointer' }}
                            onClick={() => { 
                              setContactIds([...contactIds, d]);
                              //setSearch("");
                            }}
                            size="xs"
                            icon="add"
                          />
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </GroupsTable>
            <TablePagination
              currentPage={selected}
              totalPages={pageCount || 1}
              nextAction={() => setSelected((prev) => prev + 1)}
              previousAction={() => setSelected((prev) => prev - 1)}
            />
          </>
        </RegisterFormOneColumn>
      </ModalPattern>
    </>
  );
}
