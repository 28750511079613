import styled, { css } from "styled-components/macro";

export const FormContainer = styled.form`
  ${({ theme }) => css`
    margin: auto;
    width: 640px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 658px) {
      width: 100%;
    }
  `}
`;

export const GridContainer = styled.div`
  ${({ theme }) => css`
    gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  `}
`;
