import React, { useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import LoadOutDropdown from "../../components/fragments/loadOutDropdown";
import Breadcrumb from "../../components/fragments/breadcrumb";
import ContactImport from "./contact/contactImport";
//import ContactConfirm from "./contactConfirm";
import { FormattedMessage } from "react-intl";
import {
  ImportsBG,
  ImportsContainer,
  ImportsSubTitle,
  ImportsTabBody,
  ImportsTabBodyContainer,
  ImportsTabHeaderContainer,
  ImportsTabsContainer,
  ImportsTitle,
} from "./style";
import { ImportsTabHeader } from "../../styles/common";

export default function Import() {
  var pathName = useLocation().pathname;
  const history = useHistory();
  const [contact, setContact] = useState();
  const [fileNameImport, setFileNameImport] = useState();
  const [tabActive, setTabActive] = useState(0);
  const dropdownData = ["Bulk"];
  const [dropdownValue, setDropdownValue] = useState(dropdownData[tabActive]);

  const handleChangeDropdown = (value) => {
    setTabActive(
      dropdownData.findIndex((item) => {
        return item === value;
      })
    );
    setDropdownValue(value);
  };

  return (
    <Switch>
      <ImportsBG>
        <ImportsContainer style={{ paddingTop: window.innerWidth > 768 ? 50 : 0 }}>
          <Breadcrumb paths={[{ to: "#", path: "Data Import" }]} />

          {!pathName.includes("/import/contact/confirm") && (
            <>
              <ImportsTitle>Data Import</ImportsTitle>
              <ImportsSubTitle>
                Drag or select your files here
              </ImportsSubTitle>

              <ImportsTabsContainer>
                <LoadOutDropdown
                  data={dropdownData}
                  setValue={handleChangeDropdown}
                  value={dropdownValue}
                />
                <ImportsTabHeaderContainer>
                  <ImportsTabHeader
                    onClick={() => setTabActive(0)}
                    active={tabActive === 0}
                  >
                    <FormattedMessage id="bulky" />
                  </ImportsTabHeader>
                </ImportsTabHeaderContainer>
                <ImportsTabBodyContainer>
                  <ImportsTabBody active={tabActive === 0}>
                    <ContactImport />
                  </ImportsTabBody>
                </ImportsTabBodyContainer>
              </ImportsTabsContainer>
            </>
          )}
        </ImportsContainer>
      </ImportsBG>
    </Switch>
  );
}
