import { useEffect, useState, useRef } from "react";
import logoCube from "../../../assets/images/icons/load-out-cube.svg";
import iconHamburguer from "../../../assets/images/icons/hamburguer.svg";
import { useHistory, useLocation } from "react-router-dom";
import {
  HamButton,
  HeaderContainer,
  LogoCube,
  MobileButtons,
  UserActions,
} from "./style";
import User from "../userNav";
import { DriverItemItem, DriverItemModal } from "../../../styles/common";
import { hardReload, useOnClickOutside } from "../../../utils/utils";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { ViewAllContainerMobile, ViewAllButton } from "./style";
import { useRecoilState } from "recoil";
import { LineState, LineListState, TenantState } from "../../../state/states";

export default function Header({ toggleSideBar, setToggleSideBar }) {
  const auth = useAuth();
  const history = useHistory();
  const [viewAllModal, setViewAllModal] = useState(false);
  const [lineData, setLineData] = useRecoilState(LineState);
  const [lineList, setLineList] = useRecoilState(LineListState);
  const [tenantData, setTenantData] = useRecoilState(TenantState);
  //const [ selectedLine, setSelectedLine ] = useState(lineData.id);

  useEffect(() => {
    document.title = "LoadOut";

    if (!noMenuPages.includes(pathName.split("/")[1])) {
    }
  }, []);

  const noMenuPages = ["", "login", "forgot_password", "define"];

  var pathName = useLocation().pathname;

  const viewRef = useRef();
  useOnClickOutside(viewRef, () => setViewAllModal(false));

  if (noMenuPages.includes(pathName.split("/")[1])) {
    return null;
  }

  return (
    <HeaderContainer>
      {auth.tenantContext?.id && (
        <span>
          {`${tenantData?.company} : ${tenantData?.city} : ${tenantData?.state}`}
        </span>
      )}

      <LogoCube src={logoCube} alt="Logo LoadOut" />
      <MobileButtons>
        {/* <Notifications newNotifications /> */}
        <HamButton onClick={() => setToggleSideBar(!toggleSideBar)}>
          {!toggleSideBar && (
            <img src={iconHamburguer} alt="Ícone Hamburguer" />
          )}
        </HamButton>
      </MobileButtons>
      {auth.linesContext?.length > 0 && (
        <ViewAllContainerMobile>
          <ViewAllButton
            ref={viewRef}
            onClick={() => setViewAllModal(!viewAllModal)}
          >
            {
              <span>
                {`${auth.activeLine()?.name} • ${auth.activeLine()?.number}`}
              </span>
            }
          </ViewAllButton>
          <DriverItemModal
            style={{ width: "250px" }}
            active={viewAllModal /*? "viewAllModal" : undefined*/}
          >
            {auth.linesContext?.map((line) => {
              return (
                <DriverItemItem
                  key={line.id}
                  active={lineData.id === line.id ? "true" : undefined}
                  onTouchEnd={() => {
                    auth.changeLine(line.id);
                    //setSelectedLine(line.id);
                    setViewAllModal(false);
                    //history.push("/dashboard?changed_line=true");
                  }}
                  onClick={() => {
                    if (lineData.id === line.id) return;
                    auth.changeLine(line.id);
                    setViewAllModal(false);
                  }}
                >
                  <span>{`${line.name} • ${line.number}`}</span>
                </DriverItemItem>
              );
            })}
          </DriverItemModal>
        </ViewAllContainerMobile>
      )}
      <UserActions>
        <User mode="header" />
      </UserActions>
    </HeaderContainer>
  );
}
