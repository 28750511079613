import {
  ModalChildren,
  ModalComposition,
  ModalClose,
  ModalContainer,
  ModalHeader,
  ModalHeaderText,
  ModalFooter,
} from "./style";
import { useEffect, useRef, useState } from "react";
import LoadOutButton from "../loadOutButton";
import { useOnClickOutside } from "../../../utils/utils";

const ModalPattern = ({
  open,
  setOpen,
  title,
  children,
  saveAction,
  cancelAction,
  type,
}) => {
  const [openModal, setOpenModal] = useState(false);
  //const ref = useRef();

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const closeModal = () => {
    setOpen(false);
    setOpenModal(false);
    cancelAction && cancelAction();
  };

  const submitModal = () => {
    saveAction();
  };

  //useOnClickOutside(ref, closeModal);

  return (
    <ModalComposition type={type} open={openModal}>
      <ModalContainer type={type} open={openModal}>
        <ModalHeader>
          <ModalHeaderText>{title}</ModalHeaderText>
          <ModalClose onClick={closeModal}>
            <svg
              width="14"
              height="15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m13 1.355-12 12m0-12 12 12"
                stroke="#101828"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ModalClose>
        </ModalHeader>
        <ModalChildren>{children}</ModalChildren>
        <ModalFooter>
          <LoadOutButton
            size="xs"
            color="purpleLight"
            onClick={closeModal}
            label="Cancel"
          />
          <LoadOutButton
            size="xs"
            onClick={submitModal}
            color="purple"
            label="Save"
          />
        </ModalFooter>
      </ModalContainer>
    </ModalComposition>
  );
};

export default ModalPattern;
