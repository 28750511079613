import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";

export const LoginGeneralContainer = styled.main`
  ${({ theme, active }) => css`
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
  `}
`;

export const IllustrationRight = styled.img`
  ${({ theme }) => css`
    width: 100%;

    @media (max-width: 1700px) {
      height: 100%;
      min-width: 907px;
    }
  `}
`;

export const IllustrationRightContainer = styled.div`
  ${({ theme }) => css`
    width: 50%;
    height: 100vh;
    overflow: hidden;

    @media (max-width: 900px) {
      display: none;
    }
  `}
`;

export const FormContainer = styled.div`
  ${({ theme }) => css`
    max-width: 360px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
      max-width: initial;
      width: 100%;
      padding: 0 16px;
    }    
  `}
`;

export const GeneralChildren = styled.div`
  ${({ theme }) => css`
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
    }
  `}
`;

export const Copyright = styled.p`
  ${({ theme }) => css`
    color: ${appTheme.colors.gray[500]};
    position: absolute;
    left: 32px;
    bottom: 32px;
    font-size: 14px;

    @media (max-width: 900px) {
      display: none;
    }
  `}
`;
