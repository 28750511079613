import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { textLgMedium } from "../../../styles/typography";

export const ModalComposition = styled.div`
  ${({ type, open }) => css`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(38, 38, 38, 0.95);
    pointer-events: ${open ? "all" : "none"};
    opacity: ${open ? "1" : "0"};
    justify-content: center;
    transition: 0.3s ease;
    align-items: center;
    z-index: 9999;
    display: flex;

    @media (max-width: 658px) {
      padding: 15px;
    }

    ${type === "driver" &&
    css`
      @media (max-width: 1030px) {
        padding: 20px;
      }
    `}
  `}
`;

export const ModalContainer = styled.div`
  ${({ theme, open, type }) => css`
    position: relative;
    text-align: center;
    border-radius: 12px;
    transition: 0.3s ease;
    top: ${open ? "0" : "20px"};
    background-color: ${appTheme.colors.white};
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);

    ${type === "driver" &&
    css`
      @media (max-width: 1030px) {
        width: 100%;
      }
    `}

    @media (max-width: 1268px) {
      max-height: 100%;
      overflow-y: scroll;
      width: 100%;
    }
  `}
`;

export const ModalHeader = styled.header`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px 20px 22px 20px;
    border-bottom: 1px solid ${appTheme.colors.gray[200]};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const ModalHeaderText = styled.h2`
  ${({ theme }) => css`
    ${textLgMedium}
    color: ${appTheme.colors.gray[900]};
    margin: 0;
  `}
`;

export const ModalClose = styled.button`
  ${({ theme }) => css``}
`;

export const ModalFooter = styled.footer`
  ${({ theme }) => css`
    justify-content: flex-end;
    padding: 16px 24px;
    display: flex;
    gap: 12px;
    border-top: 1px solid ${appTheme.colors.gray[200]};

    & button {
      width: fit-content;
    }
  `}
`;

export const ModalChildren = styled.div`
  ${({ theme, open }) => css``}
`;
