import React, { useEffect, useState } from "react";
import ModalPattern from "../../../components/fragments/modalPattern";
import InputPhoneMask from "../../../components/fragments/inputPhoneMask";
import Swal from "sweetalert2";
import iconTic from "../../../assets/images/icons/tic.svg";
import { FormattedMessage } from "react-intl";
import LoadOutInput from "../../../components/fragments/loadOutInput";
import { FormContainer, GridContainer } from "./style";
import {
  DriverItemItem,
  DriverItemList,
  DriverItemModal,
  FieldContainer,
} from "../../../styles/common";
import LoadOutDropdownButton from "../../../components/fragments/loadOutDropdownButton";
import { useRef } from "react";
import FeedBackModal from "../../../components/fragments/feedBackModal";
import Loading from "../../../components/fragments/Loading";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import {
  checkTokenValidity,
  fireAlert,
  fireSuccess,
} from "../../../utils/utils";
import { useHistory } from "react-router-dom";

export default function CompanySettingsModal({
  open,
  setOpen,
  getall,
  getDetails,
}) {
  const auth = useAuth();
  const [stateDropdown, setStateDropdown] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneMask, setPhoneMask] = useState("");
  const [company, setCompany] = useState("");
  const [billing, setBilling] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState("");
  const [zip, setZip] = useState("");
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });
  const typeRef = useRef();
  const history = useHistory();

  async function onSubmit() {
    setLoading(true);
    const getOnlyPhoneNumbers = phone.replace(/[^0-9]+/g, "");

    if (isNaN(getOnlyPhoneNumbers)) {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops",
        desc: "Check your phone number!",
        action: () => setOpen(false),
      });
      return;
    }

    try {
      const payload = {
        company,
        zip,
        state,
        city,
        address1,
        address2,
        email: billing,
        phone: getOnlyPhoneNumbers,
      };
      const { data } = await auth.putTenantUpdate(payload);
      if (data.res) {
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Success",
          desc: "Infos updated!",
          action: () => setOpen(false),
        });
      }
    } catch ({ response }) {
      checkTokenValidity(response.data);
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setCompany(auth.tenantContext.company);
    setZip(auth.tenantContext.zip);
    setState(auth.tenantContext.state);
    setBilling(auth.tenantContext.email);
    setCity(auth.tenantContext.city);
    setAddress1(auth.tenantContext.address1);
    setAddress2(auth.tenantContext.address2);
    if (!open && window.location.pathname.includes("/company-settings")) {
      history.goBack();
    }
  }, [open]);

  useEffect(() => {
    const currentLang = localStorage.getItem("language");
    const lang = getall?.find((x) => x.code === currentLang)?.phonemask;
    setPhoneMask(lang);
  }, [open, getall]);

  return (
    <ModalPattern
      title={<FormattedMessage id="company_settings" />}
      open={open}
      setOpen={setOpen}
      saveAction={onSubmit}
    >
      <Loading visible={loading} />
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <FormContainer>
        <GridContainer>
          <LoadOutInput
            highlight={<FormattedMessage id="company_name" />}
            value={company}
            onChange={({ target }) => setCompany(target.value)}
            type="text"
          />
          <InputPhoneMask
            highlight={<FormattedMessage id="phone" />}
            value={phoneMask || ""}
            onChange={({ target }) => setPhone(target.value)}
          />
        </GridContainer>
        <LoadOutInput
          highlight={<FormattedMessage id="billing_email" />}
          value={billing}
          onChange={({ target }) => setBilling(target.value)}
          type="email"
        />
        <LoadOutInput
          highlight={<FormattedMessage id="address1" />}
          value={address1}
          onChange={({ target }) => setAddress1(target.value)}
          type="address"
        />
        <GridContainer>
          <LoadOutInput
            highlight={<FormattedMessage id="address2" />}
            value={address2}
            onChange={({ target }) => setAddress2(target.value)}
            type="address"
          />
          <LoadOutInput
            highlight={<FormattedMessage id="city" />}
            value={city}
            onChange={({ target }) => setCity(target.value)}
            type="text"
          />
          <LoadOutInput
            highlight={<FormattedMessage id="form.state" />}
            value={state}
            onChange={({ target }) => setState(target.value)}
            type="text"
          />
          <LoadOutInput
            highlight={<FormattedMessage id="zip" />}
            value={zip}
            onChange={({ target }) => setZip(target.value)}
            type="text"
          />
        </GridContainer>
      </FormContainer>
    </ModalPattern>
  );
}
