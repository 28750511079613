import List from "./list";
import { CompanyCityState, FormRosterContainer } from "../../styles/common";
import { FormUsersContainer } from "./style";
import { useAuth } from '../../context/AuthProvider/useAuth'

export default function Users() {
  const auth = useAuth()
  return (
    <FormRosterContainer>
      <FormUsersContainer style={{ paddingTop: window.innerWidth > 768 ? 50 : 0 }}>
        <CompanyCityState>
          {auth.tenantContext.company} - {auth.tenantContext.city} - {auth.tenantContext.state}
        </CompanyCityState>
        <List />
      </FormUsersContainer>
    </FormRosterContainer>
  );
}
