import React from "react";
import Routes from "./routes";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import IntlProviderConfigured from "./_i18n/IntlProviderConfigured";
import { AuthProvider } from "./context/AuthProvider";
import './styles/global.css'

function MainApp(props) {
  return (

    <IntlProviderConfigured>
      <AuthProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Routes />
        </MuiPickersUtilsProvider>
      </AuthProvider>
    </IntlProviderConfigured>

  );
}

export default MainApp;
