import axios from "axios";
import jwt_decode from "jwt-decode";

const api = axios.create({
  baseURL: "https://chat-api.dsploadout.com/v1/",
  headers: {
    'x-access-token': getLocalStoragedToken(),
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
  }
});


function saveLocalStorageToken(token) {
  localStorage.setItem('token', token); //token_jwt_loadout
}

function getLocalStoragedToken() {
  return localStorage.getItem('token') ?? null;
}

function saveLocalStorageTenantData(tenantData) {
  localStorage.setItem('tenant_data_loadout', JSON.stringify(tenantData))
}

function saveLocalStorageUserData(tenantData) {
  localStorage.setItem('token_user_loadout', JSON.stringify(tenantData))
}

function getLocalStorageUserData() {
  //return JSON.parse(localStorage.getItem('token_user_loadout') || '{}') ?? null
  const token = getLocalStoragedToken();

  if (!token) {
    return {};
  }

  return jwt_decode(token);
}

function getLocalStoragedTenantData() {
  return JSON.parse(localStorage.getItem('tenant_data_loadout') || '{}') ?? null
}

function saveLocalStorageLinesData(linesData) {
  localStorage.setItem('lines_data_loadout', JSON.stringify(linesData))
}

function getLocalStoragedLinesData() {
  return JSON.parse(localStorage.getItem('lines_data_loadout') || '[]') ?? null
}

function saveLocalStorageSelectedLineId(lineId) {
  return localStorage.setItem('lineid_selected_loadout', JSON.stringify(lineId))
}

function getLocalStoragedSelectedLineId() {
  return JSON.parse(localStorage.getItem('lineid_selected_loadout') || '{}') ?? null
}

export {
  saveLocalStorageToken,
  saveLocalStorageTenantData,
  saveLocalStorageLinesData,
  saveLocalStorageSelectedLineId,
  saveLocalStorageUserData,
  getLocalStoragedToken,
  getLocalStoragedTenantData,
  getLocalStoragedLinesData,
  getLocalStoragedSelectedLineId,
  getLocalStorageUserData
};
export default api;
