import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { textSmRegular } from "../../../styles/typography";
import { GroupField as GroupFieldOri } from "../loadOutInput/style";

export const GroupField = styled(GroupFieldOri)`
  ${({ theme, width }) => css`
    padding: 17px 34px;
    width: ${width ? width : "100%"};
    display: flex;
    align-items: center;

    &:focus-within {
      padding: 17px 34px;
    }

    & input {
        padding-left: 22px;
    }
  `}
`;

export const DropdownPhone = styled.div`
  ${({ theme, width }) => css`
    cursor: pointer;
  `}
`;

export const DropdownSelected = styled.div`
  ${({ theme, width }) => css`
    display: flex;
    gap: 9px;

    & img {
      transform: rotate(90deg);
    }

    & p {
        ${textSmRegular}
        color: ${appTheme.colors.gray[900]};
        margin: 0;
    }
  `}
`;
