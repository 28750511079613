import { useState } from "react";
import {
  DriverItemItem,
  DriverItemList,
  DriverItemModal,
} from "../../../styles/common";
import iconTic from "../../../assets/images/icons/tic.svg";
import iconArrow from "../../../assets/images/icons/arrow-right.svg";
import { CountryButton, InputPhoneField, PhoneGroupField, EyeButton } from "./style";
import { SuportLabel } from "../loadOutInput/style";
import { useRef } from "react";
import { useOnClickOutside } from "../../../utils/utils";
import flagBR from "../../../assets/images/icons/flags/pt.svg";
import flagUS from "../../../assets/images/icons/flags/en.svg";
import cross from "../../../assets/images/icons/cross3.svg";

const LoadOutPhoneInput = ({
  value,
  groupStyle,
  required,
  disabled,
  invalid,
  onChange,
  suport,
  close,
  showClose,
  setCode
}) => {
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [country, setCountry] = useState({
    id: 1,
    flag: flagUS,
    name: "United States (+1)",
    code: "+1",
    placeholder: "(999) 999-9999",
  });
  const [countrys] = useState([
    {
      id: 1,
      flag: flagUS,
      name: "United States (+1)",
      code: "+1",
      placeholder: "(999) 999-9999",
    },
    { 
      id: 55,
      flag: flagBR,
      name: "Brazil (+55)", 
      code: "+55", 
      placeholder: "(99) 99999-9999" 
    }
  ]);
  const ref = useRef();
  useOnClickOutside(ref, () => setCountryDropdown(false));

  function clearNumber(number) {
    switch(country.id) {
      case 55:
        number = number.replace(/[^\d]/g, "").substring(0, 11);
        break;
      case 1:
      default:
        number = number.replace(/[^\d]/g, "").substring(0, 10);
    }
    return number;
  }

  function maskNumber(number) {
    if (number.length === 0) {
      return "";
    }

    var ret = number;
    var regexObj = / /;
    switch(country.id) {
      case 55:
        number = clearNumber(number);
        ret = number;
        regexObj = /(\d{0,2})?(\d{0,5})?(\d{0,4})?/;
        if (regexObj.test(number)) {
          ret = number.replace(regexObj, "($1) $2-$3");
          var sLength = 0;
          switch (true) {
            case number.length < 1:
              break;
            case number.length < 3:
              sLength = number.length + 1;
              break;
            case number.length < 8:
              sLength = number.length + 3;
              break;
            default:
              sLength = number.length + 4;
          }
    
          ret = ret.substring(0, sLength);
        }
        break;
      case 1:
      default:
        number = clearNumber(number);
        ret = number;
        regexObj = /(\d{0,3})?(\d{0,3})?(\d{0,4})?/;
        if (regexObj.test(number)) {
          ret = number.replace(regexObj, "($1) $2-$3");
          var sLength = 0;
          switch (true) {
            case number.length < 1:
              break;
            case number.length < 4:
              sLength = number.length + 1;
              break;
            case number.length < 7:
              sLength = number.length + 3;
              break;
            default:
              sLength = number.length + 4;
          }
    
          ret = ret.substring(0, sLength);
        }
    }
    
    return ret;
  }

  return (
    <>
      <PhoneGroupField type="phone" style={groupStyle}>
        <span>To:</span>
        <CountryButton ref={ref}
          className="mt-0 me-3"
          onClick={() => setCountryDropdown(!countryDropdown)}
          active={countryDropdown}
        >
          {//<span className="text-nowrap text-secondary">{country.code}</span>
          }
          <img src={country.flag} alt="Flag Icon" />
          <img className="mt-1" src={iconArrow} alt="Arrow Icon" />
        </CountryButton>
        <InputPhoneField
          className="mt-0"
          onChange={ (e) => onChange(clearNumber(e.target.value)) }
          value={ maskNumber(value) }
          placeholder={country.placeholder}
          type="text"
          disabled={disabled}
        />
        <DriverItemModal active={countryDropdown}>
          <DriverItemList>
            {countrys.map((data, i) => (
              <DriverItemItem
                key={i}
                active={country.name === data.name}
                onClick={() => {
                  setCountry(data);
                  setCode(data.code);
                  onChange("");
                  setCountryDropdown(!countryDropdown);
                }}
              >
                <span>{data.name}</span>
                <img src={iconTic} alt="Icon Tic" />
              </DriverItemItem>
            ))}
          </DriverItemList>
        </DriverItemModal>
        {showClose && (
          <EyeButton
            onClick={() => close()}
            src={cross}
          />
        )}
      </PhoneGroupField>
      {suport && <SuportLabel>{suport}</SuportLabel>}
    </>
  );
};

export default LoadOutPhoneInput;
