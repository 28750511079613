import { GoBackLink } from "./style";

const GoBackButton = ({ to, label, style }) => {
  return (
    <GoBackLink style={style} to={to}>
      <svg
        width="14"
        height="14"
        stroke="#667085"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.833 7H1.167m0 0L7 12.833M1.167 7 7 1.167"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {label}
    </GoBackLink>
  );
};

export default GoBackButton;
