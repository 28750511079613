import { useState } from "react";
import eyeOpen from "../../../assets/images/icons/eye-on.svg";
import eyeClosed from "../../../assets/images/icons/eye-off.svg";
import homeIcon from "../../../assets/images/icons/home.svg";
import mailIcon from "../../../assets/images/icons/mail.svg";
import cardIcon from "../../../assets/images/icons/icon-card.svg";
import searchIcon from "../../../assets/images/icons/icon-search2.svg";
import cross from "../../../assets/images/icons/cross3.svg";
import {
  InputField,
  InputText,
  GroupField,
  InputLabel,
  EyeButton,
  SuportLabel,
  InputTypeIcon,
} from "./style";

const LoadOutInput = ({
  value,
  type,
  groupStyle,
  highlight,
  placeholder,
  width,
  suport,
  required,
  disabled,
  invalid,
  onChange,
  size,
  multiline,
  id,
  setSearch,
}) => {
  const [pwdVisible, setPwdVisible] = useState(false);

  const typeIcon = {
    address: homeIcon,
    email: mailIcon,
    find: searchIcon,
    card: cardIcon,
  };

  return (
    <>
      <GroupField
        style={groupStyle}
        width={width}
        active={value !== "" && value !== null}
        type={type}
        className="groupField"
      >
        {(type === "email" ||
          type === "address" ||
          type === "find" ||
          type === "card") && (
          <InputTypeIcon src={typeIcon[`${type}`]} alt="Type Icon" />
        )}
        { multiline ?
          <InputText
            id={id}
            onChange={onChange}
            value={value || ""}
            type={type === "password" && pwdVisible ? "text" : type}
            placeholder={placeholder}
            disabled={disabled}
            rows={2}
          />
        :
          <InputField
            id={id}
            onChange={onChange}
            value={value || ""}
            type={type === "password" && pwdVisible ? "text" : type}
            placeholder={placeholder}
            disabled={disabled}
          />
        }
        {highlight && (
          <InputLabel type={type} active={value !== "" && value !== null}>
            {highlight}
          </InputLabel>
        )}
        {type === "password" && (
          <EyeButton
            onClick={() => setPwdVisible(!pwdVisible)}
            src={pwdVisible ? eyeOpen : eyeClosed}
          />
        )}
        {type === "find" && (
          <EyeButton
            onClick={() => setSearch("")}
            src={cross}
          />
        )}
      </GroupField>
      {suport && <SuportLabel>{suport}</SuportLabel>}
    </>
  );
};

export default LoadOutInput;
