import { atom } from 'recoil';

const TenantState = atom({
    key: 'tenantState',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(localStorage.getItem('tenant'))
});

const LineState = atom({
    key: 'lineState',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(localStorage.getItem('line'))
});

const LineListState = atom({
    key: 'lineListState',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(localStorage.getItem('lineList'))
});

const MqttState = atom({
    key: 'mqttState',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(localStorage.getItem('realtime'))
});

export { TenantState, LineState, LineListState, MqttState };