import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Calendar } from "react-calendar";
import { useOnClickOutside } from "../../../utils/utils";
import LoadOutButton from "../loadOutButton";
import { DateDropdownCard, DateDropdownFooter } from "./style";

export default function LoadOutCalendarPicker({
  dateValue,
  setDateValue,
  openModal,
  setOpenModal,
  setDateLabel,
  rightMobile,
  setValue,
  position,
}) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [auxDate, setAuxDate] = useState(new Date());
  const ref = useRef();
  useOnClickOutside(ref, () => setOpenModal(false));

  useEffect(() => {
    const labelAux = currentDate.toDateString().split(" ");
    setDateLabel &&
      setDateLabel(`${labelAux[1]} ${labelAux[2]}, ${labelAux[3]}`);
  }, [dateValue]);

  useEffect(() => {
    const aux =
      typeof auxDate === "string"
        ? new Date(String(auxDate).replace("-", "/"))
        : auxDate;
    const labelAux = aux.toDateString().split(" ");
    setCurrentDate(aux);
    setDateLabel &&
      setDateLabel(`${labelAux[1]} ${labelAux[2]}, ${labelAux[3]}`);
  }, [auxDate]);

  useEffect(() => {
    setAuxDate(dateValue);
  }, [openModal]);

  const setDate = () => {
    setDateValue(auxDate);
  };

  return (
    <DateDropdownCard
      position={position}
      ref={ref}
      rightMobile={rightMobile}
      active={openModal ? "openModal" : undefined}
    >
      <Calendar
        calendarType="US"
        value={auxDate}
        locale="en"
        onChange={(value, event) => {
          setAuxDate(value);
        }}
      />
      <LoadOutButton
        color="border"
        label="Today"
        onClick={() => {
          setAuxDate(new Date());
        }}
      />
      <DateDropdownFooter>
        <LoadOutButton
          color="border"
          label="Cancel"
          onClick={() => setOpenModal(false)}
        />
        <LoadOutButton
          label="Apply"
          onClick={() => {
            setDate();
            setOpenModal(false);
          }}
        />
      </DateDropdownFooter>
    </DateDropdownCard>
  );
}
