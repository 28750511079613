import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LoadOutAlert from "../../../components/fragments/loadOutAlert";
import LoadOutButton from "../../../components/fragments/loadOutButton";
import LoadOutInput from "../../../components/fragments/loadOutInput";
import LoadOutLink from "../../../components/fragments/loadOutLink";
import Swal from "sweetalert2";
import { IconHeader, ResendEmail, TitleHeader } from "./style";
import GoBackButton from "../../../components/fragments/goBackButton";
import EmailIcon from "../../../assets/images/icons/header-email.svg";
import KeyIcon from "../../../assets/images/icons/header-key.svg";
import api from "../../../api/axios";
import LoginGeneral from "../../../components/fragments/loginGeneral";
import { LogoSubTitle } from "../../login/style";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const history = useHistory();

  function RequestReset() {
    api
      .post("define", { email: email })
      .then((data) => {
        setSentEmail(true);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });
  }

  return (
    <LoginGeneral>
      <LoadOutAlert
        active={alertModal}
        setActive={setAlertModal}
        type="success"
        title="A new link has been sent!"
        desc="We sent a new password link to you"
      />
      <IconHeader
        src={sentEmail ? EmailIcon : KeyIcon}
        alt={sentEmail ? "E-Mail icon" : "Key icon"}
      />
      <TitleHeader>
        <FormattedMessage
          id={sentEmail ? "check_email" : "forgot_my_password"}
        />
      </TitleHeader>

      {sentEmail ? (
        <LogoSubTitle>
          <FormattedMessage id="we_sent_password_reset" />
          <br />
          {email}
        </LogoSubTitle>
      ) : (
        <LogoSubTitle>
          <FormattedMessage id="forgot_my_password_subtitle" />
        </LogoSubTitle>
      )}

      {sentEmail && (
        <ResendEmail>
          <FormattedMessage id="didnt_receive_email" />{" "}
          <LoadOutLink
            onClick={() => setAlertModal(true)}
            label={<FormattedMessage id="click_to_resend" />}
          />
        </ResendEmail>
      )}

      {!sentEmail && (
        <>
          <LoadOutInput
            type="email"
            highlight={<FormattedMessage id="add_an_email" />}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            groupStyle={{ marginBottom: 24 }}
          />

          <LoadOutButton
            onClick={RequestReset}
            label={<FormattedMessage id="ask_reset_link" />}
          />
        </>
      )}

      <GoBackButton
        style={{ marginTop: 24 }}
        to="/"
        label={<FormattedMessage id="back_to_login" />}
      />
    </LoginGeneral>
  );
}
