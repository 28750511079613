import React, { useState } from "react";
import helpIcon from "../../../assets/images/icons/help-circle.svg";
import {
  TooltipButton,
  TooltipCard,
  TooltipCardDesc,
  TooltipCardTitle,
  TooltipContainer,
} from "./style";

export default function Tooltip({ title, desc, style }) {
  const [cardOpen, setCardOpen] = useState(false);

  return (
    <TooltipContainer onClick={() => setCardOpen(!cardOpen)} style={style}>
      <TooltipButton
        onMouseEnter={() => setCardOpen(!cardOpen)}
        src={helpIcon}
      />
      <TooltipCard
        onMouseLeave={() => setCardOpen(!cardOpen)}
        active={cardOpen}
      >
        <TooltipCardTitle>{title}</TooltipCardTitle>
        <TooltipCardDesc>{desc}</TooltipCardDesc>
      </TooltipCard>
    </TooltipContainer>
  );
}
