import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import { textSmRegular, textSmSemiBold } from "../../../styles/typography";

export const PwaButton = styled.button`
  ${({ theme }) => css`
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 32px;
    gap: 14px;
    margin: 0 16px;
    border-radius: 4px;
    padding: 13.5px 27.58px 13.5px 13px;
    background-color: ${appTheme.colors.orange[50]};

    @media (max-width: 900px) {
      display: flex;
    }

    & img {
      height: 14px;
    }

    & p {
      color: ${appTheme.colors.gray[500]};
      ${textSmRegular}
      margin: 0;

      & span {
        ${textSmSemiBold}
        color: ${appTheme.colors.purple[500]};
      }
    }
  `}
`;
