import { createGlobalStyle } from "styled-components";
import styled, { css } from "styled-components/macro";
import appTheme from "./themes";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'Inter';
  }

  iframe {
    display: none !important;
  }

  * {
    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${appTheme.colors.gray[200]};
      border-radius: 8px;
      border: 6px solid ${appTheme.colors.white};
    }
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li{
    ::marker {
      display: none
    }
  }

  html {
    scroll-behavior: smooth;
  }

  a, button, label {
    transition: .3s ease;
  }

  a {
    text-decoration: none;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
`;

export const RouteMain = styled.div`
  ${({ theme }) => css`
    padding-left: 86.5;

    @media (max-width: 900px) {
      padding: 0;
    }
  `}
`;
