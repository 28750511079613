import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import iconTic from "../../assets/images/icons/tic.svg";
import { GridContainer } from '../children/company-settings/style'
import LoadOutInput from "../../components/fragments/loadOutInput";
import ModalPattern from "../../components/fragments/modalPattern";
import LoadOutDropdownButton from "../../components/fragments/loadOutDropdownButton";
import {
  DriverItemItem,
  DriverItemList,
  DriverItemModal,
  FieldContainer,
  RegisterFormOneColumn,
} from "../../styles/common";
import { TooltipContainer } from "./style";
import FeedBackModal from "../../components/fragments/feedBackModal";
import { checkTokenValidity, useOnClickOutside } from "../../utils/utils";
import { useAuth } from '../../context/AuthProvider/useAuth';
import appTheme from "../../styles/themes";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Dynamic from "../../components/fragments/dynamic/";

export default function AddEdit({
  openModal,
  setOpenModal,
  refreshData,
  id,
  setId,
  object, 
  setLoading
}) {
  const auth = useAuth();
  const [showDynamic, setShowDynamic] = useState(false);
  const [templateObject, setTemplateObject] = useState(object)
  const [statusDropdown, setStatusDropdown] = useState(false);
  //const [text] = useState(undefined);
  const [showEmoji, setShowEmoji] = useState(false);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  useEffect(() => {
    if (openModal) {
      document.getElementById("name")?.focus();
    }
  }, [openModal]);

  function setText(tag) {
    console.log(tag);
    /*if (!tag) {
      return;
    }*/

    const newText = (templateObject.message ?? "") + tag;

    setTemplateObject({ ...templateObject, message: newText });
    //setText(undefined);
    document.getElementById("textarea")?.focus();
  }
  

  const statusRef = useRef();
  useOnClickOutside(statusRef, () => setStatusDropdown(false));

  const resetForm = () => {
    setId("");
    setTemplateObject({})
    setOpenModal(false);
  };

  const emojiRef = useRef();
  useOnClickOutside(emojiRef, () => setShowEmoji(false));

  const dynamicRef = useRef();
  useOnClickOutside(dynamicRef, () => setShowDynamic(false));

  async function onSubmit() {
    try {
      if (id) {
        const { data } = await auth.putTemplateUpdateById(id, templateObject)
        if (data.res) {
          setModalObject({
            type: "success",
            buttonLabel: "Ok",
            open: true,
            title: "Success",
            desc: "Template successfully updated.",
            action: () => resetForm(),
          });

          refreshData();
        }
        return
      }
      const { data } = await auth.postTemplateCreate({ ...templateObject });
      if (data.res) {
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Success",
          desc: "Template successfully added.",
          action: () => resetForm(),
        });

        refreshData();
      }

    } catch ({ response }) {
      //checkTokenValidity(response.data)
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response?.data.message,
      });
    }
  }

  useEffect(() => {
    if (id && object.id) {
      setTemplateObject(object);
      return;
    }
    setTemplateObject({});
  }, [object, id]);

  function handleShowEmoji (e) {
    setShowEmoji(!showEmoji);
  }

  function handleShowDynamic (e) {
    setShowDynamic(!showDynamic);
  }

  function handleEmoji(e) {
    setShowEmoji(false);
    setTemplateObject({ ...templateObject, message: templateObject.message ? templateObject.message + e.native : e.native });
    document.getElementById("textarea").focus();
    console.log(e);
  }

  return (
    <>
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <ModalPattern
        open={openModal}
        setOpen={setOpenModal}
        title={id ? 'Edit Template' : 'Create Template'}
        saveAction={onSubmit}
        cancelAction={resetForm}
      >
        <RegisterFormOneColumn width="664px">
          <span ref={emojiRef} className="m-0" style={{ display: showEmoji ? 'block' : 'none', position: "fixed", zIndex: "101" }}>
            <Picker data={data} theme="light" previewPosition="none" maxFrequentRows="2" onEmojiSelect={emoji => handleEmoji(emoji)} />
          </span>
          <div ref={dynamicRef} className="m-0" style={{ display: showDynamic ? 'block' : 'none', position: "fixed", zIndex: "100" }}>
            <Dynamic openModal={showDynamic} setOpenModal={setShowDynamic} setLoading={setLoading} setText={setText} />
          </div>
          <LoadOutInput
            id="name"
            highlight={<FormattedMessage id="form.name" />}
            onChange={({ target }) => setTemplateObject({ ...templateObject, name: target.value })}
            value={templateObject.name ?? ""}
          />
          <div className="input-group">
            <textarea id="textarea" className="small form-control border-0 shadow-none p-2 px-3"
              value={templateObject.message ?? ""}
              onChange={(e) => { 
                setTemplateObject({ ...templateObject, message: e.target.value });
              }} 
              placeholder="Message" 
              rows={3} 
              data-emoji-input="" 
              data-autosize="true" 
              style={{ width: "100%", fontSize: "14px", backgroundColor: "#F6F9FB", overflow: "hidden", overflowWrap: "break-word", resize: "none", minHeight: "45px", maxHeight: "250px", overflowY: "auto" }}>
            </textarea>
          </div>
          <div className="row d-flex justify-content-between">                
            <div className="col d-flex">
              <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none" style={{ cursor: "pointer" }}>
                <div className="tooltiptext"><FormattedMessage id="emojis" /></div>
                <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} ref={emojiRef} onClick={handleShowEmoji}>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                    <circle cx="12" cy="12.5" r="10" fill="transparent"></circle>
                    <path d="M12 23.14a10.75 10.75 0 1 1 10.75-10.75A10.77 10.77 0 0 1 12 23.14zm0-20a9.25 9.25 0 1 0 9.25 9.25A9.26 9.26 0 0 0 12 3.14zm5.36 12.34a.74.74 0 0 0-.27-1 .76.76 0 0 0-1 .27 4.68 4.68 0 0 1-8.12 0 .75.75 0 0 0-1-.27.74.74 0 0 0-.27 1 6.18 6.18 0 0 0 10.72 0zm-2.73-5a.88.88 0 1 0 .87-.87.88.88 0 0 0-.87.83zm-7 0a.88.88 0 1 0 1.75 0 .88.88 0 0 0-1.75 0zm3.17-.29"></path>
                  </svg>
                </span>
              </TooltipContainer>
              <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none" style={{ cursor: "pointer" }}>
                <div className="tooltiptext"><FormattedMessage id="dynamic" /></div>
                <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowDynamic}>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                    <rect fill="transparent" x="4" y="3" width="16" height="18.2"></rect>
                    <path d="M8.34,22H4a.8.8,0,0,1-.8-.8V2.94A.79.79,0,0,1,4,2.15H8.34a.79.79,0,0,1,.8.79.8.8,0,0,1-.8.8H4.76V20.4H8.34a.79.79,0,0,1,.8.79A.8.8,0,0,1,8.34,22Zm12.5-.8V2.94a.79.79,0,0,0-.8-.79H15.66a.79.79,0,0,0-.8.79.8.8,0,0,0,.8.8h3.58V20.4H15.66a.79.79,0,0,0-.8.79.8.8,0,0,0,.8.8H20A.8.8,0,0,0,20.84,21.19Z"></path>
                  </svg>
                </span>
              </TooltipContainer>
            </div>
          </div>
        </RegisterFormOneColumn>
      </ModalPattern>
    </>
  );
}
