import styled, { css } from "styled-components";
import appTheme from "../../styles/themes";
import { textXsMedium } from "../../styles/typography";

export const TooltipContainer = styled.div`
  ${({ }) => css`
    position: relative;
    display: inline-block;

    & .tooltiptext {
      ${textXsMedium}
      visibility: hidden;
      width: 120px;
      background-color: ${appTheme.colors.gray[700]};
      color: #fff;
      text-align: center;
      border-radius: 5px;
      padding: 5px 0;
      position: absolute;
      z-index: 10;
      top: -2px;
      left: 130%;
      
      /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
      opacity: 0;
      transition: opacity 0.4s;
      transition-delay: 1.5s;

      &:hover {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }

    & .tooltiptext::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent ${appTheme.colors.gray[700]} transparent transparent;
    }

    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  `}
`;
