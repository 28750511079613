import React, { useEffect, useState } from "react";
import LoadOutInput from "../../../components/fragments/loadOutInput";
import { FormattedMessage } from "react-intl";
import ModalPattern from "../../../components/fragments/modalPattern";
import { FormContainer } from "../company-settings/style";
import Loading from "../../../components/fragments/Loading";
import FeedBackModal from "../../../components/fragments/feedBackModal";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { checkTokenValidity } from "../../../utils/utils";

export default function ChangePasswordModal({ open, setOpen }) {
  const auth = useAuth();
  const [passwordBody, setPasswordBody] = useState({
    oldpass: "",
    newpass: "",
    confirmpass: "",
  });
  const [isRevealCurrentPassword, setIsRevealCurrentPassword] = useState(false);
  const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);
  const [modalObj, setModalObj] = useState({
    type: "alert",
    buttonLabel: "Ok",
    open: false,
    title: "Success!",
    desc: "This note has been deleted.",
  });
  const [loading, setLoading] = useState(false);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  const passwordDataBodyReset = () =>
    setPasswordBody({
      oldpass: "",
      newpass: "",
      confirmpass: "",
    });

  useEffect(() => {
    passwordDataBodyReset();
  }, [open]);

  async function onSubmit() {
    if (Object.values(passwordBody).some((field) => field.trim() === "")) {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Ops...",
        desc: "Fill all the fields",
        action: () => setOpen(false),
      });
      return;
    }
    if (passwordBody.newpass !== passwordBody.confirmpass) {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Ops...",
        desc: "Your new password don't match with the confirmation.",
        action: () => setOpen(false),
      });
      return;
    }
    try {
      setLoading(true);
      const { data } = await auth.postPasswordChange(passwordBody);
      if (data.res) {
        passwordDataBodyReset();
        setIsRevealCurrentPassword(false);
        setIsRevealNewPassword(false);
        localStorage.setItem("token", data.token);
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Password reset",
          desc: "Your password has been successfully reset.",
          action: () => setOpen(false),
        });
      }
    } catch ({ response }) {
      checkTokenValidity(response.data);
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalPattern
      title={<FormattedMessage id="change-password" />}
      open={open}
      setOpen={setOpen}
      saveAction={onSubmit}
    >
      <Loading visible={loading} />
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />

      <FormContainer>
        <LoadOutInput
          highlight={<FormattedMessage id="current-password" />}
          type="password"
          value={passwordBody.oldpass}
          onChange={({ target }) =>
            setPasswordBody({ ...passwordBody, oldpass: target.value })
          }
        />
        <LoadOutInput
          highlight={<FormattedMessage id="new-password" />}
          type="password"
          value={passwordBody.newpass}
          onChange={({ target }) =>
            setPasswordBody({ ...passwordBody, newpass: target.value })
          }
        />
        <LoadOutInput
          highlight={<FormattedMessage id="confirm-password" />}
          type="password"
          value={passwordBody.confirmpass}
          onChange={({ target }) =>
            setPasswordBody({ ...passwordBody, confirmpass: target.value })
          }
        />
      </FormContainer>
    </ModalPattern>
  );
}
