import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";

export const PwdResetSuccess = styled.p`
  ${({ theme }) => css`
    margin: 0 0 32px;
    font-size: 14px;
    line-height: 20px;
    color: ${appTheme.colors.gray[500]};
    text-align: center;
  `}
`;
