import styled, { css } from "styled-components";
import { DropdownField } from "../../components/fragments/loadOutDropdown/style";
import { RosterHeaderButtons, RosterTable, GatesLineResponsiveContainer } from "../../styles/common";
import appTheme from "../../styles/themes";
import { textSmMedium, textSmSemiBold, textXsMedium, textXsSemiBold } from "../../styles/typography";

const getStatusBall = (type) => {
  if (type) {
    if (type.includes("Active")) {
      return appTheme.colors.success[500];
    } else if (type.includes("Onboarding")) {
      return appTheme.colors.blue[500];
    } else if (type.includes("Offboarded")) {
      return appTheme.colors.orange[500];
    } else if (type === "Inactive ") {
      return appTheme.colors.red[500];
    } else {
      return appTheme.colors.gray[500];
    }
  }
};

const getStatusBallColor = (status) => {
  switch (status) {
    case 0:
      return appTheme.colors.gray[500];
    case 1:
      return appTheme.colors.success[500];
    default:
      return appTheme.colors.purple[600];
  }
};

export const QuantityBall = styled.span`
  ${({ theme, active }) => css`
    ${textSmMedium}
    margin-left: 8px;
    padding: 2px 10px;
    border-radius: 16px;
    transition: 0.3s ease;
    background-color: ${active
      ? appTheme.colors.purple[50]
      : appTheme.colors.gray[100]};
    color: ${active ? appTheme.colors.purple[500] : appTheme.colors.gray[700]};
  `}
`;

const getStatusColor = (type) => {
  if (type) {
    if (type.includes("Active")) {
      return appTheme.colors.green[700];
    } else if (type.includes("Onboarding")) {
      return appTheme.colors.blue[700];
    } else {
      return appTheme.colors.gray[700];
    }
  }
};

const getStatusBgColor = (type) => {
  if (type) {
    if (type.includes("Active")) {
      return appTheme.colors.green[50];
    } else if (type.includes("Onboarding")) {
      return appTheme.colors.blue[50];
    } else {
      return appTheme.colors.gray[100];
    }
  }
};

export const TableStatus = styled.div`
  ${({ theme, type }) => css`
    background-color: ${getStatusBgColor(type)};
    padding: 2px 8px 2px 7px;
    border-radius: 16px;
    align-items: center;
    width: fit-content;
    display: flex;
    gap: 7px;

    @media (max-width: 1270px) {
      margin-top: 4px;
    }

    & span {
      ${textXsMedium}
      color: ${getStatusColor(type)};
    }

    & figure {
      width: 8px;
      margin: 0%;
      height: 8px;
      flex-shrink: 0;
      border-radius: 100%;
      background-color: ${getStatusBall(type)};
    }
  `}
`;

export const ViewAllContainer = styled.div`
  ${({ theme }) => css`
    position: relative;

    @media (max-width: 750px) {
      display: none;
    }

    & > div:last-of-type {
      width: 189px;
      right: 0;

      & li {
        gap: 9px;
      }

      & ul {
        overflow: initial;
      }

      & span {
        color: ${appTheme.colors.gray[900]};
      }
    }
  `}
`;

export const ViewAllContainerMobile = styled(ViewAllContainer)`
  ${({ theme }) => css`
    @media (max-width: 750px) {
      display: block;
    }
  `}
`;

export const ActiveBall = styled.div`
  ${({ theme, status }) => css`
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 100%;
    background-color: ${getStatusBallColor(status)};
  `}
`;

export const ViewAllButton = styled(DropdownField)`
  ${({ theme, active }) => css`
    background-color: ${appTheme.colors.white};
    border: 1px solid
      ${active ? appTheme.colors.purple[300] : appTheme.colors.gray[200]};
    padding: 10px 20px;
    width: 116px;
    gap: 8px;

    @media (max-width: 750px) {
      padding: 9.14px 12.8px;
    }

    &:hover {
      border: 1px solid ${appTheme.colors.purple[300]};
    }

    & span {
      color: ${appTheme.colors.gray[500]};
      flex-shrink: 0;
    }
  `}
`;

export const UsersTable = styled(RosterTable)`
  ${({ theme }) => css`
    & thead {
      & th {
        &:nth-of-type(4) {
          min-width: 240px;

          @media (max-width: 1350px) {
            min-width: 173px;
          }
        }
      }
    }
    & tbody {
      & td {
        ${textSmMedium}
        color: ${appTheme.colors.gray[500]};

        &:first-of-type {
          align-items: center;
        }
      }
    }
  `}
`;

export const GroupsTable = styled.table`
${({ theme }) => css`
width: 100%;

    & thead {
      background-color: ${appTheme.colors.gray[25]};

      @media (max-width: 1270px) {
        
        
      }

      & th {
        
        ${textXsSemiBold}
        color: ${appTheme.colors.gray[500]};
        padding: 14px;

        & > span {
          display: flex;
          gap: 4px;
        }

        &:first-of-type {
          min-width: 60px;
          display: flex;

          & span {
            margin-right: 1px;
            margin-left: 1px;
          }
        }

        &:nth-of-type(2) {
          min-width: 60px;
        }

        &:nth-of-type(3) {
          min-width: 60px;
        }

        &:nth-of-type(4) {
          min-width: 83px;
        }
        &:nth-of-type(5) {
          min-width: 73px;
        }
        &:nth-of-type(6) {
          min-width: 63px;
        }
      }
    }

    & tbody {
      
      & tr {
        ${textSmMedium}
        &:not(:first-of-type) {
          border-top: 1px solid ${appTheme.colors.gray[100]};
        }

        @media (max-width: 1270px) {
          
        }
      }
      & td {
        
        height: 52px;
        padding: 5px;

        &:first-of-type {
          padding: 5px;
        }
      }
    }
  `}
`;

export const FormUsersContainer = styled.div`
  ${({ theme, page }) => css`
    margin: 54px auto 0;
    max-width: ${page ? "714px" : "initial"};
    @media (min-width: 750px) {
      width: 80%;
    }
  `}
`;

export const DevicesHeaderButtonsMobile = styled(RosterHeaderButtons)`
  ${({ theme }) => css`
    display: none;

    @media (max-width: 750px) {
      display: flex;
      width: 100%;
      margin-top: 18px;

      & button {
        width: 100%;
        justify-content: center;
      }
    }
  `}
`;

export const DevicesLineResponsiveContainer = styled(
  GatesLineResponsiveContainer
)`
  ${({ theme }) => css`
    justify-content: space-evenly;
    gap: 24px;

    & p {
      color: ${appTheme.colors.gray[500]};
    }
  `}
`;

export const AddressLine = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
  `}
`;

export const AddressContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 7px;

    & img {
      flex-shrink: 0;
      width: 40px;
    }

    & h5 {
      ${textSmSemiBold}
      margin: 0;
    }
  `}
`;
export const CodeContainer = styled.div`
  ${({ theme }) => css`
    flex-shrink: 0;
  `}
`;