import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoginGeneral from "../../../components/fragments/loginGeneral";
import LoadOutButton from "../../../components/fragments/loadOutButton";
import LoadOutInput from "../../../components/fragments/loadOutInput";
import Swal from "sweetalert2";

import api from "../../../api/axios";

import KeyIcon from "../../../assets/images/icons/header-key.svg";
import SucessIcon from "../../../assets/images/icons/header-tic.svg";
import Loading from "../../../components/fragments/Loading";
import { IconHeader, TitleHeader } from "../forgot-password/style";
import { FormattedMessage } from "react-intl";
import { PwdResetSuccess } from "./style";

export default function Define() {
  const history = useHistory();
  const [pwdReseted, setPwdReseted] = useState(false);

  let { id } = useParams();

  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [loading, setLoading] = useState(false);

  function HandleSend() {
    setLoading(true);

    api
      .post("define/" + id, { pass: pass })
      .then((data) => {
        setPwdReseted(true);
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      })
      .finally((_) => {
        setLoading(false);
      });
  }

  return (
    <LoginGeneral>
      <Loading visible={loading} />

      <IconHeader
        src={pwdReseted ? SucessIcon : KeyIcon}
        alt="Key icon"
      />
      <TitleHeader>
        <FormattedMessage
          id={pwdReseted ? "password_reset" : "set_new_password"}
        />
      </TitleHeader>

      {pwdReseted ? (
        <PwdResetSuccess>
          <FormattedMessage id="password_reset_success" />
          <br />
          <FormattedMessage id="click_bellow_login" />
        </PwdResetSuccess>
      ) : (
        <>
          <LoadOutInput
            type="password"
            value={pass}
            onChange={(e) => {
              setPass(e.target.value);
            }}
            //suport={<FormattedMessage id="must_least_chara" />}
            highlight={<FormattedMessage id="new-password" />}
          />

          <LoadOutInput
            type="password"
            value={confPass}
            onChange={(e) => {
              setConfPass(e.target.value);
            }}
            groupStyle={{ margin: "24px 0" }}
            highlight={<FormattedMessage id="confirm_password" />}
          />
        </>
      )}

      <LoadOutButton
        onClick={
          pwdReseted
            ? () => {
                history.push("/");
              }
            : HandleSend
        }
        label={
          <FormattedMessage id={pwdReseted ? "login" : "reset_password"} />
        }
      />
    </LoginGeneral>
  );
}
