import { useState } from "react";
import { FormattedMessage } from "react-intl";
import LoadOutCheckbox from "../../components/fragments/loadOutCheckbox";
import ThreeModalActions from "../../components/fragments/threeModalActions";
import {
  DisplayBlock,
  TableLineResponsive,
  TableLineResponsiveHeader,
} from "../../styles/common";
import { formatDate } from "../../utils/utils";
import {
  AddressContainer,
  AddressLine,
  CodeContainer,
  DevicesLineResponsiveContainer,
} from "./style";
import FeedBackModal from "../../components/fragments/feedBackModal";

export default function ListItem({
  listData,
  updateData,
  deleteData,
  setAddEditRegister,
  setIdEditRegister,
  setCheckID,
}) {
  const [lineActionsDropdown, setLineActionsDropdown] = useState(false);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  function handleOnChange() {
    setCheckID(listData.id, !listData.active);
  }

  return (
    <>
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <TableLineResponsive>
        <TableLineResponsiveHeader>
          <AddressLine>
            <LoadOutCheckbox
              value={listData.active}
              onChange={handleOnChange}
            />
            <AddressContainer>
              <DisplayBlock>
                <h5>{listData.name}</h5>
              </DisplayBlock>
            </AddressContainer>
          </AddressLine>
          <ThreeModalActions
            openModal={lineActionsDropdown}
            setOpenModal={setLineActionsDropdown}
            editAction={async () => {
              setAddEditRegister(true);
              setIdEditRegister(listData.id);
              setLineActionsDropdown(false);
              await updateData(listData.id);
            }}
            deleteAction={() => {
              setLineActionsDropdown(false);
              setModalObject({
                type: "alert",
                buttonLabel: "Delete",
                open: true,
                title: "Confirm delete",
                desc: "Are you sure you want to delete this item?",
                action: async () => {
                  await deleteData(listData.id);
                  setModalObject({
                    ...modalObject,
                    open: false,
                  });
                },
              });
            }}
          />
        </TableLineResponsiveHeader>
        <DevicesLineResponsiveContainer>
          <div class="w-100">
            <CodeContainer>
              <h6>
                <FormattedMessage id="tabela.message" />
              </h6>
              <p class="text-break">{listData.message ?? "N/A"}</p>
            </CodeContainer>
          </div>
        </DevicesLineResponsiveContainer>
      </TableLineResponsive>
      <tr>
        <td>
          <LoadOutCheckbox
            value={listData.active}
            onChange={handleOnChange}
            label={listData.name}
          />
        </td>
        <td>
          <span>{listData.message ?? "N/A"}</span>
        </td>
        <td>
          <span>{formatDate(listData.createdat)}</span>
        </td>
        <td className="d-flex justify-content-end">
          <ThreeModalActions
            openModal={lineActionsDropdown}
            setOpenModal={setLineActionsDropdown}
            editAction={async () => {
              setAddEditRegister(true);
              setIdEditRegister(listData.id);
              setLineActionsDropdown(false);
              await updateData(listData.id);
            }}
            deleteAction={() => {
              setLineActionsDropdown(false);
              setModalObject({
                type: "alert",
                buttonLabel: "Delete",
                open: true,
                title: "Confirm delete",
                desc: "Are you sure you want to delete this item?",
                action: async () => {
                  await deleteData(listData.id);
                  setModalObject({
                    ...modalObject,
                    open: false,
                  });
                },
              });
            }}
          />
        </td>
      </tr>
    </>
  );
}
