import styled, { css } from "styled-components/macro";
import { textSmMedium } from "../../../styles/typography";
import appTheme from "../../../styles/themes";
import iconArrow from "../../../assets/images/icons/arrow-right2.svg";
import { Link } from "react-router-dom";

export const BreadcrumbContainer = styled.ul`
  ${({ theme }) => css`
    display: flex;
  `}
`;

export const CurrentLink = styled(Link)`
  ${({ theme }) => css`
    ${textSmMedium}
    cursor: pointer;
    margin-left: 40px;
    position: relative;
    color: ${appTheme.colors.gray[500]};
    transition: .3s ease;

    &:hover {
      color: ${appTheme.colors.purple[700]};
    }

    &::before {
      content: "";
      position: absolute;
      transform: translate(0, -50%);
      background: no-repeat url(${iconArrow}) center;
      width: 16px;
      height: 16px;
      left: -28px;
      top: 50%;
    }

    &:last-of-type {
      color: ${appTheme.colors.purple[700]};
    }
  `}
`;
