import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: 14;
    color: ${appTheme.colors.purple[500]};

    &:hover {
        color: ${appTheme.colors.purple[700]};
    }
  `}
`;
