import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./components/fragments/header";
import LeftMenu from "./components/fragments/leftMenu/";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard/";
import Chat from "./pages/chat";
import Contacts from "./pages/contacts";
import Templates from "./pages/templates";
import Users from "./pages/users";
import Groups from "./pages/groups";
import Imports from "./pages/imports";
import ContactConfirm from "./pages/imports/contact/contactConfirm";

import ForgotPassword from "./pages/children/forgot-password/";
import Define from "./pages/children/define";
import { RouteMain } from "./styles/global";
import ChkAuth from './context/AuthProvider/ChkAuth'

export default function Routes() {
  const [toggleSideBar, setToggleSideBar] = useState(false);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/forgot_password" exact component={ForgotPassword} />
        <Route path="/define/:id" exact component={Define} />
        <ChkAuth>
          <LeftMenu
            toggleSideBar={toggleSideBar}
            setToggleSideBar={setToggleSideBar}
          />
          <RouteMain>
            <Header
              toggleSideBar={toggleSideBar}
              setToggleSideBar={setToggleSideBar}
            />
            <Route path="/dashboard" component={Dashboard}
            />
            <Route path="/chat" component={
              Chat
            }
            />
            <Route path="/contacts" component={
              Contacts
            }
            />
            <Route path="/users" component={
              Users
            }
            />
            <Route path="/templates" component={
              Templates
            }
            />
            <Route path="/groups" component={
              Groups
            }
            />
            <Route path="/import" component={
              Imports
            }
            />
            <Route path="/import/contact/confirm" component={
              ContactConfirm
            }
            />
          </RouteMain>
        </ChkAuth>
      </Switch>

    </BrowserRouter>
  );
}
