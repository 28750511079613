import { Bar } from "react-chartjs-2";

const data = {
  labels: ["Q1", "Q2", "Q3", "Q4"],
  datasets: [
    {
      label: "Supports",
      backgroundColor: "rgba(197, 145, 237,0.2)",
      borderColor: "rgba(197, 145, 237,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(197, 145, 237,0.4)",
      hoverBorderColor: "rgba(197, 145, 237,1)",
      data: [65, 59, 80, 81],
    },
  ],
};

export const QuarterlySalesChart = () => {
  return (
    <div>
      <h5>Quarterly Supports</h5>
      <Bar data={data} />
    </div>
  );
};
