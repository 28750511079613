import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FeedBackModal from "../../components/fragments/feedBackModal";
import LoginGeneral from "../../components/fragments/loginGeneral";
import LoadOutInput from "../../components/fragments/loadOutInput";
import LoadOutButton from "../../components/fragments/loadOutButton";
import LoadOutCheckbox from "../../components/fragments/loadOutCheckbox";
import { FormSubActions, LogoImg, LogoSubTitle } from "./style";
import Logo from "../../assets/images/icons/load-out-logo.svg";
import Loading from "../../components/fragments/Loading";
import { FormattedMessage } from "react-intl";
import LoadOutLink from "../../components/fragments/loadOutLink";
import PWARequest from "../../components/fragments/pwaRequest";
import { checkRememberMe, fireAlert, hardReload } from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import * as PusherPushNotifications from '@pusher/push-notifications-web';

export default function Login() {
  const auth = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [modalObj, setModalObj] = useState({
    type: "alert",
    buttonLabel: "Ok",
    open: false,
    title: "Success!",
    desc: "This note has been deleted.",
  });
  const [modalAction, setModalAction] = useState(() => null);
  const [loading, setLoading] = useState(false);
  const [beamsClient, setBeamsClient] = useState();

  /*const beamsClient = new PusherPushNotifications.Client({
    instanceId: 'd62d18d7-982e-4a80-816f-1f54c66f9688',
    serviceWorkerRegistration: serviceWorkerRegistration
  });*/

  useEffect(() => {
    if (!beamsClient) {
      window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        console.log("entrou no sw");
        setBeamsClient(new PusherPushNotifications.Client({
          instanceId: 'd62d18d7-982e-4a80-816f-1f54c66f9688',
          serviceWorkerRegistration: serviceWorkerRegistration,
        }));
      });
    }
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const data = await auth.login({ email, pass });
      console.log(JSON.stringify(data));
      if (rememberMe) {
        localStorage.setItem("rememberMe", email);
      } else {
        localStorage.setItem("rememberMe", null);
      }
      if (await data.auth) {
        history.push("/chat");
        //hardReload();
      }
    } catch ({ response }) {
      if (response?.data?.message) {
        setModalObj({
          type: "error",
          buttonLabel: "Ok",
          open: true,
          title: "Oops...",
          desc: response?.data?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(auth.tokenContext);
    if (auth.tokenContext) {
      history.push("/dashboard");
    }
    const { emailRemembered, isRemembered } = checkRememberMe();
    if (isRemembered && emailRemembered) {
      setEmail(emailRemembered);
      setRememberMe(isRemembered);
    }
    if (history.location.search.includes("expired")) {
      setModalObj({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: "Your session has expired. Please log in again.",
      });
    }
  }, [history]);

  function handlePush() {
    console.log("click");
    console.log(beamsClient);
    if (beamsClient) {
      beamsClient
        .start()
        .then((beamsClient) => beamsClient.getDeviceId())
        .then((deviceId) => console.log("Successfully registered with Beams. Device ID:", deviceId))
        .catch(console.error);
    }
  }

  return (
    <>
      <FeedBackModal
        modalObj={modalObj}
        setModalObj={setModalObj}
        action={modalAction}
      />
      <LoginGeneral>
        <PWARequest />

        <Loading visible={loading} />
        <LogoImg src={Logo} />

        <LogoSubTitle>
          <FormattedMessage id="enter_credentials" />
        </LogoSubTitle>

        <LoadOutInput
          onChange={({ target }) => setEmail(target.value)}
          value={email}
          highlight="Email"
          type="text"
          groupStyle={{ marginBottom: 24 }}
        />
        <LoadOutInput
          onChange={({ target }) => setPass(target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (pass && email) handleLogin();
            }
          }}
          groupStyle={{ marginBottom: 24 }}
          value={pass}
          highlight="Password"
          type="password"
        />

        <FormSubActions>
          <LoadOutCheckbox
            value={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            label={<FormattedMessage id="remember_me" />}
          />

          <LoadOutLink
            to="/forgot_password"
            label={<FormattedMessage id="forgot_password" />}
          />
        </FormSubActions>  
        
        <LoadOutButton
          label="Login"
          disabled={!(pass && email)}
          onClick={handleLogin}
        />

        <LoadOutLink
          label="Enable push notification here"
          onClick={handlePush}
        />
      </LoginGeneral>
    </>
  );
}
