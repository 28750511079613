import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import { textXsMedium } from "../../../styles/typography";

export const NotificationsComponent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `}
`;

export const NotificationDate = styled.h5`
  ${({ theme }) => css`
    ${textXsMedium}
    padding: 2px 8px;
    width: fit-content;
    border-radius: 16px;
    color: ${appTheme.colors.purple[600]};
    background-color: ${appTheme.colors.purple[50]};
    margin: 0;
  `}
`;

export const NotificationsList = styled.ul`
  ${({ theme }) => css`
    margin-bottom: 24px;
  `}
`;
export const NotificationsFooter = styled.footer`
  ${({ theme }) => css`
    gap: 12px;
    width: 100%;
    display: flex;
    margin-top: 36px;
    justify-content: flex-end;

    & button {
      width: fit-content;
    }
  `}
`;

export const NotificationButton = styled.button`
  ${({ theme, newNotifications }) => css`
    position: relative;

    ${newNotifications &&
    css`
      &:after {
        position: absolute;
        content: "";
        top: -3px;
        right: 1px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: ${appTheme.colors.red[500]};
        pointer-events: none;
      }
    `}

    & svg {
      transition: 0.3s ease;
      stroke: ${appTheme.colors.gray[500]};

      &:hover {
        stroke: ${appTheme.colors.purple[600]};
      }
    }
  `}
`;
