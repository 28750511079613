import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import { textMdMedium, textSmRegular } from "../../../styles/typography";

export const NotificationCardContainer = styled.li`
  ${({ theme, newNotification }) => css`
    box-shadow: 4px 7px 24px -10px rgba(0, 0, 0, 0.21);
    background-color: ${newNotification
      ? appTheme.colors.purple[25]
      : appTheme.colors.white};
    margin-top: 12px;
    border-radius: 12px;
    padding: 24px;
  `}
`;

export const NotificationTitle = styled.h6`
  ${({ theme, newNotification }) => css`
    ${textMdMedium}
    color: ${appTheme.colors.purple[900]};
    position: relative;
    margin-bottom: 8px;

    ${newNotification &&
    css`
      &::after {
        transform: translate(0, -50%);
        position: absolute;
        content: "";
        right: 0;
        top: 50%;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: ${appTheme.colors.red[500]};
      }
    `}
  `}
`;

export const NotificationDesc = styled.p`
  ${({ theme }) => css`
    ${textSmRegular}
    color: ${appTheme.colors.gray[500]};
    margin: 0;
    padding: 0;
  `}
`;
