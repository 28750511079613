import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import iconTic from "../../assets/images/icons/tic.svg";
import { GridContainer } from '../children/company-settings/style'
import LoadOutInput from "../../components/fragments/loadOutInput";
import ModalPattern from "../../components/fragments/modalPattern";
import LoadOutDropdownButton from "../../components/fragments/loadOutDropdownButton";
import {
  DriverItemItem,
  DriverItemList,
  DriverItemModal,
  FieldContainer,
  RegisterForm
} from "../../styles/common";
import FeedBackModal from "../../components/fragments/feedBackModal";
import { useRef } from "react";
import { checkTokenValidity, statusNames, getStatusName, useOnClickOutside } from "../../utils/utils";
import { useAuth } from '../../context/AuthProvider/useAuth';
import Loading from "../../components/fragments/Loading";

export default function AddEdit({
  openModal,
  setOpenModal,
  refreshData,
  id
}) {
  const auth = useAuth()
  const [contactObject, setContactObject] = useState()
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  const statusRef = useRef();
  useOnClickOutside(statusRef, () => setStatusDropdown(false));

  useEffect(async () => {
    setLoading(true);

    try {
      if (id && openModal) {
        const { data } = await auth.getContactById(id);
        if (data.res) {
          setContactObject(data.ret[0]);
        }
      }
    } catch ({ response }) {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response?.data.message,
      });
    } finally {
      setLoading(false);
    }
    
  }, [id, openModal]);

  const resetForm = () => {
    setContactObject({});
    setOpenModal(false);
  };

  async function onSubmit() {
    setLoading(true);

    try {
      if (id) {
        const { data } = await auth.putContactUpdateById(id, contactObject);
        if (data.res) {
          setModalObject({
            type: "success",
            buttonLabel: "Ok",
            open: true,
            title: "Success",
            desc: "This contact has been updated!",
            action: () => resetForm(),
          });
          refreshData(contactObject);
        }
        return;
      }
      const { data } = await auth.postContactCreate({ ...contactObject });
      if (data.res) {
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Success",
          desc: "This contact has been added!",
          action: () => resetForm(),
        });
        refreshData(contactObject);
      }
    } catch ({ response }) {
      //checkTokenValidity(response.data)
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response?.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      setContactObject({})
      return
    }
    setContactObject({})
  }, [id]);

  function maskNumber(number) {
    if (!number) { return; }
    var name = "";
    switch (true) {
      case number.indexOf("+1") === 0:
        name = "+1 (" + number.substring(2, 5) + ") " + number.substring(5, 8) + "-" + number.substring(8);
        break;
      case number.indexOf("+55") === 0:
        name = "+55 (" + number.substring(3, 5) + ") " + number.substring(5, 10) + "-" + number.substring(10);
        break;
      default:
        name = number;
    }

    return name;
  }

  return (
    <>
      <Loading visible={loading} style={{ zindex: 100 }} />
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <ModalPattern
        open={openModal}
        setOpen={setOpenModal}
        title={id ? 'Edit Contact' : 'Create Contact'}
        saveAction={onSubmit}
        cancelAction={resetForm}
      >
        <RegisterForm width="100%">
          <LoadOutInput
            highlight={<FormattedMessage id="form.phone" />}
            onChange={({ target }) => setContactObject({ ...contactObject, phone: target.value })}
            value={maskNumber(contactObject?.phone)}
            disabled
          />
          <LoadOutInput
            highlight={<FormattedMessage id="form.email" />}
            onChange={({ target }) => setContactObject({ ...contactObject, email: target.value })}
            value={contactObject?.email}
          />
          <GridContainer>
            <LoadOutInput
              highlight={<FormattedMessage id="form.fname" />}
              onChange={({ target }) => setContactObject({ ...contactObject, fname: target.value })}
              value={contactObject?.fname}
            />
            <LoadOutInput
              highlight={<FormattedMessage id="form.lname" />}
              onChange={({ target }) => setContactObject({ ...contactObject, lname: target.value })}
              value={contactObject?.lname}
            />
          </GridContainer>
          <LoadOutInput
            highlight={<FormattedMessage id="form.company" />}
            onChange={({ target }) => setContactObject({ ...contactObject, company: target.value })}
            value={contactObject?.company}
          />
          <LoadOutInput
            highlight={<FormattedMessage id="form.title" />}
            onChange={({ target }) => setContactObject({ ...contactObject, title: target.value })}
            value={contactObject?.title}
          />
          <LoadOutInput
            highlight={<FormattedMessage id="form.address1" />}
            onChange={({ target }) => setContactObject({ ...contactObject, address1: target.value })}
            value={contactObject?.address1}
          />
          <LoadOutInput
            highlight={<FormattedMessage id="form.address2" />}
            onChange={({ target }) => setContactObject({ ...contactObject, address2: target.value })}
            value={contactObject?.address2}
          />
          <GridContainer>
            <LoadOutInput
              highlight={<FormattedMessage id="form.state" />}
              onChange={({ target }) => setContactObject({ ...contactObject, state: target.value })}
              value={contactObject?.state}
            />
            <LoadOutInput
              highlight={<FormattedMessage id="form.city" />}
              onChange={({ target }) => setContactObject({ ...contactObject, city: target.value })}
              value={contactObject?.city}
            />
          </GridContainer>
          <LoadOutInput
            highlight={<FormattedMessage id="form.zip" />}
            onChange={({ target }) => setContactObject({ ...contactObject, zip: target.value })}
            value={contactObject?.zip}
          />
          <LoadOutInput
            highlight={<FormattedMessage id="form.country" />}
            onChange={({ target }) => setContactObject({ ...contactObject, country: target.value })}
            value={contactObject?.country}
          />
          <LoadOutInput
            highlight={<FormattedMessage id="form.notes" />}
            onChange={({ target }) => setContactObject({ ...contactObject, notes: target.value })}
            value={contactObject?.notes}
          />
          <FieldContainer ref={statusRef}>
            <LoadOutDropdownButton
              openModal={statusDropdown}
              setOpenModal={() => setStatusDropdown(!statusDropdown) }
              placeholder={<FormattedMessage id="form.status" />}
              value={
                getStatusName(contactObject?.status)
              }
            />
            <DriverItemModal active={statusDropdown}>
              <DriverItemList>
                {Object.keys(statusNames).map((key, index) => {
                  return (<DriverItemItem
                    key={index}
                    active={contactObject?.status === Number(key)}
                    onClick={() => {
                      setContactObject({ ...contactObject, status: Number(key) });
                      setStatusDropdown(false);
                    }}
                  >
                    {getStatusName(Number(key))}
                    <img src={iconTic} alt="Icon Tic" />
                  </DriverItemItem>)
                })}
              </DriverItemList>
            </DriverItemModal>
          </FieldContainer>
        </RegisterForm>
      </ModalPattern>
    </>
  );
}
