import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import { textXsMedium } from "../../../styles/typography";

export const TooltipContainer = styled.span`
  ${({}) => css`
    opacity: 0;
    top: 0;
    left: 61px;
    position: absolute;
    pointer-events: none;
    transition: 0.4s ease-out;
    background-color: ${appTheme.colors.gray[900]};
    border-radius: 8px;
    padding: 8px 12px;

    &::before {
      top: 50%;
      left: -8px;
      content: "";
      width: 12px;
      height: 12px;
      position: absolute;
      border-radius: 1px;
      transform: rotate(45deg) translate(0, -50%);
      background-color: ${appTheme.colors.gray[900]};
    }

    & span {
      ${textXsMedium}
      color: ${appTheme.colors.white};
      white-space: nowrap;
    }

    @media (max-width: 900px) {
      display: none;
    }
  `}
`;
