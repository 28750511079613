import React, { useEffect, useRef, useState } from "react";
import brFlag from "../../../assets/images/icons/flags/pt.svg";
import enFlag from "../../../assets/images/icons/flags/en.svg";
import esFlag from "../../../assets/images/icons/flags/es.svg";
import downArrowIcon from "../../../assets/images/icons/arrow-right.svg";
import hamBallsIcon from "../../../assets/images/icons/ham-balls.svg";
import emptyAvatar from "../../../assets/images/icons/empty-avatar.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import CompanySettingsModal from "../../../pages/children/company-settings";
import ChangePasswordModal from "../../../pages/children/change-password";
import arrowIcon from "../../../assets/images/icons/arrow.svg";
import Notifications from "../notifications";
import { FormattedMessage } from "react-intl";
import {
  ArrowIcon,
  BallsIcon,
  ImageCircle,
  ImageLabel,
  ImageLabelModal,
  LanguageButton,
  LanguagesDropdown,
  LanguageSettings,
  LogOutContainer,
  MobileHidden,
  SettingsDropdown,
  UserNavContainer,
  UserProfile,
  UserProfileActions,
  UserProfilePicture,
  UserSettings,
} from "./style";
import { LeftMenuButton } from "../leftMenu/style";
import LoadOutButton from "../loadOutButton";
import LoadOutTooltip from "../loadOutTooltip";
import { hardReload, useOnClickOutside } from "../../../utils/utils";
import Loading from "../../fragments/Loading";
import { useAuth } from "../../../context/AuthProvider/useAuth";

export default function UserNav({ mode, setToggleSideBar, getall, details }) {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  //const pathName = useLocation().pathname;
  //const [userName, setUserName] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [userDropDown, setUserDropDown] = useState(false);
  //const [settingsDropdown, setSettingsDropdown] = useState(false);
  const [languagesDropdown, setLanguagesDropdown] = useState(false);
  const [companySettingsModal, setCompanySettingsModal] = useState(false);
  const [changePwdModal, setChangePwdModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [getDetails, setGetDetails] = useState("");
  const [language, setLanguage] = useState("");
  const flags = {
    en: enFlag,
    es: esFlag,
    pt: brFlag,
  };

  const userRef = useRef();
  useOnClickOutside(userRef, () => setUserDropDown(false));
  
  const languagesRef = useRef();
  useOnClickOutside(languagesRef, () => setLanguagesDropdown(false));

  useEffect(() => {
    setGetDetails(details);
  }, [details]);

  useEffect(() => {
    setCountry(getall);
  }, [getall]);

  useEffect(() => {
    const currentLang = localStorage.getItem("language");

    if (currentLang === "null") {
    } else {
      setLanguage(currentLang);
    }

    /*if (
      auth?.userContext?.chgpss === 1 &&
      history.location.pathname.toLowerCase() !== "/change-password"
    ) {
      history.push("/change-password");
      return;
    }*/

    // if (userInfo.role === 1) {
    //   if (
    //     history.location.pathname.toLowerCase() !== "/dashboard" &&
    //     history.location.pathname.toLowerCase() !== "/gates" &&
    //     history.location.pathname.toLowerCase() !== "/counseling/list" &&
    //     history.location.pathname.toLowerCase() !== "/change-password" &&
    //     history.location.pathname.toLowerCase() !== "/scorecard"
    //   ) {
    //     history.push("/dashboard");
    //   }
    // }
  }, [location]);

  function Logout() {
    auth.logout();
    history.push("/");
    hardReload();
  }

  const getLanguageName = (label) => {
    if (label) {
      switch (label) {
        case "es":
          return "Espanhol";
        case "pt":
          return "Português";
        default:
          return "English";
      }
    }
  };

  return (
    <>
      <Loading visible={loading} />
      <CompanySettingsModal
        getDetails={getDetails}
        open={companySettingsModal}
        setOpen={setCompanySettingsModal}
        getall={country}
      />
      <ChangePasswordModal open={changePwdModal} setOpen={setChangePwdModal} />
      <UserNavContainer>
        {mode === "sidebar" ? (
          <LanguageSettings>
            <li className="dropdown">
              <LanguageButton
                active={languagesDropdown}
                onClick={() => setLanguagesDropdown(!languagesDropdown)}
              >
                <ImageLabel>
                  <LoadOutTooltip label={<span>English</span>} />

                  <ImageCircle>
                    <img src={flags[language]} alt="flag icon" />
                  </ImageCircle>
                  <span>{getLanguageName(language)}</span>
                </ImageLabel>
                <img src={downArrowIcon} alt="Arrow Icon" />
              </LanguageButton>
              <LanguagesDropdown ref={languagesRef} active={languagesDropdown}>
                {country?.map((language) => {
                  return (
                    <li key={language.code}>
                      <LanguageButton
                        onClick={() => {
                          localStorage.setItem("language", language.code);
                          document.location.reload();
                        }}
                      >
                        <ImageLabelModal>
                          <ImageCircle>
                            <img src={flags[language.code]} alt="flag icon" />
                          </ImageCircle>
                          <span>{getLanguageName(language.code)}</span>
                        </ImageLabelModal>
                      </LanguageButton>
                    </li>
                  );
                })}
              </LanguagesDropdown>
            </li>

            <LogOutContainer>
              <LeftMenuButton onClick={Logout} to="">
                <svg
                  width="19"
                  height="18"
                  stroke="#101828"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.25 15.75h-3a1.5 1.5 0 0 1-1.5-1.5V3.75a1.5 1.5 0 0 1 1.5-1.5h3m5.25 10.5L16.25 9m0 0L12.5 5.25M16.25 9h-9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Log Out</span>
              </LeftMenuButton>
            </LogOutContainer>
          </LanguageSettings>
        ) : (
          <UserSettings>
            <MobileHidden>
              {/* <Notifications newNotifications /> */}
            </MobileHidden>
            <UserProfile
              ref={userRef}
              onClick={() => setUserDropDown(!userDropDown)}
            >
              <UserProfilePicture
                src={profilePicture ? profilePicture : emptyAvatar}
                alt="User Profile"
              />
              <ArrowIcon
                active={userDropDown}
                src={arrowIcon}
                alt="Ícone de seta"
              />
              <BallsIcon src={hamBallsIcon} alt="Ícone de 3 circulos" />
            </UserProfile>
            <UserProfileActions active={userDropDown}>
              {auth?.userContext?.role === 0 && (
                <li>
                  <LoadOutButton
                    icon="home"
                    color="transparent"
                    onClick={() => {
                      history.push("/company-settings");
                      setCompanySettingsModal(!companySettingsModal);
                      setUserDropDown(false);
                    }}
                    label={<FormattedMessage id="company_settings" />}
                  />
                </li>
              )}
              <li>
                <LoadOutButton
                  icon="lock"
                  color="transparent"
                  onClick={() => {
                    setChangePwdModal(!changePwdModal);
                    setUserDropDown(false);
                  }}
                  label={<FormattedMessage id="change-password" />}
                />
              </li>
              <li>
                <LoadOutButton
                  onClick={Logout}
                  icon="exit"
                  color="transparent"
                  label={<FormattedMessage id="logout" />}
                >
                  <FormattedMessage id="logout" />
                </LoadOutButton>
              </li>
            </UserProfileActions>
          </UserSettings>
        )}
      </UserNavContainer>
    </>
  );
}
