import React from "react";
import { Link } from "react-router-dom";
import homeIcon from "../../../assets/images/icons/home.svg";
import { BreadcrumbContainer, CurrentLink } from "./style";

const Breadcrumb = ({ paths }) => {
  return (
    <BreadcrumbContainer>
      <Link to="/dashboard">
        <img src={homeIcon} alt="Ícone da Home" />
      </Link>
      {paths.map((item, i) => (
        <CurrentLink to={item.to} key={i}>
          {item.path}
        </CurrentLink>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
