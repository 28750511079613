import React, { useEffect, useState } from "react";
import { ModalContainer } from "./style";
import DragUploadFile from "../dragUploadImage";
//import FeedBackModal from "../feedBackModal";
//import { useAuth } from '../../../context/AuthProvider/useAuth';

export default function Image({openModal, setOpenModal, fileName, onFileChange}) {
  //const auth = useAuth();
  /*const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });*/

  /*useEffect(() => {
    
  }, []);*/

  /*function handleClick(tag) {
    
  }*/

  return (
    <>
      <ModalContainer
        style={{ height: "350px", width: "350px", position: "fixed", textAlign: "start", alignItems: "start" }}
        open={openModal}
        setOpen={setOpenModal}
      >
        <div className="small fw-bold text-center mb-2">Images</div>
        <DragUploadFile
          title="Mentor"
          fileName={fileName}
          id="label-upload-image"
          onFileChange={onFileChange}
          toolTipTitle="Image"
          toolTipDesc="Upload image to chat"
        />
        
      </ModalContainer>
    </>
  );
}
