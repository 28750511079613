import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
//import api from "../../../api/axios";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import Loading from "../../../components/fragments/Loading";
import { useHistory, useLocation } from "react-router-dom";
import FeedBackModal from "../../../components/fragments/feedBackModal";
import {
  ConfirmHeader,
  ConfirmHeaderActions,
  ConfirmHeaderActionsResponsive,
  ConfirmHeaderTexts,
  ImportsBG,
  ImportsContainer,
  SaveCountLabel,
  TableConfirm,
  TableResponsive,
} from "../styleconfirm";
import LoadOutButton from "../../../components/fragments/loadOutButton";
import LoadOutCheckbox from "../../../components/fragments/loadOutCheckbox";

export default function GateImportConfirm() {
  const history = useHistory();
  const { state } = useLocation();
  const auth = useAuth();
  const [fileNameImport, setFileNameImport] = useState("");

  const [uploadData, setUploadData] = useState();
  const [removeData, setRemoveData] = useState();
  const [checkAll, setCheckAll] = useState(true);
  const [loading, setLoading] = useState();
  const [modalObject, setModalObject] = useState({
    type: "error",
    buttonLabel: "Ok",
    open: false,
    title: "Oops...",
    desc: "",
  });

  useEffect(() => {
    if (!state) {
      history.push("/import");
    }

    const { data, fileName } = state;

    var contactData = [];
    data.contacts.forEach((item) => {
      item.active = true;
      contactData.push(item);
    });

    setUploadData(contactData);
    setRemoveData(data.remove);
    setFileNameImport(fileName);
  }, [history, state]);

  async function SaveContacts() {
    var uploadObject = {
      contacts: [],
    };

    uploadData.forEach((retorno) => {
      if (!retorno.active) {
        return;
      }
      delete retorno.active;
      uploadObject.contacts.push(retorno);
    });

    if (uploadObject.contacts.length === 0) {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Error",
        desc: "Select at least one gate",
      });
      return;
    }

    setLoading(true);

    try {
      const { data } = await auth.postContactSave(uploadObject);
      if (data.res) {
        setModalObject({
          type: "success",
          buttonLabel: "Ok",
          open: true,
          title: "Success",
          desc: data.message,
          action: () => history.push("/import"),
        });
      }
    } catch ({ response }) {
      //checkTokenValidity(response.data)
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: response?.data.message,
      });
    } finally {
      setLoading(false);
    }
  }

  function checkAllFunc() {
    let uploadDataClone = [...uploadData];
    uploadDataClone.forEach((retorno) => {
      retorno.active = !checkAll;
      return retorno;
    });
    setCheckAll(!checkAll);
    setUploadData(uploadDataClone);
  }

  function ChangeSelect(e, index) {
    let uploadData_clone = [...uploadData];
    uploadData_clone.map((data, key) => {
      if (key === index) {
        data.active = e;
      }
      return data;
    });
    setUploadData(uploadData_clone);
  }

  return (
    <ImportsBG>
      <Loading visible={loading} />
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />

      <ImportsContainer>
        <ConfirmHeader>
          <ConfirmHeaderTexts>
            <h1>
              <FormattedMessage id="contacts" />
            </h1>
            <span>{fileNameImport}</span>
          </ConfirmHeaderTexts>
          <ConfirmHeaderActions>
            <LoadOutButton
              color="purpleLight"
              label={<FormattedMessage id="form.cancel" />}
              onClick={() => history.push("/imports")}
            />
            <LoadOutButton
              label={
                <SaveCountLabel>
                  <FormattedMessage id="save" />
                  <span>({uploadData?.filter((x) => x.active)?.length})</span>
                </SaveCountLabel>
              }
              disabled={
                uploadData?.filter((x) => x.active)?.length <= 0 || !uploadData
              }
              onClick={SaveContacts}
            />
          </ConfirmHeaderActions>
        </ConfirmHeader>

        <TableResponsive>
          <TableConfirm>
            <thead>
              <tr>
                <td style={{ minWidth: 350 }}>
                  <LoadOutCheckbox
                    value={checkAll}
                    onChange={() => checkAllFunc()}
                  />
                  <FormattedMessage id="tabela.name" />
                </td>
                <td>
                  <FormattedMessage id="tabela.phone" />
                </td>
              </tr>
            </thead>
            <tbody>
              {uploadData?.map((data, key) => (
                <tr key={key}>
                  <td>
                    <LoadOutCheckbox
                      disabled={data.time === null ? true : false}
                      value={data.active}
                      onChange={(e) => ChangeSelect(!data.active, key)}
                    />
                    {data.fname.concat(data.lname ? " ".concat(data.lname) : "")}
                  </td>
                  <td>{data.phone}</td>
                </tr>
              ))}
            </tbody>
          </TableConfirm>
        </TableResponsive>

        <ConfirmHeader>
          <ConfirmHeaderTexts>
            <h1>
              <FormattedMessage id="remove" />
            </h1>
          </ConfirmHeaderTexts>
        </ConfirmHeader>

        <TableResponsive>
          <TableConfirm>
            <thead>
              <tr>
                <td>
                  <FormattedMessage id="tabela.name" />
                </td>
                <td>
                  <FormattedMessage id="tabela.phone" />
                </td>
              </tr>
            </thead>
            <tbody>
              {removeData?.map((data, key) => (
                <tr key={key}>
                  <td>{data.fname.concat(data.lname ? " ".concat(data.lname) : "")}</td>
                  <td>{data.phone}</td>
                </tr>
              ))}
            </tbody>
          </TableConfirm>
        </TableResponsive>

        <ConfirmHeaderActionsResponsive>
          <LoadOutButton
            color="purpleLight"
            label={<FormattedMessage id="form.cancel" />}
            onClick={() => history.push("/import")}
          />
          <LoadOutButton
            label={
              <SaveCountLabel>
                <FormattedMessage id="save" />
                <span>({uploadData?.filter((x) => x.active)?.length})</span>
              </SaveCountLabel>
            }
            onClick={SaveContacts}
          />
        </ConfirmHeaderActionsResponsive>
      </ImportsContainer>
    </ImportsBG>
  );
}
