import React, { useRef, useState } from "react";
import LoadOutCheckbox from "../../components/fragments/loadOutCheckbox";
import appTheme from "../../styles/themes";
import { FormattedMessage } from "react-intl";
import { useOnClickOutside } from "../../utils/utils";
import iconArrow from "../../assets/images/icons/arrow-right.svg";
import arrowIcon from "../../assets/images/icons/arrow-down.svg";
import iconTic from "../../assets/images/icons/tic.svg";
import {
  ViewAllButton,
  ViewAllContainerMobile
} from "./style";
import {
  DriverItemItem,
  DriverItemList,
  DriverItemModal,
  ArrowOrder
} from "../../styles/common";

function Header_Mobile({ checkAll, onChangeSelectedAll, lastField, lastOrder, reorder }) {

  const [viewFieldsModal, setViewFieldsModal] = useState(false);
  const viewRef = useRef();
  useOnClickOutside(viewRef, () => setViewFieldsModal(false));

  const fields = [
    {
      name: "name",
      text: <FormattedMessage id="form.name" />
    },
    {
      name: "contactids",
      text: <FormattedMessage id="tabela.members" />
    },
    {
      name: "createdat",
      text: <FormattedMessage id="tabela.createdat" />
    }
  ];

  return (
    <>
      <div className="d-flex justify-content-between p-3 mb-3 align-items-center" style={{backgroundColor: appTheme.colors.gray[25], width: "100%", height: "50px"}}>
        <LoadOutCheckbox
          value={checkAll}
          onChange={(e) => {
            onChangeSelectedAll(e);
          }}
          label={<FormattedMessage id="select_all" />}
        />
        <ViewAllContainerMobile ref={viewRef}>
          <ViewAllButton
            active={viewFieldsModal}
            onClick={() => setViewFieldsModal(!viewFieldsModal)}
          >
            <div className="d-flex justify-content-between text-left pe-3 w-100">
              {fields.filter((item) => 
                item.name === lastField
              )[0].text}
              <ArrowOrder className="figure float-end"
                src={arrowIcon}
                down={lastOrder}
                alt="Arrow Icon"
              />
            </div>
            <img src={iconArrow} alt="Arrow Icon" />
          </ViewAllButton>
          <DriverItemModal active={viewFieldsModal}>
            <DriverItemList>
              {fields.map((field) => {
                return (
                  <DriverItemItem
                    active={field.name === lastField}
                    onClick={() => {
                      reorder(field.name);
                      setViewFieldsModal(false);
                    }}
                  >
                    <span>{field.text}</span>
                    <img src={iconTic} alt="Icon Tic" />
                  </DriverItemItem>
                );
              })}
            </DriverItemList>
          </DriverItemModal>
        </ViewAllContainerMobile>
      </div>
    </>
  );
}

export default Header_Mobile;
