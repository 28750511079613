import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";

const getPadding = (active, type) => {
  switch (type) {
    case "find":
    case "card":
      return active ? "19px 20px 5px 56px" : "19px 20px 5px 56px";
    case "address":
    case "email":
      return active ? "26px 20px 8px 56px" : "20px 18px 20px 56px";
    default:
      return active ? "26px 20px 8px" : "17px 18px";
  }
};

export const GroupField = styled.div`
  ${({ theme, active, width, type }) => css`
    position: relative;
    background-color: ${appTheme.colors.gray[100]};
    border-radius: 8px;
    padding: ${getPadding(active, type)};
    transition: 0.3s ease;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    width: ${width ? width : "100%"};

    &:focus-within,
    &:has(:-webkit-autofill),
    &:has(:-webkit-autofill:hover),
    &:has(:-webkit-autofill:focus) {
      padding: ${type === "address" || type === "email"
      ? "26px 20px 8px 56px"
      : type === "find" || type === "card" || type === "phone"
        ? "19px 20px 5px 56px"
        : "26px 20px 8px"};
    }
  `}
`;

export const InputTypeIcon = styled.img`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
    color: #000;
  `}
`;

export const InputField = styled.input`
  ${({ theme, type }) => css`
    position: relative;
    color: ${appTheme.colors.gray3};
    line-height: 20px;
    background: none;
    font-size: 14px;
    border: none;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    &:focus ~ label,
    &:-webkit-autofill ~ label,
    &:-webkit-autofill:hover ~ label,
    &:-webkit-autofill:focus ~ label {
      top: ${type === "find" || type === "card" ? "30%" : "35%"};
      color: ${appTheme.colors.purple[500]};
      font-weight: 600;
    }
  `}
`;

export const InputText = styled.textarea`
  ${({ theme, type, rows }) => css`
    position: relative;
    color: ${appTheme.colors.gray3};
    line-height: 20px;
    background: none;
    font-size: 14px;
    border: none;
    width: 100%;
    rows: ${rows}

    &:focus-visible {
      outline: none;
    }

    &:focus ~ label,
    &:-webkit-autofill ~ label,
    &:-webkit-autofill:hover ~ label,
    &:-webkit-autofill:focus ~ label {
      top: ${type === "find" || type === "card" ? "30%" : "35%"};
      color: ${appTheme.colors.purple[500]};
      font-weight: 600;
    }
  `}
`;

export const InputLabel = styled.label`
  ${({ theme, active, type }) => css`
    color: ${active ? appTheme.colors.purple[500] : appTheme.colors.gray[500]};
    font-weight: ${active ? '600' : '400'};
    top: ${!active ? "50%" : type === "find" || type === "card" ? "30%" : "35%"};
    transform: translate(0, -50%);
    pointer-events: none;
    position: absolute;
    font-size: 12px;
    left: ${type === "email" ||
      type === "address" ||
      type === "find" ||
      type === "card"
      ? "56px"
      : "20px"};
  `}
`;

export const EyeButton = styled.img`
  ${({ theme }) => css`
    transform: translate(0, -50%);
    position: absolute;
    cursor: pointer;
    right: 21.67px;
    top: 50%;
  `}
`;

export const SuportLabel = styled.p`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 6px;
    margin-bottom: 0;
    color: ${appTheme.colors.gray[500]};
    text-align: left;
    width: 100%;
  `}
`;
